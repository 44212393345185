import React, { useState } from "react";
import "./CheckoutDepositExpress.scss";
import CheckoutTitle from "./CheckoutTitle";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import LeftArrowBlack from "../img/left_arrow_black.png";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Correct from "../img/correct.jpg";
import Upload1 from "../img/upload1.png";
import { checkName, checkTaxId } from "../utils/validations";
import InputAdornment from "@material-ui/core/InputAdornment";
import TaxId from "./form-input/TaxId";
import DropZoneCustom from "./DropZoneCustom";
import req from "../utils/axios";
import SuccessImg from "../img/success.png";

const keyValue = {
  itau: "pixitau@pagsmile.com",
  santander: "pixsantander@pagsmile.com",
  bradesco: "pixbradesco@pagsmile.com",
  "banco-do-brasil": "pixbb@pagsmile.com",
  caixa: "pixcaixa@pagsmile.com",
};
const bankNames = {
  itau: "ITAÚ UNIBANCO S.A.",
  santander: "BCO SANTANDER (BRASIL) S.A.",
  bradesco: "BCO BRADESCO S.A.",
  "banco-do-brasil": "Banco do Brasil",
  caixa: "CAIXA ECONOMICA FEDERAL",
};

const bankCodes = {
  itau: "341",
  santander: "033",
  bradesco: "237",
  "banco-do-brasil": "--",
  caixa: "104",
};

const paymentTypes = {
  ted_same_bank: "Transferência entre mesmo banco",
  ted_other_bank: "TED entre bancos",
  doc: "DOC",
  cash_deposit: "Depósito no Caixa",
  envelop_deposit: "Depósito envelope",
};

function CheckoutDepositExpress({ setIsLoading, handleError, ...props }) {
  const [uploadState, setUploadState] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [form, setForm] = useState({
    payment_type: { value: "", valid: true, checked: false },
    agent: { value: "", valid: true, checked: false, max: 6 },
    account: { value: "", valid: true, checked: false, max: 12 },
    name: { value: "", valid: true, checked: false },
    taxId: { value: "", valid: true, checked: false },
    file: { value: "", valid: true, checked: false },
    idType: "",
  });

  const { t, i18n } = useTranslation();
  const submitForm = () => {
    checkPayType();
    checkAgent();
    checkAccount();
    checkName({ form: form, setForm: setForm });
    checkTaxId({ form: form, setForm: setForm, region: "BRA" });
    checkFile();
    if (
      form.payment_type.checked &&
      form.payment_type.valid &&
      form.agent.checked &&
      form.agent.valid &&
      form.account.checked &&
      form.account.valid &&
      form.name.checked &&
      form.name.valid &&
      form.taxId.checked &&
      form.taxId.valid &&
      form.file.checked &&
      form.file.valid
    ) {
      let formData = new FormData();
      let curFileSuffix = form.file.value?.name.slice(form.file.value?.name.lastIndexOf(".") + 1);
      formData.append("voucher", form.file.value, "payment_voucher." + curFileSuffix);
      formData.append("payment_type", form.payment_type.value);
      formData.append("holder_name", form.name.value);
      let taxId = form.taxId.value.replace(/[^\d]+/g, "");
      formData.append("holder_type", form.idType);
      formData.append("holder_id", taxId);
      formData.append("bank_branch", form.agent.value);
      formData.append("bank_account", form.account.value);
      setIsLoading(true);
      req({
        method: "post",
        url: `/api/trade/upload-voucher?trade_no=${props.prepayId}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          if (res.data.code === "10000") {
            setIsUploadSuccess(true);
            setIsLoading(false);
          } else {
            handleError(
              i18n.t("err_100004"),
              i18n.t("err_100003")
              // "Upload failed!",
              // "Something went wrong. Please try to upload again!"
            );
          }
        })
        .catch(function (err) {
          //handle error
          handleError(
            err,
            i18n.t("err_100003")
            // "Something went wrong. Please try to upload again!"
          );
        });
    }
  };

  const checkFile = () => {
    setForm((prev) => {
      return {
        ...prev,
        file: {
          ...prev.file,
          valid: !!form.file.value,
          checked: true,
        },
      };
    });
  };

  const checkPayType = () => {
    setForm((prev) => {
      return {
        ...prev,
        payment_type: {
          ...prev.payment_type,
          valid: !!form.payment_type.value,
          checked: true,
        },
      };
    });
  };
  const checkAgent = () => {
    let format = /^(\d{1})([\d-]{2,4})(\d{1})$/;
    setForm((prev) => {
      return {
        ...prev,
        agent: {
          ...prev.agent,
          valid: format.test(form.agent.value),
          checked: true,
        },
      };
    });
  };
  const checkAccount = () => {
    let format = /^\d{1,10}-\d{0,7}(\d|X|x)$/;
    setForm((prev) => {
      return {
        ...prev,
        account: {
          ...prev.account,
          valid: format.test(form.account.value),
          checked: true,
        },
      };
    });
  };
  return (
    <div className="checkoutDepositExpress">
      {!uploadState && (
        <div className="checkoutDepositExpress__payResult">
          <CheckoutTitle />
          <div className="checkoutDepositExpress__inst">
            <p className="checkoutDepositExpress__inst__title">{i18n.t("depositExpress_inst_title1")}</p>
            <p>{i18n.t("depositExpress_inst_title2")}</p>
            <p>{i18n.t("depositExpress_inst_title3")}</p>
          </div>
          <div className="checkoutDepositExpress__content">
            <div className="checkoutDepositExpress__row">
              <p>{i18n.t("depositExpress_bank_name")}</p>
              <p>{bankNames[props.payData?.bank_name]}</p>
            </div>
            <div className="checkoutDepositExpress__row">
              <p>{t("bank_code")}</p>
              <p>{bankCodes[props.payData?.bank_name]}</p>
            </div>
            <div className="checkoutDepositExpress__row">
              <p>{i18n.t("depositExpress_provider_agency")}</p>
              <p>{props.payData?.provider_agency}</p>
            </div>
            <div className="checkoutDepositExpress__row">
              <p>{i18n.t("depositExpress_provider_number")}</p>
              <p>{props.payData?.provider_number}</p>
            </div>
            <div className="checkoutDepositExpress__row">
              <p>{i18n.t("depositExpress_provider_owner")}</p>
              <p>{props.payData?.provider_owner}</p>
            </div>
            <div className="checkoutDepositExpress__row">
              <p>{i18n.t("depositExpress_provider_owner_document")}</p>
              <p>{props.payData?.provider_owner_document}</p>
            </div>
            <div className="checkoutDepositExpress__row">
              <p>{i18n.t("depositExpress_provider_chave_pix")}</p>
              <p>{keyValue[props.payData?.bank_name]}</p>
            </div>
          </div>
          <div className="checkoutDepositExpress--buttonContainer">
            <button
              onClick={() => {
                setUploadState(true);
              }}
            >
              {i18n.t("depositExpress_buttonContainer")}
            </button>
          </div>
        </div>
      )}
      {uploadState && (
        <>
          <div
            className="checkoutDepositExpress__back"
            onClick={() => {
              setUploadState(false);
            }}
          >
            <img src={LeftArrowBlack} alt="" />
            <span>{t("back")}</span>
          </div>
          {!isUploadSuccess && (
            <div className="checkoutDepositExpress__upload">
              <div className="checkoutDepositExpress__upload__title">
                <p>{t("deposit_upload_inst1")}</p>
                <p>{t("deposit_upload_inst2")}</p>
              </div>
              <div className="checkoutDepositExpress__upload__content">
                <FormControl
                  variant="outlined"
                  size="small"
                  error={!form.payment_type.valid}
                  className="homeMobile__filling__content__input"
                >
                  <InputLabel id="demo-simple-select-label">{i18n.t("payment_type_value")}</InputLabel>
                  <Select
                    value={form.payment_type.value}
                    label={i18n.t("payment_type_value")}
                    onChange={(event) => {
                      setForm((prev) => {
                        return {
                          ...prev,
                          payment_type: {
                            ...prev.payment_type,
                            value: event.target.value,
                          },
                        };
                      });
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onBlur={() => {
                      checkPayType();
                    }}
                  >
                    {Object.keys(paymentTypes).map((item, index) => {
                      return (
                        <MenuItem value={item} key={item}>
                          {paymentTypes[item]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {!form.payment_type.valid && <FormHelperText>{i18n.t("payment_type_value_invalid")}</FormHelperText>}
                </FormControl>

                <TextField
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  inputProps={{ autocomplete: "new-password" }}
                  size="small"
                  error={!form.agent.valid}
                  helperText={!form.agent.valid && i18n.t("agent_value_helpertext")}
                  className="homeMobile__filling__content__input"
                  label={i18n.t("agent_value_label")}
                  variant="outlined"
                  value={form.agent.value}
                  onChange={(event) => {
                    var format = /[^0-9-]+/g;
                    var value = event.target.value;
                    value = value.replace(format, "");
                    if (value?.length > form.agent.max) {
                      value = value.slice(0, form.agent.max);
                    }
                    setForm((prev) => {
                      return {
                        ...prev,
                        agent: { ...prev.agent, value: value },
                      };
                    });
                  }}
                  onBlur={() => {
                    checkAgent();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            display: form.agent.valid && form.agent.checked ? "block" : "none",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  inputProps={{ autocomplete: "new-password" }}
                  size="small"
                  error={!form.account.valid}
                  helperText={!form.account.valid && i18n.t("account_valid_helpertext")}
                  className="homeMobile__filling__content__input"
                  label={i18n.t("account_valid_label")}
                  variant="outlined"
                  value={form.account.value}
                  onChange={(event) => {
                    var format = /[^0-9-]+/g;
                    var value = event.target.value;
                    value = value.replace(format, "");
                    if (value?.length > form.account.max) {
                      value = value.slice(0, form.account.max);
                    }
                    setForm((prev) => {
                      return {
                        ...prev,
                        account: { ...prev.account, value: value },
                      };
                    });
                  }}
                  onBlur={() => {
                    checkAccount();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            display: form.account.valid && form.account.checked ? "block" : "none",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  inputProps={{ autocomplete: "new-password" }}
                  size="small"
                  error={!form.name.valid}
                  helperText={!form.name.valid && t("invalid_name")}
                  className="homeMobile__filling__content__input"
                  label={i18n.t("name_valid_label")}
                  variant="outlined"
                  value={form.name.value}
                  onChange={(event) => {
                    var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g;
                    var value = event.target.value;
                    value = value.replace(format, "");
                    if (value?.length > form.name.max) {
                      value = value.slice(0, form.name.max);
                    }
                    setForm((prev) => {
                      return {
                        ...prev,
                        name: { ...prev.name, value: value },
                      };
                    });
                  }}
                  onBlur={() => {
                    checkName({ form: form, setForm: setForm });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            display: form.name.valid && form.name.checked ? "block" : "none",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} disableIfData />
                <div className="checkoutDepositExpress__upload__dropzone">
                  <DropZoneCustom image={Upload1} setForm={setForm} title={i18n.t("drop_zone_custom_title")} />
                  {!form.file.valid && (
                    <p className="checkoutDepositExpress__upload__dropzoneError">{i18n.t("file_valid_text")}</p>
                  )}
                </div>
              </div>
              <div className="checkoutDepositExpress--buttonContainer">
                <button
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {t("upload")}
                </button>
              </div>
            </div>
          )}
          {isUploadSuccess && (
            <div className="checkoutDepositExpress__uploadSuccess">
              <img src={SuccessImg} alt="" />
              <p>{i18n.t("depositExpress_uploadSuccess")}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
    tradeInfo: state.tradeInfo,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDepositExpress);
