import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../../img/correct.jpg";
import { checkToditoPin } from "../../../utils/validations";

function PinNo({ form, setForm, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ autocomplete: "new-password" }}
        size="small"
        error={!form.todito_pin.valid}
        helperText={!form.todito_pin.valid && t("invalid_PIN")}
        className="homeMobile__filling__content__input"
        label="PIN"
        variant="outlined"
        value={form.todito_pin.value}
        onChange={(event) => {
          var value = event.target.value?.replace(/[^\d]/, "");
          if (value?.length > form.todito_pin.max) {
            value = value.slice(0, form.todito_pin.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              todito_pin: { ...prev.todito_pin, value: value },
            };
          });
        }}
        onBlur={() => {
          checkToditoPin({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display:
                    form.todito_pin.valid && form.todito_pin.checked
                      ? "block"
                      : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default PinNo;
