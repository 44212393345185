import React, { useState } from "react";
import "./CheckoutLottery.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Back from "../img/back.png";
import { formatTaxId } from "../utils/formats";

function CheckoutLottery(props) {
  const { i18n } = useTranslation();
  const [videoState, setVideoState] = useState(false);
  return (
    <div className="checkoutLottery">
      <div className="checkoutLottery__title">
        <p>{i18n.t("checkoutLottery_title")}</p>
      </div>
      <div className="checkoutLottery__contentContainer">
        <div className="checkoutLottery__content">
          <p className="checkoutLottery__contentText">{i18n.t("checkoutLottery_contentText1")}</p>
          <p className="checkoutLottery__contentValue">{props.payData?.bank_no}</p>
        </div>
        <div className="checkoutLottery__content">
          <p className="checkoutLottery__contentText">{i18n.t("checkoutLottery_contentText2")}</p>
          <p className="checkoutLottery__contentValue">
            {props.payData?.userCPF ||
              formatTaxId(props.tradeInfo?.user?.identify?.number, "BRA", props.tradeInfo?.user?.identify?.type)}
          </p>
        </div>
      </div>
      <div className="checkoutLottery__instContainer">
        <p>
          {i18n.t("checkoutLottery_instContainer1")}{" "}
          <span
            className="checkoutLottery__instContainer__click"
            onClick={() => {
              setVideoState(true);
            }}
          >
            {i18n.t("checkoutLottery_instContainer2")}
          </span>{" "}
          {i18n.t("checkoutLottery_instContainer3")}
        </p>
        <p>{i18n.t("checkoutLottery_instContainer4")}</p>
      </div>
      {videoState && (
        <div
          className="checkoutLottery__videoContainer"
          onClick={() => {
            setVideoState(false);
          }}
        >
          <div className="checkoutLottery__iframeContainer">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Vw8Iib_uGeE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <img
              src={Back}
              alt=""
              onClick={() => {
                setVideoState(false);
              }}
              className="checkoutLottery__iframeContainer__back"
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutLottery);
