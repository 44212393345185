import React from "react";
import "./CheckoutWallet.scss";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import MachQr from "../img/mach_qr.png";
import VitaQr from "../img/vita_qr.png";
import AmeQr from "../img/ameqr.png";
import AlipayhkQr from "../img/alipayhkQr.png";
import GcashQr from "../img/gcash_qr.png";
import KakaopayQr from "../img/kakaopay_qr.png";
import TngQr from "../img/tng_qr.png";
import TruemoneyQr from "../img/truemoney_qr.png";
import TpagaQr from "../img/tpaga_qr.jpg";
import { walletChannels } from "../utils/configurations";
var QRCode = require("qrcode.react");

const channel2logo = {
  Mach: MachQr,
  Vita: VitaQr,
  AME: AmeQr,
  Alipay: AlipayhkQr,
  GCash: GcashQr,
  KakaoPay: KakaopayQr,
  TNG: TngQr,
  TrueMoney: TruemoneyQr,
  TPaga: TpagaQr,
};

function CheckoutWallet(props) {
  const { t } = useTranslation();
  const getQrData = {
    Mach: props.payData?.app_link_url,
    Vita: props.payData?.app_link_url,
    AME: props.payData?.wallet_url,
    Alipay: props.payData?.qr_code,
    GCash: props.payData?.qr_code,
    KakaoPay: props.payData?.qr_code,
    TNG: props.payData?.qr_code,
    TrueMoney: props.payData?.qr_code,
    TPaga: props.payData?.wallet_url,
  };
  const getLink = {
    Mach: props.payData?.app_link_url,
    Vita: props.payData?.app_link_url,
    AME: props.payData?.app_link_url,
    Alipay: props.payData?.wallet_url,
    GCash: props.payData?.wallet_url,
    KakaoPay: props.payData?.wallet_url,
    TNG: props.payData?.wallet_url,
    TrueMoney: props.payData?.wallet_url,
    TPaga: props.payData?.wallet_url,
  };
  return (
    <div className="checkoutWallet">
      <div className="checkoutWallet__title">
        <p className="checkoutWallet--pc">{t("wallet_inst1")}</p>
        <p className="checkoutWallet--mobile">{t("wallet_mob_ins1")}</p>
      </div>
      <div className="checkoutWallet__content">
        <div className="checkoutWallet__content__qrcode">
          {(((props.payChannel === "Mach" || props.payChannel === "Vita") &&
            props.payData?.app_link_url) ||
            (props.payChannel === "AME" && props.payData?.wallet_url) ||
            (props.payChannel === "Alipay" && props.payData?.qr_code) ||
            (props.payChannel === "GCash" && props.payData?.qr_code) ||
            (props.payChannel === "KakaoPay" && props.payData?.qr_code) ||
            (props.payChannel === "TNG" && props.payData?.qr_code) ||
            (props.payChannel === "TrueMoney" && props.payData?.qr_code) ||
            (props.payChannel === "TPaga" && props.payData?.wallet_url)) && (
            <QRCode
              value={getQrData[props.payChannel]}
              size={160}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"Q"}
              includeMargin={false}
              renderAs={"svg"}
              imageSettings={{
                src: channel2logo[props.payChannel],
                x: null,
                y: null,
                height: 24,
                width: 24,
                excavate: true,
              }}
            />
          )}
        </div>
        <div className="checkoutWallet__content__inst">
          <div className="checkoutWallet__content__inst__titleContainer">
            <p className="checkoutWallet__content__inst__title checkoutWallet--pc">
              {t("wallet_inst2")}
            </p>
            <p className="checkoutWallet__content__inst__title checkoutWallet--mobile">
              {t("wallet_mob_ins2")}
            </p>
            <img
              src={
                walletChannels[props.tradeInfo?.app_info?.region][
                  props.payChannel
                ]
              }
              alt=""
            />
          </div>
          <div className="checkoutWallet__content__inst__howToPay">
            <p className="checkoutWallet__content__inst__howToPay__title">
              {t("wallet_inst3")}
            </p>
            <div className="checkoutWallet--pc">
              <p>{t("wallet_inst4")}</p>
              <p>{t("wallet_inst5")}</p>
              <p>{t("wallet_inst6")}</p>
            </div>
            <div className="checkoutWallet--mobile">
              <p>
                <span>{t("wallet_mob_ins3")}</span>
                <span>{props.payChannel}</span>
                <span>{t("wallet_mob_ins3.1")}</span>
              </p>
              <p>{t("wallet_mob_ins4")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="checkoutWallet__buttonContainer">
        <button
          onClick={() => {
            window.open(getLink[props.payChannel], "_blank");
          }}
        >
          {t("go_to")}
          {props.payChannel} App
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payChannel: state.payChannel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutWallet);
