import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../../img/correct.jpg";
import { checkMachCardNo } from "../../../utils/validations";
import { formatMachCardNumber } from "../../../utils/formats";

function MachCardNo({ form, setForm, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        inputProps={{ autocomplete: "new-password" }}
        error={!form.mach_cardNo.valid}
        helperText={!form.mach_cardNo.valid && t("invalid_card_no")}
        className="homeMobile__filling__content__input"
        label={t("card_no")}
        variant="outlined"
        value={form.mach_cardNo.value}
        onChange={(event) => {
          let value = formatMachCardNumber(event.target.value);
          setForm((prev) => {
            return {
              ...prev,
              mach_cardNo: { ...prev.mach_cardNo, value: value },
            };
          });
        }}
        onBlur={() => {
          checkMachCardNo({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display:
                    form.mach_cardNo.valid && form.mach_cardNo.checked
                      ? "block"
                      : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default MachCardNo;
