import React, { useEffect } from "react";
import req from "./utils/axios";
import queryUrl from "./utils/queryUrl";
import { connect } from "react-redux";
import { setTradeInfo, setPrepayId, queryOrder, setIsAppLoading } from "./redux/actions";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Result from "./components/Result";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Checkout from "./components/Checkout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProcessPage from "./components/ProcessPage";
import NotFound from "./components/NotFound";
import RefundConfirmation from "./components/RefundConfirmation";
import { handleErrorGlobal, notify } from "./utils/toast";
import MainSkeleton from "./components/MainSkeleton";
import RequireAuth from "./components/RequireAuth";

function App(props) {
  const prepayId = queryUrl("trade_no");

  const handleError = (err, notice) => {
    handleErrorGlobal({ err: err, notice_code: notice, defaultErrCode: "err_100001" });
  };

  useEffect(() => {
    props.setPrepayId(prepayId);
    const pathname = window.location.pathname;
    if (pathname === "/process" || pathname === "/authorization_fail") {
      // static pages
      props.setIsAppLoading(false);
    } else if (pathname === "/refund-confirmation" && prepayId) {
      req.post(`/api/trade/query-refund-key?trade_no=${prepayId}`).then((res) => {
        if (res?.data?.code === "10000") {
          const tradeInfo = res?.data?.data;
          props.setTradeInfo({
            out_trade_no: tradeInfo.trade_no,
            order_currency: tradeInfo.refund_currency,
            order_amount: tradeInfo.refund_amount,
            pay_currency: tradeInfo.refund_currency,
            pay_amount: tradeInfo.refund_amount,
            app_info: {
              app_name: tradeInfo.app_name,
              region: "BRA",
              icon_url: tradeInfo.icon_url,
            },
            user: {
              identify: {
                number: tradeInfo.id,
                type: tradeInfo.type,
              },
            },
            content: "Refund confirmation",
          });
          props.setIsAppLoading(false);
        } else if (res.data.code == "40002") {
          handleError("order timed_out", "timed_out");
        } else {
          handleError(`unknown error code: ${res.data.code}`);
        }
      });
    } else if (prepayId) {
      props.queryOrder(prepayId);
    } else {
      notify("Missing parameters!");
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Header />
      </Router>
      {props.isAppLoading && (
        <>
          <ToastContainer />
          <MainSkeleton />
        </>
      )}
      {!props.isAppLoading && (
        <Router>
          <ToastContainer />
          <Switch>
            <Route exact path="/">
              <RequireAuth>
                <Home />
              </RequireAuth>
            </Route>
            <Route path="/checkout">
              <RequireAuth>
                <Checkout />
              </RequireAuth>
            </Route>
            <Route path="/result">
              <RequireAuth>
                <Result />
              </RequireAuth>
            </Route>
            <Route path="/process">
              <ProcessPage />
            </Route>
            {/* <Route path="/authorization_fail">
              <AuthorizationFail />
            </Route>
            <Route path="/authorization">
              <Authorization />
            </Route> */}
            <Route path="/refund-confirmation">
              <RefundConfirmation />
            </Route>
            <Route path="/refund-result">
              <RequireAuth>
                <Result hideTrxDetails />
              </RequireAuth>
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Router>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    isAppLoading: state.isAppLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTradeInfo: (item) => dispatch(setTradeInfo(item)),
    setPrepayId: (item) => dispatch(setPrepayId(item)),
    queryOrder: (prepayId) => dispatch(queryOrder(prepayId)),
    setIsAppLoading: (item) => dispatch(setIsAppLoading(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
