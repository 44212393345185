import React from "react";
import "./OrderDetails.scss";
import TitleWithDetails from "./TitleWithDetails";
import TradeStatus from "./TradeStatus";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormatMoney } from "../utils/formats";
import PaymentCode from "./PaymentCode";

function OrderDetails({ showDetails = false, showStatus = false, backToShop = false, ...props }) {
  const { t } = useTranslation();
  return (
    <div className="orderDetails">
      <TitleWithDetails show={showDetails} />
      <hr />
      <div className="orderDetails__container">
        <p className="orderDetails__text">{t("order_value")}</p>
        <p className="orderDetails__value">
          <FormatMoney type="order" />
        </p>
      </div>
      <hr />
      {props.channel?.data?.length === 1 && props.channel?.data[0]?.logo && (
        <>
          <div className="orderDetails__container">
            <p className="orderDetails__text">{t("payment_ticket")}</p>
            <img className="orderDetails__ticketImg" src={props.channel?.data[0]?.logo} alt="" />
          </div>
          <hr />
        </>
      )}
      {((props.method === "KLAP" && props.payChannel === "Multicaja") ||
        (props.method === "Cash" && props.payChannel === "Multicaja") ||
        (props.method === "Cash" && props.payChannel === "PayCash")) &&
        props.payData.reference && (
          <>
            <PaymentCode value={props.payData.reference} copy />
            <hr />
          </>
        )}
      {!showStatus && (
        <div className="orderDetails__container" style={{ marginTop: "40px" }}>
          <p className="orderDetails__totalText">{t("total")}</p>
          <p className="corderDetails__totalValue">
            <FormatMoney type="total" />
          </p>
        </div>
      )}
      {showStatus && <TradeStatus status={props.tradeStatus.id} />}
      {backToShop && props.returnUrl && (
        <div className="orderDetails__return">
          <a href={props.returnUrl}>{t("back_shop")}</a>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    returnUrl: state.returnUrl,
    channel: state.channel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    tradeStatus: state.tradeStatus,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
