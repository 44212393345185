import req from "./axios";
import { formatTime } from "./formats";
import store from "../redux/store";

export const tokenize = (appId, issuer, form, success = () => {}, fail = () => {}) => {
  const { tradeInfo } = store.getState();
  const postData = {
    app_id: appId,
    biz_content: "",
    format: "",
    sign: "",
    sign_type: "",
    version: "2.0",
    card: {
      ext: "",
      valid_thru_month: form.expMonth.value,
      cvv: form.cvc.value,
      pin: "",
      valid_from_year: "",
      valid_thru_year: form.expYear.value,
      holder: {
        identification: {
          number: "",
          type: "",
        },
        name: form.name.value,
      },
      valid_from_month: "",
      card_no: form.cardNumber.value.replace(/[^\d]/g, ""),
      issuer: form.installments.stage,
    },
    timestamp: formatTime(new Date(), "yyyy-MM-dd HH:mm:ss"),
  };
  if (tradeInfo?.app_info?.global) postData.region = tradeInfo?.app_info?.region;
  req
    .post(`/api/trade/token`, postData)
    .then((res) => {
      success(res.data);
    })
    .catch((err) => {
      fail();
    });
};

export const paypalTokenize = (prepayId, success = () => {}, fail = () => {}) => {
  const { tradeInfo } = store.getState();
  let postUrl = `/api/trade/get-paypal-client-token?trade_no=${prepayId}`;
  if (tradeInfo?.app_info?.global) postUrl += `&region=${tradeInfo?.app_info?.region}`;
  req
    .post(postUrl)
    .then((res) => {
      if (res.data.code === "10000") {
        success(res.data.token);
      } else {
        fail("/get-paypal-client-token endpoint error!");
      }
    })
    .catch((err) => {
      fail(err);
    });
};

export const creditcardTokenize = (reqData) => {
  return new Promise((resolve, reject) => {
    req
      .post("/api/trade/token", reqData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
