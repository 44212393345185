import React from "react";

function HiddenForm({ form }) {
  return (
    <div>
      <form
        action="#"
        method="POST"
        id="payment-form"
        style={{ display: "none" }}
      >
        <input type="hidden" name="token_id" id="token_id" />
        <input
          type="hidden"
          name="use_card_points"
          id="use_card_points"
          value="false"
        />
        <input
          type="text"
          placeholder="Como aparece en la tarjeta"
          autocomplete="off"
          data-openpay-card="holder_name"
          class="form-control"
          id="card-name"
          value={form.name.value}
        />

        <input
          type="text"
          autocomplete="off"
          data-openpay-card="card_number"
          class="form-control"
          id="card-number"
          value={form.cardNumber.value.replace(/[^\d]/g, "")}
        />

        <input
          type="text"
          placeholder="Mes"
          data-openpay-card="expiration_month"
          class="form-control"
          id="exp-month"
          value={form.expMonth.value}
        />

        <input
          type="text"
          placeholder="Año"
          data-openpay-card="expiration_year"
          class="form-control"
          id="exp-year"
          value={form.expYear.value?.slice(2)}
        />

        <input
          type="text"
          placeholder="3/4 dígitos"
          autocomplete="off"
          data-openpay-card="cvv2"
          class="form-control"
          id="sec-code"
          value={form.cvc.value}
        />
      </form>
    </div>
  );
}

export default HiddenForm;
