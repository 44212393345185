import React from "react";
import "./PaymentCode.scss";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function PaymentCode({ copy, className, text = "", value = "", ...props }) {
  const { t } = useTranslation();
  const notify = () => {
    toast.success(t("copied"), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <div className={`${className} paymentCode_container`}>
      <div className="paymentCode">
        <p className="paymentCode__paymentCodeText">{text || t("payment_code")}</p>
        <div className="paymentCode__paymentCodeContainer">
          <p
            className="paymentCode__paymentCodeValue"
            style={{
              borderRadius: !copy ? "23px" : "",
            }}
          >
            {value || props.payData?.reference}
          </p>
          {copy && (
            <CopyToClipboard text={value || props.payData?.reference} onCopy={() => {}}>
              <button className="paymentCode__copyButton" onClick={notify}>
                {t("copy")}
              </button>
            </CopyToClipboard>
          )}
        </div>
      </div>
      <div className="paymentCodeMobile">
        <div>
          <p className="paymentCodeMobile__text">{text || t("payment_code")}</p>
          <p className="paymentCodeMobile__value">{value || props.payData?.reference}</p>
        </div>
        <CopyToClipboard text={value || props.payData?.reference} onCopy={() => {}}>
          <button className="paymentCodeMobile__copyButton" onClick={notify}>
            {t("copy")}
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCode);
