import React from "react";
import InfoLogo from "../img/info_icon2.svg";
import "./Warning.scss";

function Warning({ children }) {
  return (
    <div>
      <div className="warning">
        <img src={InfoLogo} alt="" />
        <p>{children}</p>
      </div>
    </div>
  );
}

export default Warning;
