import React, { useState } from "react";
import "./TitleWithDetails.scss";
import { connect } from "react-redux";
import PagsmileIcon from "../img/pagsmile_logo.png";
import { useTranslation } from "react-i18next";
import EllipsisScroll from "./EllipsisScroll";

function TitleWithDetails({ show = false, ...props }) {
  const { t } = useTranslation();
  const [isDetails, setIsDetails] = useState(show);
  return (
    <div className="details">
      <div className="details__channelContainer">
        <p className="details__channelName">{props?.tradeInfo?.app_info?.app_name}</p>
        <div className="details__channelImage">
          <img
            src={props?.tradeInfo?.app_info?.icon_url ? props?.tradeInfo?.app_info?.icon_url : PagsmileIcon}
            alt=""
          />
        </div>
      </div>
      <div className="details__floatRight">
        <span
          onClick={() => {
            setIsDetails((prev) => !prev);
          }}
          className="details__hide"
        >
          {isDetails ? t("hide") : t("details")}
        </span>
      </div>
      {isDetails && (
        <div style={{ marginTop: "10px" }}>
          <div className="details__container">
            <p className="details__text">{t("trx_id")}</p>
            <EllipsisScroll className="details__value" text={props?.tradeInfo?.out_trade_no} />
          </div>
          <div className="details__container">
            <p className="details__text">{t("product_description")}</p>
            <EllipsisScroll className={"details__value"} text={props?.tradeInfo?.content} />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleWithDetails);
