import React, { useState, useEffect } from "react";
import "./CheckoutMonnetBankCash.scss";
import BcpLogo from "../img/bcp.png";
import BbvaLogo from "../img/bbva.png";
import InterbankLogo from "../img/interbank.png";
import KasnetLogo from "../img/kasnet.png";
import ScotiabankLogo from "../img/scotiabank.png";
import TamboLogo from "../img/tambo.png";
import { useTranslation } from "react-i18next";
import { FormatMoney } from "../utils/formats";
import { connect } from "react-redux";
var classNames = require("classnames");

const AgentLogo = ({ name, active }) => {
  const color = active ? "#0666EB" : "#C2C9D6";
  switch (name) {
    case "bankMobile":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="smartphone-icon"
        >
          <path
            d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path d="M12 18H12.01" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      );
    case "bankWeb":
      return (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="monitor-icon"
        >
          <path
            d="M20.125 3H4.125C3.02043 3 2.125 3.89543 2.125 5V15C2.125 16.1046 3.02043 17 4.125 17H20.125C21.2296 17 22.125 16.1046 22.125 15V5C22.125 3.89543 21.2296 3 20.125 3Z"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M8.125 21H16.125"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path d="M12.125 17V21" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      );
    case "bank":
      return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="home-icon">
          <g clip-path="url(#clip0_368_2663)">
            <path
              d="M12.375 1L1.875 9H22.875L12.375 1Z"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M3.875 21V9H20.875V21"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M7.875 21V9H16.875V21"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M1.875 22H22.875"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_368_2663">
              <rect width="24" height="24" fill="white" transform="translate(0.125)"></rect>
            </clipPath>
          </defs>
        </svg>
      );
    case "agent":
      return (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="credit-card-icon"
        >
          <g clip-path="url(#clip0_368_2660)">
            <path
              d="M21.0942 2H4.65578C4.19691 2 3.79693 2.3123 3.68563 2.75746L2.18563 8.75746C2.02785 9.38861 2.50514 10 3.15571 10H3.875V20H21.875V10H22.5942C23.2448 10 23.7222 9.38861 23.5644 8.75746L22.0644 2.75746C21.9531 2.3123 21.5531 2 21.0942 2Z"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M22.875 10L21.8231 10C21.2308 10 20.6776 10.296 20.3491 10.7889V10.7889C19.6478 11.8408 18.1022 11.8408 17.4009 10.7889L17.375 10.75C17.1371 10.3932 16.6129 10.3932 16.375 10.75V10.75C15.6614 11.8204 14.0886 11.8204 13.375 10.75V10.75C13.1371 10.3932 12.6129 10.3932 12.375 10.75V10.75C11.6614 11.8204 10.0886 11.8204 9.375 10.75V10.75C9.13714 10.3932 8.61286 10.3932 8.375 10.75L8.34907 10.7889C7.64783 11.8408 6.10217 11.8408 5.40093 10.7889V10.7889C5.07236 10.296 4.51921 10 3.92686 10L3.875 10"
              stroke={color}
              stroke-width="2"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_368_2660">
              <rect width="24" height="24" fill="white" transform="translate(0.875)"></rect>
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <></>;
  }
};

const payConfig = {
  BCP: {
    name: "BCP",
    logo: BcpLogo,
    methods: {
      bankMobile: [
        'Ingresa a la aplicación móvil del BCP, accede al menú principal y selecciona la opción "Pagar Servicios".',
        'Selecciona la opción "Buscar en la sección".',
        'Busca "KashIO Perú", elige KashIO Perú Soles o Dólares e ingresa tu código de pago.',
        "Selecciona la deuda a pagar, confirma y ¡Listo!",
      ],
      bankWeb: [
        "Ingresa a tu banca web BCP desde: https://www.viabcp.com",
        'Ve al menú de "Pagos", y selecciona "Pagar un servicio".',
        'Busca "KashIO Perú", elige KashIO Perú Soles o Dólares e ingresa tu código de pago.',
        "Selecciona la deuda a pagar, confirma y ¡Listo!",
      ],
      bank: [
        "Acércate a una oficina o agencia del BCP e indica que deseas pagar al servicio de recaudación de KashIO.",
        "Elije el tipo de moneda a pagar y proporciona el número de tu DNI, RUC, teléfono o código único, según corresponda.",
        "Elije la deuda que deseas pagar y listo. Recibe el voucher de pago de la operación.",
      ],
      agent: [
        "Acércate a un agente del BCP e indica que deseas pagar al servicio de recaudación de KashIO Perú.",
        "ndica el Código de Agente de KashIO: 15813.",
        "Indica tu código de pago.",
        "Indica monto total, moneda a pagar y ¡Listo!",
        "Recibe el voucher de pago de la operación.",
      ],
    },
  },
  BBVA: {
    name: "BBVA",
    logo: BbvaLogo,
    methods: {
      bankMobile: [
        "Ingresa a la aplicación móvil del BBVA, accede al menú principal y selecciona tu cuenta bancaria.",
        'Elige la opción “Pagar servicio” y selecciona "Agregar servicio a pagar".',
        'Busca "KashIO Perú", elige KashIO Perú Soles o Dólares e ingresa tu código de pago.',
        "Selecciona la deuda a pagar, confirma y ¡Listo!",
      ],
      bankWeb: [
        "Ingresa a tu banca web BBVA desde: https://www.bbva.pe",
        'Ve a tu cuenta, pulsa el botón "Quiero" y selecciona la opción "Pagar servicio".',
        'Selecciona la opción "Instituciones y empresas" y pulsa la opción "Nombre".',
        'Busca "KashIO Perú", elige KashIO Perú Soles o Dólares, pulsa siguiente e ingresa tu código de pago',
        "Selecciona la deuda a pagar, confirma y ¡Listo!!",
      ],
      bank: [
        "Acércate a una oficina o agencia del BBVA e indica que deseas pagar al servicio de recaudación de KashIO.",
        "Elije el tipo de moneda en la que quieres pagar.",
        "Proporciona tu número de DNI, RUC, teléfono o código único según corresponda y elije la deuda que quieres pagar.",
        "Recibe el voucher de pago de la operación.",
      ],
      agent: [
        "Acércate a un agente del BBVA e indica que deseas pagar al servicio de recaudación de KashIO Perú.",
        "Indica el Código de Agente de KashIO: Pago en Soles: 11140, Pago en Dólares: 11141",
        "Indica tu código de pago.",
        "Indica monto total, moneda a pagar y ¡Listo!",
        "Recibe el voucher de pago de la operación.",
      ],
    },
  },
  Interbank: {
    name: "Interbank",
    logo: InterbankLogo,
    methods: {
      bankMobile: [
        "Ingresa a la aplicación móvil de Interbank.",
        'Selecciona la opción "Operaciones" y elige la opción "Pago y recargas".',
        'Selecciona "Pago a institución o empresa".',
        'Busca "KashIO Perú", elige KashIO Perú Soles o Dólares e ingresa tu código de pago.',
        "Selecciona la deuda a pagar, confirma y ¡Listo!",
      ],
      bankWeb: [
        "Ingresa a tu banca web Interbank desde: https://interbank.pe",
        'Selecciona "Mis operaciones" y eligen la opción "Pago o Recarga".',
        'Selecciona "Pago a institución o empresa".',
        'Busca "KashIO Perú", elige KashIO Perú Soles o Dólares e ingresa tu código de pago.',
        "Selecciona la deuda a pagar, confirma y ¡Listo!",
      ],
      bank: [
        "Acércate a una oficina o agencia del Interbank e indica que deseas pagar al servicio de recaudación de KashIO.",
        "Elige la moneda a pagar y proporciona tu número de DNI, RUC, teléfono o código único según corresponda.",
        "Elije las deudas a pagar y listo.",
        "Recibe el voucher de pago de la operación.",
      ],
      agent: [
        "Acércate a un agente de Interbank e indica que deseas pagar al servicio de recaudación de KashIO Perú.",
        "Indica el Código de Agente de KashIO: Pago en Soles: 0791501, Pago en Dólares: 0791502",
        "Indica tu código de pago.",
        "Indica monto total, moneda a pagar y ¡Listo!",
        "Recibe el voucher de pago de la operación.",
      ],
    },
  },
  Scotiabank: {
    name: "Scotiabank",
    logo: ScotiabankLogo,
    methods: {
      bankMobile: [
        "Ingresa a la aplicación móvil de Scotiabank.",
        'Selecciona la opción "Quiero" y elige la opción "Pagar".',
        'Selecciona "Servicios o Instituciones".',
        'Busca "KashIO Perú", elige KashIO Perú Soles o Dólares e ingresa tu código de pago.',
        "Selecciona la deuda a pagar, confirma y ¡Listo!",
      ],
      bankWeb: [
        "Ingresa a tu banca web Scotiabank desde: https://mi.scotiabank.com.pe/",
        'Elige la opción "Pagar" y selecciona la opción "Servicios o Instituciones".',
        'Busca "KashIO Perú", elige KashIO Perú Soles o Dólares e ingresa tu código de pago.',
        "Selecciona la deuda a pagar, confirma y ¡Listo!",
      ],
      bank: [
        "Acércate a una oficina o agencia del Scotiabank e indica que deseas pagar al servicio de recaudación de KASHIO PERU.",
        "Proporciona tu número de DNI, RUC, teléfono o código único según corresponda y elije la deuda que quieres pagar.",
        "Elije el tipo de moneda y el moto a pagar.",
        "Recibe el voucher de pago de la operación.",
      ],
      agent: [
        "Acércate a un agente de Scotiabank e indica que deseas pagar al servicio de recaudación de KashIO Perú o indica nuestro RUC 20602393799.",
        "Indica tu código de pago.",
        "Indica monto total, moneda a pagar y ¡Listo!",
        "Recibe el voucher de pago de la operación.",
      ],
    },
  },
  Kasnet: {
    name: "Kasnet",
    logo: KasnetLogo,
    methods: {
      agent: [
        "Acércate a un agente de KASNET e indica que deseas pagar al servicio de recaudación de KashIO Perú.",
        "Indica el Código de Agente de KashIO: 220044 .",
        "Indica tu código de pago.",
        "Indica monto total, moneda a pagar y ¡Listo!",
        "Recibe el voucher de pago de la operación.",
      ],
    },
  },
  Tambo: {
    name: "Tambo",
    logo: TamboLogo,
    methods: {
      agent: [
        'Acércate a una tienda Tambo+ e indica que deseas realizar pagos por agente a "KashIO".',
        "Indica tu código de pago.",
        "Elige la Deuda a pagar y ¡Listo!",
        "Recibe el voucher de pago de la operación.",
      ],
    },
  },
};

function CheckoutMonnetBankCash(props) {
  const { t } = useTranslation();
  const [selectedCannel, setSelectedChannel] = useState(payConfig.BCP);
  const [selectedMethod, setSelectedMethod] = useState("bankMobile");

  useEffect(() => {
    setSelectedMethod(Object.keys(selectedCannel.methods)?.[0] || {});
  }, [selectedCannel]);

  return (
    <div className="CheckoutMonnetBankCash">
      <div className="CheckoutMonnetBankCash__title">
        <div className="CheckoutMonnetBankCash__titleLeft">
          <div>
            <p className="text">Nombre del servicio</p>
            <p className="value">KASHIO PERU SOLES</p>
          </div>
          <div>
            <p className="text">Codigo de pago</p>
            <p className="value">{props.payData?.reference}</p>
          </div>
        </div>
        <div className="CheckoutMonnetBankCash__titleMiddle">
          <p className="text">Importe a pagar</p>
          <p className="value">
            <FormatMoney type="total" />
          </p>
        </div>
        {/* <div className="CheckoutMonnetBankCash__titleRight">Expiry time</div> */}
      </div>
      <div className="CheckoutMonnetBankCash__agents">
        <p className="CheckoutMonnetBankCash__agentsTitle">{t("mon_chooseBank")}</p>
        <div className="CheckoutMonnetBankCash__agentsContainer">
          {Object.values(payConfig).map((item) => (
            <div
              className={classNames({ "logo-active-border": selectedCannel === item })}
              style={{ height: "30px", width: "100px", margin: "10px", padding: "5px" }}
              key={item.name}
              onClick={() => {
                setSelectedChannel(item);
              }}
            >
              <img
                className={classNames("CheckoutMonnetBankCash__agentsLogo", {
                  "logo-active": selectedCannel === item,
                })}
                src={item.logo}
                alt=""
                style={{ height: "30px" }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="CheckoutMonnetBankCash__methods">
        <p className="CheckoutMonnetBankCash__methodsTitle">{t("mon_chooseMethod")}</p>
        <div className="CheckoutMonnetBankCash__methodList">
          {Object.keys(selectedCannel.methods || {}).map((item) => (
            <div
              className={classNames("CheckoutMonnetBankCash__methodContainer", {
                "logo-active-border": selectedMethod === item,
              })}
              onClick={() => {
                setSelectedMethod(item);
              }}
            >
              <AgentLogo name={item} active={item === selectedMethod} />
              <p>{t(`mon_${item}`)}</p>
            </div>
          ))}
        </div>
        <div className="CheckoutMonnetBankCash__methodIns">
          <ol>
            {selectedCannel.methods?.[selectedMethod]?.map?.((item) => (
              <li key={item}>{item}</li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutMonnetBankCash);
