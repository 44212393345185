import React, { useState } from "react";
import "./ShowHideBox.scss";
import FlipMove from "react-flip-move";

function ShowHideBox({ title, content, className }) {
  const [state, setState] = useState(false);
  return (
    <div
      className={`box ${className}`}
      onClick={() => {
        setState((prev) => !prev);
      }}
    >
      <div className="box__title">
        <p className="box__title__text">{title}</p>
        <p className="box__title__icon">{state ? "-" : "+"}</p>
      </div>
      <FlipMove enterAnimation="accordionVertical" leaveAnimation="none">
        {state && <p className="box__content">{content}</p>}
      </FlipMove>
    </div>
  );
}

export default ShowHideBox;
