import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import { checkFirstName } from "../../utils/validations";

function FirstName({ form, setForm, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ autocomplete: "new-password" }}
        size="small"
        error={!form.firstName.valid}
        helperText={!form.firstName.valid && t("invalid_name")}
        className="homeMobile__filling__content__input"
        label={"First Name"}
        variant="outlined"
        value={form.firstName.value}
        onChange={(event) => {
          var format =
            /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
          var value = event.target.value;
          value = value.replace(format, "");
          if (value?.length > form.firstName.max) {
            value = value.slice(0, form.firstName.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              firstName: { ...prev.firstName, value: value },
            };
          });
        }}
        onBlur={() => {
          checkFirstName({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display:
                    form.firstName.valid && form.firstName.checked
                      ? "block"
                      : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default FirstName;
