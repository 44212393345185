import React from "react";
import "./Other.scss";
import Cancel from "../img/cancel.png";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
function Other(props) {
  const { t } = useTranslation();
  return (
    <div className="other">
      <img className="other__result" src={Cancel} alt="" />
      <p className="other__notice1">{t(props.tradeStatus?.text)}</p>
      <p className="other__notice2">{t(props.tradeStatus?.notice)}</p>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeStatus: state.tradeStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Other);
