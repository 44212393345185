import React from "react";
import Wapp from "../img/wapp.png";
import Fbook from "../img/fbook.png";
import "./ShareOnSocialMedia.scss";
import { useTranslation } from "react-i18next";
import ShareIcon from "../img/icon_share.png";

function ShareOnSocialMedia({ text }) {
  const { t } = useTranslation();
  return (
    <div className="shareOnSocialMedia">
      <div className="shareOnSocialMedia__container">
        <button className="shareOnSocialMedia__shareButton">
          {t("share")}
          <img src={ShareIcon} alt="" style={{ marginLeft: "10px" }} />
        </button>
        <div className="shareOnSocialMedia__mediaContainer">
          <img
            src={Wapp}
            alt=""
            onClick={() => {
              if (window.innerWidth > 800) {
                window.open(`https://web.whatsapp.com/send?text=${encodeURIComponent(text)}`, "_blank");
              } else {
                window.open(`https://wa.me/?text=${encodeURIComponent(text)}`, "_blank");
              }
            }}
          />
          <img
            src={Fbook}
            alt=""
            onClick={() => {
              window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(text)}`, "_blank");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ShareOnSocialMedia;
