import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import { checkPostalCode } from "../../utils/validations";
import { formatPostalCode } from "../../utils/formats";

function PostalCode({ form, setForm, setFocusOnInput = () => {}, tradeInfo, setSelectNotice, isGlobal }) {
  const { t } = useTranslation();
  useEffect(() => {
    isGlobal &&
      form.postalCode.checked &&
      checkPostalCode({ form: form, setForm: setForm, region: tradeInfo?.app_info?.region });
  }, [form.country.value]);

  return (
    <>
      <TextField
        inputProps={{ autocomplete: "new-password", "ga-data": "343" }}
        onFocus={() => {
          setFocusOnInput(true);
          if (isGlobal && !form.country.value) {
            setSelectNotice({ state: true, notice: t("select_country") });
          }
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        error={!form.postalCode.valid}
        helperText={!form.postalCode.valid && t("invalid_postal_code")}
        className="homeMobile__filling__content__input"
        label={t("postal_code")}
        variant="outlined"
        value={form.postalCode.value}
        onChange={(event) => {
          let value = event.target.value;
          value = formatPostalCode(value, tradeInfo?.app_info?.region, form.country.value, isGlobal);
          setForm((prev) => {
            return {
              ...prev,
              postalCode: { ...prev.postalCode, value: value },
            };
          });
        }}
        onBlur={() => {
          checkPostalCode({ form: form, setForm: setForm, region: tradeInfo?.app_info?.region, isGlobal: isGlobal });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.postalCode.valid && form.postalCode.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default PostalCode;
