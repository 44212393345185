import React from "react";
import Processing from "./Processing";
import "./ProcessPage.scss";
import { useTranslation } from "react-i18next";
function SuccessPage() {
  const { t } = useTranslation();
  return (
    <div className="processPage">
      <Processing />
      {/* <p>{t("visit_us")}</p>
      <a href="www.pagsmile.com">www.pagsmile.com</a> */}
    </div>
  );
}

export default SuccessPage;
