import React, { useState } from "react";
import Channel from "../Channel";
import "./ChannelSelect.scss";
var classNames = require("classnames");

function ChannelSelect({ channelList, method, handleError }) {
  const [subChannel, setState] = useState("agent");
  return (
    <div className="channelSelect">
      <div className="channelSelect__title">
        {/* <span
          onClick={() => {
            setState("bank");
          }}
          className={classNames({
            "channelSelect--active": subChannel === "bank",
          })}
        >
          Banks
        </span> */}
        <span
          onClick={() => {
            setState("agent");
          }}
          className={classNames({
            "channelSelect--active": subChannel === "agent",
          })}
        >
          Agentes
        </span>
      </div>
      <div className="channelSelect__channels">
        {channelList[method][subChannel]?.map((item) => {
          return <Channel data={item} key={item.key} subChannel={subChannel} handleError={handleError} />;
        })}
      </div>
    </div>
  );
}

export default ChannelSelect;
