import React, { useEffect, useState } from "react";

function CountDown({ time, timeIsUpCallback = () => {}, style = { color: "#f00" }, hourOnly = false }) {
  const [remainTime, setRemainTime] = useState({
    day: "00",
    hour: "",
    min: "",
    sec: "",
  });
  useEffect(() => {
    const start = Date.now();
    const interval = setInterval(() => {
      let timeValue = typeof time !== "number" || isNaN(time) ? 0 : time;
      //console.log("time:", time, "timevalue:", timeValue);
      let remain = timeValue - (Date.now() - start);
      if (remain <= 0) {
        setRemainTime({ day: "00", hour: "00", min: "00", sec: "00" });
        clearInterval(interval);
        timeIsUpCallback();
      } else if (!isNaN(remain)) {
        //console.log(`time: ${time}, remain: ${remain}`);
        let timeString = new Date(remain).toISOString();
        let value = new Date(remain).toISOString().substr(11, 8);
        value = value.split(":");
        //console.log(value);
        let day = timeString.slice(8, 10) - 1;
        day = String(day).length === 1 ? "0" + day : String(day);
        if (hourOnly) {
          setRemainTime({ hour: Number(day) * 24 + Number(value[0]), min: value[1], sec: value[2] });
          return;
        }
        setRemainTime({ day: day, hour: value[0], min: value[1], sec: value[2] });
      }
      //console.log("time called", interval);
    }, 1000);

    return () => {
      //console.log("component will unmount:", interval);
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <span style={style}>
        {hourOnly
          ? (remainTime.hour && remainTime.hour !== "00" ? `${remainTime.hour}:` : "") +
            (remainTime.min ? `${remainTime.min}:` : "") +
            (remainTime.sec ? `${remainTime.sec}` : "")
          : (remainTime.day !== "00" && remainTime.day ? `${remainTime.day}d:` : "") +
            (remainTime.hour && remainTime.hour !== "00" ? `${remainTime.hour}h:` : "") +
            (remainTime.min ? `${remainTime.min}m:` : "") +
            (remainTime.sec ? `${remainTime.sec}s` : "")}
      </span>
    </>
  );
}

export default CountDown;
