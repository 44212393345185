import React from "react";
import "./PaymentMethod.scss";
import Selected from "../img/selected_green.png";
import { connect } from "react-redux";
import { changeMethod, setWalletChannels, setPaymentInfo } from "../redux/actions";
var classNames = require("classnames");

function PaymentMethod({ image, text, type, channel, loading = () => {}, handleError, ...props }) {
  return (
    <>
      <div
        className="method"
        onClick={() => {
          if (type === "Wallet") {
            props.setPaymentInfo("");
            props.setWalletChannels(type, handleError, loading);
          } else {
            props.changeMethod(type, channel, loading, handleError);
          }
        }}
      >
        <div className="method__logoContainer">
          <img
            className={classNames("method__logo", {
              "method--filter": props.method !== type,
            })}
            src={image}
            alt=""
          />
          {props.method === type && <img className="method__selected" src={Selected} alt="" />}
        </div>

        <p className="method__text">{text}</p>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeMethod: (method, channel, setIsLoading, handleError) =>
      dispatch(changeMethod(method, channel, setIsLoading, handleError)),
    setWalletChannels: (method, handleError, loading) => dispatch(setWalletChannels(method, handleError, loading)),
    setPaymentInfo: (item) => dispatch(setPaymentInfo(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
