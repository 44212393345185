import { toast } from "react-toastify";
import i18next from "i18next";

export const notify = (text) => {
  toast.warn(text, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const handleErrorGlobal = ({ defaultErrCode, err, notice_code, notice_text, setIsLoading = () => {} }) => {
  console.error(err);
  setIsLoading(false);
  if (notice_text || notice_code || defaultErrCode) {
    notify(notice_text || i18next.t(notice_code || defaultErrCode));
  }
};
