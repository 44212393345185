import React from "react";
import "./Success.scss";
import SuccessImg from "../img/success.png";
import { useTranslation } from "react-i18next";
function Success() {
  const { t } = useTranslation();
  return (
    <div className="success">
      <img className="success__result" src={SuccessImg} alt="" />
      <p className="success__notice1" style={{ color: "#01a37f" }}>
        {t("success")}
      </p>
      <p className="success__notice2">{t("success_notice")}</p>
    </div>
  );
}

export default Success;
