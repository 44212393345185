// const getStatus = (code) => {
//   switch (code) {
//     case 10001:
//       return "trade";
//     case 18000:
//       return "process";
//     case 20000:
//       return "success";
//     case 25000:
//       return "cancel";
//     case 40000:
//       return "fail";
//     case 21000:
//       return "fail";
//     case 30000:
//       return "fail";
//     case 50001:
//       return "fail";
//     default:
//       return "process";
//   }
// };

// export default getStatus;

const getStatus = (code) => {
  code = String(code);
  let trade = ["10001"];
  let success = ["20000"];
  let process = ["18000", "20001", "20002", "22005", "22006", "27000"];
  let other = [
    "25000",
    "22000",
    "22001",
    "23000",
    "23001",
    "30000",
    "30001",
    "50001",
    "22002",
  ];
  let fail = ["19000", "21000", "22003", "24000", "40000"];

  const status = {
    id: "",
    text: "", // i18n id
    notice: "", // i18n id
  };

  if (trade.indexOf(code) >= 0) {
    status.id = "trade";
  } else if (success.indexOf(code) >= 0) {
    status.id = "success";
    status.text = "success";
    status.notice = "success_notice";
  } else if (process.indexOf(code) >= 0) {
    status.id = "process";
    status.text = "processing";
    status.notice = "processing_notice";
    if (code === "20001") {
      status.text = "paid_major";
    } else if (code === "20002") {
      status.text = "paid_minor";
    } else if (code === "22005") {
      status.text = "refund_verifying";
    } else if (code === "22006") {
      status.text = "refund_processing";
    } else if (code === "27000") {
      status.text = "risk_control";
    }
  } else if (other.indexOf(code) >= 0) {
    status.id = "other";
    status.text = "";
    status.notice = "";
    if (code === "25000") {
      status.text = "cancelled";
      status.notice = "cancelled_notice";
    } else if (code === "22000") {
      status.text = "refunded";
    } else if (code === "22001") {
      status.text = "partial_refund";
    } else if (code === "22002") {
      status.text = "refund_reverse";
    } else if (code === "23000") {
      status.text = "chargeback";
    } else if (code === "23001") {
      status.text = "chargeback_reversed";
    } else if (code === "30000") {
      status.text = "dispute";
    } else if (code === "30001") {
      status.text = "dispute_reversed";
    } else if (code === "50001") {
      status.text = "timeout";
      status.notice = "cancelled_notice";
    }
  } else if (fail.indexOf(code) >= 0) {
    status.id = "fail";
    status.text = "failed";
    status.notice = "";
    if (code === "19000") {
      status.notice = "fail_sec";
    } else if (code === "21000") {
      status.text = "payment_refuse";
    } else if (code === "22003") {
      status.text = "refund_refuse";
    } else if (code === "24000") {
      status.text = "pay_close";
    } else if (code === "40000") {
      status.text = "payment_fail";
    }
  }
  return status;
};

export default getStatus;
