import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import { checkAddress } from "../../utils/validations";

function AddressBox({ form, setForm, setFocusOnInput = () => {}, tradeInfo }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!form.address.checked) {
      let address = tradeInfo?.address?.street;

      if (address) {
        setForm((prev) => {
          return {
            ...prev,
            address: { ...prev.address, value: address },
          };
        });
        checkAddress({ form: form, setForm: setForm, data: address });
      }
    }
  }, []);
  return (
    <>
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        multiline
        maxRows={3}
        inputProps={{ autocomplete: "new-password" }}
        size="small"
        error={!form.address.valid}
        helperText={!form.address.valid && t("invalid_address")}
        className="homeMobile__filling__content__input"
        label={t("address")}
        variant="outlined"
        value={form.address.value}
        onChange={(event) => {
          let value = event.target.value;
          value = value?.replace(/[!¡@¿$%^&*=()?":;{}+|<>”]/g, "");
          if (value?.length > form.address.max) {
            value = value.slice(0, form.address.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              address: { ...prev.address, value: value },
            };
          });
        }}
        onBlur={() => {
          checkAddress({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.address.valid && form.address.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default AddressBox;
