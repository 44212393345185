import { AsYouType } from "libphonenumber-js/mobile";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import { countryCode3to2, latinCountries } from "./configurations";
import store from "../redux/store";
import { cpf, cnpj } from "cpf-cnpj-validator";
import country2codes from "./regionCodes";
const validateRfc = require("validate-rfc");

export const validateReturnUrl = (url) => {
  url = String(url);
  return /^https?:(\/\/)?.+\..+$/.test(url);
};

///////////////////////// Phone number

const validatePhone = (number, code) => {
  code = String(code ?? "").toUpperCase();
  if (code.length === 3) {
    code = countryCode3to2[code] ?? "";
  }
  let asYouType = new AsYouType(code);
  asYouType.input(number);
  //console.log(number, countryCode3to2[code], asYouType.getNumber()?.country);
  return isValidPhoneNumber(number, code) && asYouType.getNumber()?.country === code;
};

export const checkIsPhoneValid = (val, region) => {
  return validatePhone(String(val), region);
};

export const checkPhone = ({ form, setForm, region, data }) => {
  let val = data === undefined ? form?.phone?.value : data;
  region = form?.phone?.countryObj?.alpha2_code || region;

  const validity = !!validatePhone(val, region);

  setForm((prev) => {
    return {
      ...prev,
      phone: { ...prev.phone, valid: validity, checked: true },
    };
  });

  return validity;
};

///////////////////////// Tax id

const code2RegxVal = {
  MEX: { RFC: /^([a-zA-Z]{3,4})(\d{6})([a-zA-Z0-9]{3})$/, CURP: /^([a-zA-Z]{4}\d{6}[a-zA-Z]{6}[0-9a-zA-Z]{1}\d{1})$/ },
  CHL: { RUT: /^(\d{8})([0-9Kk]{1})$/, RUN: /^(\d{8})([0-9Kk]{1})$/ },
  COL: { NIT: /^(\d{10})$/, CC: /^(\d{6,10})$/, CE: /^([a-zA-Z0-9]{5,12})$/ },
  PER: { DNI: /^(\d{8})$/, RUC: /^(\d{11})$/ },
  ECU: { RUC: /^(\d{13})$/, CE: /^([0-1][0-9]|2[0-4])[0-5]\d{7}$/, CI: /^(\d{8,10})/ },
  PAN: { CIP: /^(\d{6})$/ },
  CRI: { CDI: /^(\d{9})$/ },
  SLV: { DUI: /^(\d{9})$/ },
  GTM: { DPI: /^(\d{9})$/ },
  NIC: { CIP: /^(\d{6})$/ },
  ARG: {
    DNI: /^\d{2}[.]?\d{3}[.]?\d{3}$/,
    CUIT: /^(20|23|24|25|26|27|30|33|34)-?\d{2}[.]?\d{3}[.]?\d{3}-?\d$/,
    CUIL: /^(20|23|24|25|26|27|30|33|34)-?\d{2}[.]?\d{3}[.]?\d{3}-?\d$/,
    CDI: /^(20|23|24|25|26|27|30|33|34)-?\d{2}[.]?\d{3}[.]?\d{3}-?\d$/,
  },
  URY: {
    CI: /^[0-9]{1}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{1}$/,
    RUT: /^[0-9]{2}-?[0-9]{6}-?[0-9]{3}-?[0-9]{1}$/,
  },
  BOL: {
    NIT: /^(\d{9,12})$/,
    CI: /^([a-zA-Z]{2}\d{7})|(\d{7}[a-zA-Z]{2})|(\d{7})$/,
    CE: /^(\d{8})$/,
  },
  PRY: {
    RUC: /^(\d{8,9})$/,
    CI: /^(\d{7})/,
  },
};

// export const validate_cpf = (cpfVal) => {
//   cpfVal = String(cpfVal).replace(/[^\d]+/g, "");
//   var add = 0;
//   for (var i = 0; i < 9; i++) {
//     add += parseInt(cpfVal.charAt(i)) * (10 - i);
//   }
//   var rev = 11 - (add % 11);
//   if (rev == 10 || rev == 11) {
//     rev = 0;
//   }
//   if (rev != parseInt(cpfVal.charAt(9))) {
//     return false;
//   }
//   // Valida 2o digito
//   add = 0;
//   for (i = 0; i < 10; i++) {
//     add += parseInt(cpfVal.charAt(i)) * (11 - i);
//   }
//   rev = 11 - (add % 11);
//   if (rev == 10 || rev == 11) {
//     rev = 0;
//   }
//   if (rev != parseInt(cpfVal.charAt(10))) {
//     return false;
//   }
//   return true;
// };

// function validate_cnpj(val) {
//   val = String(val).replace(/[^\d]+/g, "");
//   // Elimina CNPJs inválidos conhecidos
//   if (val == "" || val.length != 14 || /^(.)\1+$/.test(val)) return false;
//   // Valida DVs
//   var tamanho = val.length - 2;
//   var numeros = val.substring(0, tamanho);
//   var digitos = val.substring(tamanho);
//   var soma = 0;
//   var pos = tamanho - 7;
//   for (var i = tamanho; i >= 1; i--) {
//     soma += numeros.charAt(tamanho - i) * pos--;
//     if (pos < 2) pos = 9;
//   }
//   var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
//   if (resultado != digitos.charAt(0)) return false;
//   tamanho = tamanho + 1;
//   numeros = val.substring(0, tamanho);
//   soma = 0;
//   pos = tamanho - 7;
//   for (i = tamanho; i >= 1; i--) {
//     soma += numeros.charAt(tamanho - i) * pos--;
//     if (pos < 2) pos = 9;
//   }
//   resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
//   if (resultado != digitos.charAt(1)) return false;
//   return true;
// }

const validateRFC = (num) => {
  let cleared = String(num ?? "").replace(/[^a-zA-Z0-9]/g, "");
  return validateRfc(cleared)?.isValid;
};

const validateRut = (rut) => {
  var cleanRut = String(rut).replace(/[^\dkK]/g, "");
  if (cleanRut.length === 8) cleanRut = "0" + cleanRut;
  if (!/^\d{8}[Kk\d]$/.test(cleanRut)) return false;
  var dv = cleanRut.substr(-1);
  var rest = cleanRut.substr(0, cleanRut.length - 1);
  var digits = rest
    .split("")
    .reverse()
    .map(function (d, i) {
      return [(i % 6) + 2, parseInt(d)];
    });
  var sum = digits
    .map(function (x) {
      return x[0] * x[1];
    })
    .reduce(function (x, y) {
      return x + y;
    });
  var result = 11 - (sum % 11);
  switch (result) {
    case 11:
      return "0" === dv;
    case 10:
      return "K" === dv.toUpperCase();
    default:
      return result == dv;
  }
};

export const validateTaxId = (data, code, idType) => {
  data = String(data ?? "");
  if (!idType) {
    // check all cases without considering idType
    if (code === "BRA") {
      return cpf.isValid(data) || cnpj.isValid(data);
    } else {
      return Object.values(code2RegxVal[code] || {})?.reduce((result, item) => {
        return result || item?.test(data?.replace(/[^a-zA-Z0-9]/g, ""));
      }, false);
    }
  } else if (code === "BRA") {
    if (idType === "CPF") return cpf.isValid(data);
    else if (idType === "CNPJ") return cnpj.isValid(data);
  } else if (code === "MEX" && idType === "RFC") {
    return validateRFC(data);
  } else if (code === "CHL" && (idType === "RUT" || idType === "RUN")) {
    return validateRut(data);
  } else {
    return code2RegxVal[code]?.[idType]?.test(data?.replace(/[^a-zA-Z0-9]/g, "")) === true;
  }
};

export const checkTaxId = ({ form, setForm, region, data: { number, type } = {} }) => {
  let val = number === undefined ? form.taxId.value : number;
  val = val ? String(val) : "";

  const validity = !!validateTaxId(val, region, number ? type : form?.idType || "");

  setForm((prev) => {
    return {
      ...prev,
      taxId: { ...prev.taxId, valid: validity, checked: true },
    };
  });
  return validity;
};

///////////////////////// Name

const checkNameChar = (name) => {
  name = name.replace("á", "a");
  name = name.replace("à", "a");
  name = name.replace("â", "a");
  name = name.replace("ã", "a");
  name = name.replace("Á", "A");
  name = name.replace("À", "A");
  name = name.replace("Ã", "A");
  name = name.replace("é", "e");
  name = name.replace("ê", "e");
  name = name.replace("É", "E");
  name = name.replace("Ê", "E");
  name = name.replace("í", "i");
  name = name.replace("Í", "I");
  name = name.replace("ó", "o");
  name = name.replace("ô", "o");
  name = name.replace("õ", "o");
  name = name.replace("Ó", "O");
  name = name.replace("Ô", "O");
  name = name.replace("Õ", "O");
  name = name.replace("ú", "u");
  name = name.replace("Ú", "U");
  name = name.replace("ç", "c");
  name = name.replace("Ç", "C");
  return name;
};

const leastTwoAndNotAllSame = (val) => {
  val = val ? String(val).trim() : "";
  let format = /^(\w)(?!\1+$)\w+/;
  return format.test(val);
};

export const checkIsNameValid = (val) => {
  let name = checkNameChar(String(val).trim());
  return /^[a-zA-Z ]+$/.test(name);
};

export const checkName = ({ form, setForm, data }) => {
  let val = data === undefined ? form.name.value : data;
  val = val ? String(val).trim() : "";
  let format = /[^0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]/;

  if (latinCountries.indexOf(store.getState().tradeInfo?.app_info?.region) >= 0) {
    val = checkNameChar(val);
    format = /^[a-zA-Z ]+$/;
  }

  const validity = !!(format.test(val) && leastTwoAndNotAllSame(val));

  setForm((prev) => {
    return { ...prev, name: { ...prev.name, valid: validity, checked: true } };
  });

  return validity;
};

export const checkFirstName = ({ form, setForm, data }) => {
  let val = data === undefined ? form.firstName.value : data;
  val = val ? String(val).trim() : "";
  let format = /[^0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]/;

  if (latinCountries.indexOf(store.getState().tradeInfo?.app_info?.region) >= 0) {
    val = checkNameChar(val);
    format = /^[a-zA-Z ]+$/;
  }
  const validity = format.test(val);
  setForm((prev) => {
    return {
      ...prev,
      firstName: { ...prev.firstName, valid: validity, checked: true },
    };
  });

  return validity;
};

export const checkLastName = ({ form, setForm, data }) => {
  let val = data === undefined ? form.lastName.value : data;
  val = val ? String(val).trim() : "";
  let format = /[^0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]/;

  if (latinCountries.indexOf(store.getState().tradeInfo?.app_info?.region) >= 0) {
    val = checkNameChar(val);
    format = /^[a-zA-Z ]+$/;
  }
  const validity = format.test(val);
  setForm((prev) => {
    return {
      ...prev,
      lastName: { ...prev.lastName, valid: validity, checked: true },
    };
  });
  return validity;
};

///////////////////////// Email

export const checkIsEmailValid = (val) => {
  var format =
    /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~]+([.-]?[a-zA-Z0-9!#$%&'*+/=?^_`{|}~]+)*@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
  return format.test(String(val));
};

export const checkEmail = ({ form, setForm, data }) => {
  const { method, payChannel } = store.getState();
  let val = data === undefined ? form?.email?.value : data;
  val = val ? String(val) : "";
  var format =
    /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~]+([.-]?[a-zA-Z0-9!#$%&'*+/=?^_`{|}~]+)*@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

  if (payChannel === "PPRO") {
    // Underscore (_) is not allowed in email address for PPRO
    format =
      /^([a-zA-Z0-9!#$%&'*+/=?^`{|}~]+([.-]?[a-zA-Z0-9!#$%&'*+/=?^`{|}~]+)*@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
  } else if (method === "Boleto") {
    // Sum (+) is not allowed in email address for PPRO
    format =
      /^([a-zA-Z0-9!#$%&'*/=?^_`{|}~]+([.-]?[a-zA-Z0-9!#$%&'*/=?^_`{|}~]+)*@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
  }

  const validity = format.test(val);

  setForm((prev) => {
    return {
      ...prev,
      email: { ...prev.email, valid: validity, checked: true },
    };
  });

  return validity;
};

///////////////////////// Card Number

export const checkCardNumber = ({ form, setForm, data }) => {
  let val = data ? data : form.cardNumber.value;
  val = val ? String(val).trim() : "";
  val = val?.replace(/[ ]/g, "");

  const validity = !!(val && val?.length > 13);

  setForm((prev) => {
    return {
      ...prev,
      cardNumber: { ...prev.cardNumber, valid: validity, checked: true },
    };
  });

  return validity;
};

///////////////////////// Year

export const checkYear = ({ form, setForm, data }) => {
  let val = data ? data : form.expYear.value;
  val = val ? String(val) : "";

  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      expYear: { ...prev.expYear, valid: validity, checked: true },
    };
  });

  return validity;
};

///////////////////////// Month

export const checkMonth = ({ form, setForm, data }) => {
  let val = data ? data : form.expMonth.value;
  val = val ? String(val) : "";

  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      expMonth: { ...prev.expMonth, valid: validity, checked: true },
    };
  });

  return validity;
};

///////////////////////// CVC

export const checkCvc = ({ form, setForm, data }) => {
  let val = data ? data : form.cvc.value;
  val = val ? String(val).trim() : "";

  const validity = !!(val && val.length >= 3);

  setForm((prev) => {
    return {
      ...prev,
      cvc: { ...prev.cvc, valid: validity, checked: true },
    };
  });

  return validity;
};

///////////////////////// Country

export const checkCountry = ({ form, setForm, data }) => {
  let val = data ? data : form.country.value;
  val = val ? String(val) : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      country: { ...prev.country, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkBillCountry = ({ form, setForm, data }) => {
  let val = data ? data : form.bill_country.value;
  val = val ? String(val) : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      bill_country: { ...prev.bill_country, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkDeliveryCountry = ({ form, setForm, data }) => {
  let val = data ? data : form.delivery_country.value;
  val = val ? String(val) : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      delivery_country: { ...prev.delivery_country, valid: validity, checked: true },
    };
  });
  return validity;
};

///////////////////////// State

export const checkState = ({ form, setForm, data }) => {
  let val = data ? data : form.state.value;
  val = val ? String(val) : "";

  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      state: { ...prev.state, valid: validity, checked: true },
    };
  });

  return validity;
};

export const checkBillState = ({ form, setForm, data }) => {
  let val = data ? data : form.bill_state.value;
  val = val ? String(val) : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      bill_state: { ...prev.bill_state, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkDeliveryState = ({ form, setForm, data }) => {
  let val = data ? data : form.delivery_state.value;
  val = val ? String(val) : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      delivery_state: { ...prev.delivery_state, valid: validity, checked: true },
    };
  });
  return validity;
};

///////////////////////// City

export const checkCity = ({ form, setForm, data }) => {
  let val = data ? data : form.city.value;
  val = val ? String(val) : "";

  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      city: { ...prev.city, valid: validity, checked: true },
    };
  });

  return validity;
};

export const checkBillCity = ({ form, setForm, data }) => {
  let val = data ? data : form.bill_city.value;
  val = val ? String(val) : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      bill_city: { ...prev.bill_city, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkDeliveryCity = ({ form, setForm, data }) => {
  let val = data ? data : form.delivery_city.value;
  val = val ? String(val) : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      delivery_city: { ...prev.delivery_city, valid: validity, checked: true },
    };
  });
  return validity;
};

///////////////////////// Postal code

export const checkPostalCode = ({ form, setForm, region = "", isGlobal = false }) => {
  let val = String(form.postalCode.value ?? "")
    .toUpperCase()
    .replace(/[^A-Z\d]/g, "");
  let cCodeObj = "";
  let isValid = false;

  if (form.isGlobal || isGlobal) {
    if (form.country.value) {
      cCodeObj = country2codes.find((item) => item.country_name === form.country.value);
      isValid = (cCodeObj?.postal_val ?? /^[A-Z\d]+$/).test(val);
    } else {
      isValid = false;
    }
  } else {
    cCodeObj = country2codes.find((item) => item.alpha3_code === region);
    isValid = (cCodeObj?.postal_val ?? /^[A-Z\d]+$/).test(val);
  }

  setForm((prev) => {
    return {
      ...prev,
      postalCode: { ...prev.postalCode, valid: isValid, checked: true },
    };
  });

  return isValid;
};

export const checkBillPostalCode = ({ form, setForm }) => {
  let val = String(form.bill_postalCode.value ?? "")
    .toUpperCase()
    .replace(/[^A-Z\d]/g, "");
  let cCodeObj = "";
  let isValid = false;

  if (form.bill_country.value) {
    cCodeObj = country2codes.find((item) => item.country_name === form.bill_country.value);
    isValid = (cCodeObj?.postal_val ?? /^[A-Z\d]+$/).test(val);
  } else {
    isValid = false;
  }

  setForm((prev) => {
    return {
      ...prev,
      bill_postalCode: { ...prev.bill_postalCode, valid: isValid, checked: true },
    };
  });

  return isValid;
};

export const checkDeliveryPostalCode = ({ form, setForm }) => {
  let val = String(form.delivery_postalCode.value ?? "")
    .toUpperCase()
    .replace(/[^A-Z\d]/g, "");
  let cCodeObj = "";
  let isValid = false;

  if (form.delivery_country.value) {
    cCodeObj = country2codes.find((item) => item.country_name === form.delivery_country.value);
    isValid = (cCodeObj?.postal_val ?? /^[A-Z\d]+$/).test(val);
  } else {
    isValid = false;
  }

  setForm((prev) => {
    return {
      ...prev,
      delivery_postalCode: { ...prev.delivery_postalCode, valid: isValid, checked: true },
    };
  });

  return isValid;
};

///////////////////////// Street

export const checkStreet = ({ form, setForm, data }) => {
  let val = data ? data : form.street.value;
  val = val ? String(val).trim() : "";

  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      street: { ...prev.street, valid: validity, checked: true },
    };
  });

  return validity;
};

export const checkBillStreet = ({ form, setForm, data }) => {
  let val = data ? data : form.bill_street.value;
  val = val ? String(val).trim() : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      bill_street: { ...prev.bill_street, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkDeliveryStreet = ({ form, setForm, data }) => {
  let val = data ? data : form.delivery_street.value;
  val = val ? String(val).trim() : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      delivery_street: { ...prev.delivery_street, valid: validity, checked: true },
    };
  });
  return validity;
};

///////////////////////// Address number

export const checkNo = ({ form, setForm, data }) => {
  let val = data ? data : form.no.value;
  val = val ? String(val).trim() : "";

  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      no: { ...prev.no, valid: validity, checked: true },
    };
  });

  return validity;
};

export const checkBillNo = ({ form, setForm, data }) => {
  let val = data ? data : form.bill_no.value;
  val = val ? String(val).trim() : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      bill_no: { ...prev.bill_no, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkDeliveryNo = ({ form, setForm, data }) => {
  let val = data ? data : form.delivery_no.value;
  val = val ? String(val).trim() : "";
  const validity = !!val;

  setForm((prev) => {
    return {
      ...prev,
      delivery_no: { ...prev.delivery_no, valid: validity, checked: true },
    };
  });
  return validity;
};

///////////////////////////////////////////////////

export const checkToditoNip = ({ form, setForm, data }) => {
  let val = data ? data : form.todito_nip.value;
  val = val ? String(val).trim() : "";
  const validity = !!(val && val?.length === 4);

  setForm((prev) => {
    return {
      ...prev,
      todito_nip: { ...prev.todito_nip, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkToditoCardNo = ({ form, setForm, data }) => {
  let val = data ? data : form.todito_cardNo.value;
  val = val ? String(val).trim() : "";

  const validity = val.length >= 10 && val.length <= 18;

  setForm((prev) => {
    return {
      ...prev,
      todito_cardNo: { ...prev.todito_cardNo, valid: validity, checked: true },
    };
  });

  return validity;
};

export const checkToditoPin = ({ form, setForm, data }) => {
  let val = data ? data : form.todito_pin.value;
  val = val ? String(val).trim() : "";

  const validity = val.length === 4;

  setForm((prev) => {
    return {
      ...prev,
      todito_pin: { ...prev.todito_pin, valid: validity, checked: true },
    };
  });

  return validity;
};

export const checkMachCardNo = ({ form, setForm, data }) => {
  let val = data ? data : form.mach_cardNo.value;
  val = val ? String(val).trim() : "";
  let reg = /\d{16}/;

  const validity = reg.test(val?.replace(/[^\d]/g, ""));

  setForm((prev) => {
    return {
      ...prev,
      mach_cardNo: { ...prev.mach_cardNo, valid: validity, checked: true },
    };
  });

  return validity;
};

export const checkVitaId = ({ form, setForm, data }) => {
  let val = data ? data : form.vita_id.value;
  val = val ? String(val).trim() : "";

  const validity = !!val;
  setForm((prev) => {
    return {
      ...prev,
      vita_id: { ...prev.vita_id, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkVitaPassword = ({ form, setForm, data }) => {
  let val = data ? data : form.vita_password.value;
  val = val ? String(val).trim() : "";
  const validity = !!val;
  setForm((prev) => {
    return {
      ...prev,
      vita_password: { ...prev.vita_password, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkSource = ({ form, setForm, data }) => {
  let val = data ? data : form?.source?.value;
  val = val ? String(val).trim() : "";
  const validity = !!val;
  setForm((prev) => {
    return {
      ...prev,
      source: { ...prev.source, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkIsSourceValid = (data) => {
  data = data ? String(data).trim() : "";
  return !!data;
};

export const checkCEP = ({ form, setForm, data }) => {
  let val = data ? data : form.boleto_cep.value;
  val = val ? String(val) : "";
  let format = /^(\d)(?!\1+$)\d{7}$/; // 8 digit which in all digits are not same
  const validity = format.test(val.replace(/[^\d]+/g, ""));
  setForm((prev) => {
    return {
      ...prev,
      boleto_cep: {
        ...prev.boleto_cep,
        valid: validity,
        checked: true,
      },
    };
  });
  return validity;
};

export const validateCep = (number) => {
  let val = number ? String(number) : "";
  let format = /^(\d)(?!\1+$)\d{7}$/; // 8 digit which in all digits are not same
  return format.test(val.replace(/[^\d]+/g, ""));
};

export const checkAccountNumber = ({ form, setForm, data, region }) => {
  let val = data ? data : form.account_number.value;
  const bankIdToRegex = {
    URY: {
      1001: /^(\d{14})$/, // BROU
      1110: /^(\d{9})$/, // Banco Bandes
      1153: /^(\d{1,9})$/, // BBVA
      1091: /^(\d{10})$/, // BHU
      1113: /^(\d{7})$/, // ITAU
      1137: /^(\d{12})$/, // SANTANDER
      1246: /^(\d{12})$/, // NACION
      361: /^(\d{7})$/, // BAPRO
      1162: /^(\d{9})$/, // HERITAGE
      1205: /^(\d{10})$/, // CITIBANK
      1157: /^(\d{10})$/, // HSBC
      7905: /^(\d{8})$/, // PREX (FORTEX)
      1128: /^(\d{10})$/, // SCOTIABANK
    },
  };
  const countToRegex = {
    BOL: /^(\d{7,14})$/,
    PRY: /^(\d{6,16})$/,
    ECU: /^(\d{5,20})$/,
    PER: /^(\d{20})$/,
  };
  if (region === "ARG") {
    const bankId = form.bank_list?.value?.bank_id;
    const regexPattern = `^${bankId}[0-9]{${22 - bankId?.length}}$`;
    const regex = new RegExp(regexPattern);
    countToRegex["ARG"] = regex;
  } else if (region === "PER") {
    const bankId = form.bank_list?.value?.bank_id;
    const regexPattern = `^${bankId}[0-9]{${20 - bankId?.length}}$`;
    const regex = new RegExp(regexPattern);
    countToRegex["PER"] = regex;
  } else if (region === "URY") {
    const bankId = form.bank_list?.value?.bank_id;
    const regex = bankIdToRegex["URY"][bankId] || /^({--})$/;
    countToRegex["URY"] = regex;
  }
  // const validity = bank ? bankToRegex[bank]?.test(val) : false;
  const validity = countToRegex[region] ? countToRegex[region].test(val) : !!val;
  setForm((prev) => {
    return {
      ...prev,
      account_number: {
        ...prev.account_number,
        valid: validity,
        checked: true,
      },
    };
  });
  return validity;
};

export const checkBankList = ({ form, setForm, data }) => {
  let val = data ? data : form?.bank_list?.value;
  val = val ? String(val).trim() : "";
  const validity = !!val;
  setForm((prev) => {
    return {
      ...prev,
      bank_list: { ...prev.bank_list, valid: validity, checked: true },
    };
  });
  return validity;
};

export const checkAddress = ({ form, setForm, data }) => {
  let val = data ? data : form?.address?.value;
  val = val ? String(val).trim() : "";
  const validity = val.length > 4;
  setForm((prev) => {
    return {
      ...prev,
      address: { ...prev.address, valid: validity, checked: true },
    };
  });
  return validity;
};
