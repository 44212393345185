import React, { forwardRef, useState } from "react";
import "./DropDownMenu.scss";
import { connect } from "react-redux";
import Chat from "../img/客服.png";
import ChatM from "../img/客服black.png";
import FaqImg from "../img/faq.png";
import FaqImgM from "../img/faq_black.png";
import Back from "../img/back.png";
import Instructions from "../img/图文详情.png";
import InstructionsM from "../img/图文详情black.png";
import Faq from "./Faq";
import VideoContent from "./VideoContent";
import { useTranslation } from "react-i18next";
import { useMethodConfig } from "../utils/configurations";
import { formatMethod } from "../utils/formats";

function DropDownMenu(props, ref) {
  const [pageState, setPageState] = useState(props.state);
  const { t, i18n } = useTranslation();
  const methodConfig = useMethodConfig();
  const handleLangChange = (event) => {
    i18n.changeLanguage(event.target.id);
  };
  return (
    <div ref={ref} className="dropDown">
      <div className="dropDown__back">
        <img src={Back} alt="" onClick={props.back} />
      </div>
      {pageState === "main" && (
        <>
          <div className="dropDown__main">
            <div
              className="dropDown--section"
              onClick={() => {
                window?.LC_API?.open_chat_window();
              }}
            >
              <div className="dropDown--header">
                <img src={Chat} alt="" />
                <p>{t("help_chat")}</p>
              </div>
              {formatMethod(props.tradeInfo?.channel_info_list)?.map((item) => {
                return <p>{methodConfig[item.method]?.info?.text}</p>;
              })}
            </div>
            {/* <div
              className="dropDown--section"
              onClick={() => {
                setPageState("inst");
              }}
            >
              <div className="dropDown--header">
                <img src={Instructions} alt="" />
                <p>{t("instructions")}</p>
              </div>
              <p>{t("videos")}</p>
              <p>{t("pictures")}</p>
              <p>{t("text")}</p>
            </div> */}
            {methodConfig[props.method]?.faq && (
              <div
                className="dropDown--section"
                onClick={() => {
                  setPageState("faq");
                }}
              >
                <div className="dropDown--header">
                  <img src={FaqImg} alt="" />
                  <p>FAQ</p>
                </div>
                {props.tradeInfo?.channel_info_list?.map((item) => {
                  return methodConfig[item.method]?.faq?.map((item) => <p>{item.q}</p>);
                })}
              </div>
            )}
          </div>
          <div className="dropDown__mainMobile">
            <div className="dropDown__mainMobile__content">
              <div
                className="dropDown__mainMobile__title"
                onClick={() => {
                  window?.LC_API?.open_chat_window();
                }}
              >
                <img src={ChatM} alt="" />
                <p>{t("help_chat")}</p>
              </div>
              {/* <div
                className="dropDown__mainMobile__title"
                onClick={() => {
                  setPageState("inst");
                }}
              >
                <img src={InstructionsM} alt="" />
                <p>{t("instructions")}</p>
              </div> */}
              {methodConfig[props.method]?.faq && (
                <div
                  className="dropDown__mainMobile__title"
                  onClick={() => {
                    setPageState("faq");
                  }}
                >
                  <img src={FaqImgM} alt="" />
                  <p>FAQ</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {pageState === "lang" && (
        <div className="dropDown__lang">
          <p
            className="dropDown__lang__title"
            id="en"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            {t("en")}
          </p>
          <p
            className="dropDown__lang__title"
            id="es"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            {t("es")}
          </p>
          <p
            className="dropDown__lang__title"
            id="pt"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            {t("pt")}
          </p>
          <p
            className="dropDown__lang__title"
            id="ar"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            {t("ar")}
          </p>
        </div>
      )}

      {pageState === "faq" && <Faq />}
      {pageState === "inst" && (
        <div className="dropDown__ins">
          {props.tradeInfo?.channel_info_list?.map((item) => {
            return methodConfig[item.method]?.video?.map((item) => (
              <VideoContent title={item.title} source={item.source} />
            ));
          })}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    method: state.method,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(forwardRef(DropDownMenu));
