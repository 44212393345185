import { privacyPt } from "./Privacy Policy/privacy";

const pt = {
  translation: {
    ...privacyPt,
    en: "Inglês",
    es: "Espanhol",
    pt: "Português",
    ry: "Russo",
    ar: "Árabe",
    fail_change_method: "Algo deu errado. Use outro método de pagamento.",
    crypto: "Criptomoedas",
    search: "Pesquisar",
    select_bank: "Selecione seu banco",
    bank_code: "Código do banco:",
    no_channel: "Sem canal, entre em contato com a loja",
    mon_bankMobile: "Banca Móvil",
    mon_bankWeb: "Banca Web",
    mon_bank: "Ventanilla",
    mon_agent: "Agentes",
    mon_chooseBank: "1. Escoge el banco o agente",
    mon_chooseMethod: "2. Escoge el método de pago",
    faq: "Perguntas Frequentes",
    pago_method: "Banco Móvel, QR (Yape, Plin), Agentes e Armazéns",
    efecty_faq1q: "O que é Efecty?",
    efecty_faq1a:
      "Efecty é uma empresa com sede na Colômbia que oferece uma variedade de métodos de pagamento, incluindo pedidos de dinheiro, pagamentos, recargas e cobranças em todo o país. Os clientes podem pagar online e pagar mais tarde em qualquer um dos 8000 pontos de atendimento no país.",
    efecty_faq2q: "Como posso encontrar um lugar para pagar em dinheiro?",
    efecty_faq2a: "Vá para https://mapapap.efecty.com.co/pagos-personas e pesquise seu endereço",
    efecty_faq3q: "Como saber se meu pedido foi bem sucedido?",
    efecty_faq3a:
      "As compras são aprovadas imediatamente e você vai receber um email confirmando seu pagamento.  Se você tiver dúvidas sobre a compra que está fazendo, ligue para nós nas linhas de atendimento ao cliente.",
    khipu_faq1q: "O que é Khipu?",
    khipu_faq1a:
      "Khipu é uma maneira de receber pagamentos com transferência que funciona em qualquer dispositivo, responsavelmente, e em qualquer navegador.",
    khipu_faq2q: "Como funciona?",
    khipu_faq2a:
      "Basta inserir suas informações khipu (número do cartão e senha) e concluir a transação no site ou aplicativo do seu banco.",
    khipu_faq3q: "Como saber se meu pedido foi bem sucedido?",
    khipu_faq3a:
      "As compras são aprovadas imediatamente e você vai receber um email confirmando seu pagamento.  Se você tiver dúvidas sobre a compra que está fazendo, ligue para nós nas linhas de atendimento ao cliente.",
    help_chat: "Chat de ajuda",
    okay: "Certo!",
    select_notice: "Selecionar método de pagamento",
    instructions: "Instruções",
    videos: "Videos",
    pictures: "Fotos",
    text: "Texto",
    online_inst1: "Log on to online banking as usual.",
    online_inst2: "Enter your 6 digit password.",
    online_inst3: "Select Payment and Services and companies category. Then enter in the search “SafetyPay”.",
    online_inst4:
      "Selecting this option will request the transaction number and the amount that was provided by the merchant.",
    online_inst5: "Confirm the transaction by entering your electronic key (token) and you are done!",
    payment_method: "Método de pagamento",
    online_banking: "Online Banking",
    cash_payments: "Pagamento em dinheiro",
    bank_agent: "Escolha seu banco ou agente",
    filling_details: "Detalhes pessoais",
    fill_info: "Preencha as informações",
    details: "Detalhes",
    name: "Nome",
    email: "Email",
    TIN: "CPF",
    phone: "Número de celular",
    time_remain: "Tempo restante",
    trx_id: "ID da transação",
    hide: "Esconder",
    cash: "Dinheiro",
    show: "Mostrar",
    product_description: "Descrição do produto",
    order_value: "Valor do pedido",
    bank_fee: "Taxa do banco",
    discount: "Desconto",
    confirm: "Confirmar",
    return: "Return",
    attention: "ATENÇÃO: Por favor veja as informações abaixo.",
    total: "Total",
    privacy_policy_error:
      "Você deve ler e aceitar os Termos de Uso e a Política de Privacidade para concluir sua compra.",
    privacy_policy1: "Li e concordo com os Termos de Uso e",
    privacy_policy2: " Política de Privacidade",
    privacy_policy3: " do Luxpag.",
    invalid_name: "Nome inválido",
    invalid_TIN: "ID inválido",
    invalid_email: "Email inválido",
    invalid_phone: "Número de telefone inválido",
    payment_inst: "Instruções de pagamento:",
    payment_inst_top:
      "Registramos seu pedido e estamos aguardando o pagamento para começar a entrega. Só falta um pouquinho!",
    payment_ticket: "Tíquete de Pagamento",
    payment_code: "Código de pagamento",
    pay: "Pagar:",
    pay2: "Pagar",
    processing: "Processando",
    processing_notice: "A transação está em andamento, é rapidinho. Obrigado por usar Luxpag!",
    success: "Pagamento feito com sucesso",
    success_notice:
      "Você pagou o pedido com sucesso. Se quiser, pode voltar para a página do vendedor. Obrigado por usar Luxpag!",
    cancelled: "Cancelado",
    cancelled_notice: "O prazo para o seu pagamento terminou, volte à página do vendedor para fazer um novo pedido.",
    failed: "Falhou",
    copied: "Copiado com sucesso",
    copy: "Cópia",
    visit_us: "Para saber mais sobre nós, visite",
    fail_notice: "É possível que alguma informação esteja errada, tente novamente ou fale com a gente",
    fail_query: "Quer tentar de novo?",
    yes: "Sim, quero",
    help_me: "Ajuda",
    order: "Pedido",
    back_shop: "De volta às compras",
    credit_debit_card: "Cartão de Crédito/Débito",
    credit_card: "Cartão de Crédito",
    debit_card: "Cartão de Débito",
    "payment.redirect.notice1": "Você será redirecionado para a página do lojista em {{key}} segundos.",
    "payment.redirect.notice2": "Clique ",
    "payment.redirect.notice3": "aqui para",
    "payment.redirect.notice4": " cancelar.",
    oxxo: "Pago en efectivo OXXO",
    wallet: "Carteira Digital",
    cardholder_name: "Nome do titular do cartão",
    card_no: "Número do cartão",
    invalid_card_no: "Número do cartão inválido",
    exp_year: "Ano de valid.",
    exp_date: "Data de validade (MM/YYYY)",
    invalid_exp_date: "Data de validade inválido",
    invalid_year: "Selecione um ano",
    exp_month: "Mês",
    month: "mês",
    invalid_month: "Selecione um mês",
    state: "Estado",
    invalid_state: "Selecione um Estado",
    city: "cidade",
    invalid_city: "Selecione uma cidade",
    postal_code: "Código postal",
    invalid_postal_code: "CEP inválido",
    street: "Rua",
    invalid_street: "Rua inválida",
    number: "Número",
    invalid_number: "Número inválido",
    source: "Site ou nome do APP onde você comprou o produto",
    invalid_source: "Inválido",
    source_notice: "Se as informações inseridas estiverem incorretas, o pagamento do seu pedido pode falhar",
    todito_notice: "Insira suas informações do Todito Cash abaixo:",
    "checkout_inst1.1": "Caro usuário, pague",
    "checkout_inst1.2": "para finalizar sua compra.",
    "checkout_inst2.1": "Leia o código QR para ir para",
    "checkout_inst2.1.1": "Clique no botão para ir para",
    "checkout_inst2.2": "APP e conclua o pagamento.",
    go_to: "Vá para ",
    checkout_inst3: "1. Vá para a loja mais próxima",
    checkout_inst4: "2. Informe ao caixa que você tem um pagamento do Luxpag e mostre este número:",
    share: "Quota",
    save_pdf: "Salvar PDF",
    checkout_inst5: "Abra o aplicativo do seu banco e insira as informações abaixo",
    checkout_inst6: "Insira este número de referência:",
    register_info: "Informações para registrar a conta:",
    installments: "Parcelamento",
    invalid_cvv: "CVV invalido",
    invalid_PIN: "PIN invalido",
    invalid_NIP: "NIP invalido",
    paid_major: "Maior parte paga",
    paid_minor: "Parte secundária paga",
    refund_verifying: "Verificando reembolso",
    refund_processing: "Processando reembolso",
    risk_control: "Controle de riscos",
    refunded: "Reembolsado",
    partial_refund: "Parcialmente reembolsado",
    chargeback: "Estorno",
    chargeback_reversed: "Estorno revertido",
    dispute: "Disputa",
    dispute_reversed: "Disputa revertida",
    timeout: "Tempo limite da transação esgotado",
    payment_refuse: "Pagamento recusado",
    payment_fail: "Pagamento falhou",
    refund_reverse: "Reembolso revertido",
    refund_refuse: "Reembolso recusado",
    pay_close: "Fechado",
    fail_sec: "Para a segurança da sua transação, o pedido foi encerrado. Por favor tente novamente",
    invalid_cep: "CEP invalido",
    boleto_inst1: "Boleto gerado!",
    boleto_inst2: "Você pode pagar em bancos, lotéricas, Correios, supermercados ou online.",
    boleto_inst3:
      "Já enviamos um e-mail com os detalhes e você também pode salvar um arquivo clicando no botão‘ Salvar PDF ’abaixo.",
    deposit_upload_inst1: "É bom ter você de volta!",
    deposit_upload_inst2:
      "Estamos quase lá, basta preencher este pequeno formulário e fazer upload do comprovante de pagamento para finalizar :)",
    upload: "Carregar",
    back: "Voltar",
    wallet_inst1: "Escaneie e pague",
    wallet_inst2: "Você pode escanear e pagar usando:",
    wallet_inst3: "Como pagar?",
    wallet_inst4: "1. Abra o aplicativo de carteira no seu dispositivo",
    wallet_inst5: '2. Aba o recurso "escanear"',
    wallet_inst6: "3. Escaneie o QR de pagamento e conclua o pagamento",
    wallet_mob_ins1: "Clique e pague",
    wallet_mob_ins2: "Você pode clicar e pagar usando:",
    wallet_mob_ins3: "1. Clique no botão abaixo para ir para o APP ",
    " wallet_mob_ins3.1": " em seu telefone",
    wallet_mob_ins4: "2. Prossiga para concluir o pagamento",
    "SYS-0001":
      "Seu pagamento foi recusada. Escolha outra forma de pagamento. Recomendamos meios de pagamento em dinheiro.",
    "SYS-0002": "Para a segurança da sua transação, a ordem está encerrada, por favor reenvie-o.",
    "SYS-0003": "Por favor escolha o valor mais alto.",
    "SYS-0004": "Por favor forneça o email válido.",
    "CC-0001": "Pagamento negado. Nome e CPF não são correspondentes. Por favor insira a informação correta.",
    "CC-0002": "Token de cartão inválido",
    "CC-0003": "Estamos processando o pagamento. Em até 2 dias úteis informaremos por e-mail o resultado.",
    "CC-0004":
      "Estamos processando o pagamento. Em até 2 dias úteis informaremos por e-mail se foi aprovado ou se precisamos de mais informações.",
    "CC-0005": "Confira o número do cartão.",
    "CC-0006": "Confira a data de validade.",
    "CC-0007": "Confira os dados.",
    "CC-0008": "Confira o código de segurança.",
    "CC-0009": "Não conseguimos processar seu pagamento.",
    "CC-0010": "Você deve autorizar ao Cartão o pagamento do valor ao Luxpag.",
    "CC-0011": "Ligue para o Cartão para ativar seu cartão. O telefone está no verso do seu cartão.",
    "CC-0012": "Não conseguimos processar seu pagamento.",
    "CC-0013":
      "Você já efetuou um pagamento com esse valor. Caso precise pagar novamente, utilize outro cartão ou outra forma de pagamento.",
    "CC-0014":
      "Seu pagamento foi recusado. Escolha outra forma de pagamento. Recomendamos meios de pagamento em dinheiro.",
    "CC-0015": "O Cartão possui saldo insuficiente.",
    "CC-0016": "O Cartão não processa pagamentos parcelados.",
    "CC-0017": "Você atingiu o limite de tentativas permitido. Escolha outro cartão ou outra forma de pagamento.",
    "CC-0018": "O Cartão não processou seu pagamento.",
    bank_deposit: "Depósito Bancário",
    payment_lottery: "Pagamento na Lotérica",
    checkoutLottery_title:
      "Este meio de pagamento não é via boleto bancário. Pague na lotérica utilizando os dados abaixo:",
    barcode: "código de barras",
    checkoutLottery_contentText1: "Código do convênio",
    checkoutLottery_contentText2: "Número de CPF/CNPJ",
    checkoutLottery_instContainer1: "Dúvida ao realizar o pagamento？Clique",
    checkoutLottery_instContainer2: "aqui",
    checkoutLottery_instContainer3: "e assista ao vídeo explicativo.",
    checkoutLottery_instContainer4: "A aprovação acontece em até 1 hora após o pagamento",

    depositExpress_inst_title1: "O pagamento é feito em dois passos:",
    depositExpress_inst_title2: "1. Faça uma transferência, depósito ou PIX",
    depositExpress_inst_title3: "2. Envie o comprovante de pagamento",
    depositExpress_bank_name: "Nome do banco:",
    depositExpress_provider_agency: "Agência:",
    depositExpress_provider_number: "Conta corrente:",
    depositExpress_provider_owner: "Titular:",
    depositExpress_provider_owner_document: "CNPJ:",
    depositExpress_provider_chave_pix: "Chave PIX:",
    depositExpress_buttonContainer: "Clique aqui para enviar o comprovante",
    payment_type_value: "Tipo de Pagamento",
    payment_type_value_invalid: "Tipo de pagamento inválido",
    agent_value_helpertext: "Correto:Comprimento 4 a 6 bits,Pode haver '-' no meio",
    agent_value_label: "Agência",
    account_valid_helpertext:
      "O formato correto tem 5 a 12 dígitos. Começa com um número, deve haver um - no meio, e o último dígito é um número ou x(X)",
    account_valid_label: "Conta",
    name_valid_label: "Nome completo",
    drop_zone_custom_title: "Carregar comprovante de pagamento",
    file_valid_text: "Por favor carregue um arquivo",
    depositExpress_uploadSuccess:
      "Arquivo enviado com sucesso. Aguarde o processamento e a confirmação do seu pagamento.",

    checkoutPix_instContainer:
      "Por favor abra o seu aplicativo de pagamento e escaneie o código QR abaixo para pagar ou copie o código Pix abaixo e cole em seu app de pagamenm para f inalizar a compra.",

    checkoutSpei_openpay_h4: "De BBVA",
    checkoutSpei_openpay_content1:
      '1. No menu "Pagar", selecione a opção "Serviços" e digite o seguinte "Número do Contrato CIE"',
    checkoutSpei_openpay_instValue: "Número do contrato CIE:",
    checkoutSpei_openpay_content2: "2. Insira os dados de registro para concluir a operação.",
    checkoutSpei_reference: "Referência:",
    checkoutSpei_openpay_monto: "Monto:",
    checkoutSpei_openpay_from: "De qualquer outro banco",
    checkoutSpei_openpay_content3:
      "1. Entre na seção de transferências e pagamentos ou pagamentos a outros bancos e forneça os dados de transferência:",
    checkoutSpei_openpay_beneficiary: "Beneficiário:",
    checkoutSpei_destinationbank: "Banco de destino:",
    checkoutSpei_description: "Descrição:",
    checkoutSpei_amount: "Quantia:",
    timed_out: "A solicitação de transação expirou!",
    network_err: "Erro de rede, por favor, tente novamente.",
    system_busy: "Sistema ocupado, por favor tente novamente mais tarde.",
    pay_points1: "Você pode clicar",
    pay_points2: " aqui ",
    pay_points3: "para ver todos os pontos de pagamento.",
    clap_cash_title: "How to pay?",
    pay_cash_ins1: "Go to your nearest PAYCASH payment point and request to pay with the code ",
    clap_cash_ins1: "Go to your nearest Klap EFECTIVO point and request to pay with the code ",
    clap_cash_ins2: "Tell the person at the merchant that the options on the machine are 2-8-1",
    clap_cash_ins3: "Pay and receive your receipt",
    pay_points_search: " para pesquisar o ponto de pagamento mais próximo.",
    klap_store1: "Use",
    klap_store2: " este link ",
    klap_store3: "para procurar a loja Klap mais próxima",
    address_billing: "Endereço de cobrança:",
    address_delivery: "Endereço de entrega:",
    address_same: "O mesmo endereço de cobrança",
    select_country: "Selecione um país primeiro!",
    country: "País",
    invalid_country: "Selecione um pais",
    baloto_notice1: "Recibo VIA Baloto generado!",
    baloto_notice2: "Para realizar tu pago informa los siguientes dato en el punto VIA Baloto de tu preferencia:",
    sured_notice1: "Recibo Su Red generado!",
    sured_notice2: "Para realizar tu pago informa los siguientes dato en el punto Su Red de tu preferencia:",
    gana_notice1: "Recibo Gana generado!",
    gana_notice2: "Para realizar tu pago informa los siguientes dato en el punto Gana de tu preferencia:",
    ref_no: "No. de pago: ",
    agree_code: "Convenio: ",
    id: "Cedula de identidad: ",
    amount: "Total a pagar: ",
    due_date: "Fecha de vencimiento",
    barcode_step1: "Vá até o ponto pagamento em dinheiro mais próximo e solicite o pagamento com o código ",
    bank_list: "Lista de banco",
    bank_select: "Selecione um banco",
    agent: "Agentes",
    agent_select: "Selecione um agente",
    account_number: "Número da conta",
    account_invalid: "Número da conta inválido",
    checking_account: "Cuenta corriente",
    saving_account: "Cuenta de ahorros",
    master_account: "Cuenta administradora",
    lp_ins1: "O Pagamento pode ser feito por transferência bancária ou depósito.",
    lp_ins2: "Data de validade: ",
    lp_ins3: "Número de referência:",
    lp_ins4: "Informação da conta:",
    lp_ins5: "Nome do banco",
    lp_ins6: "Tipo de conta",
    lp_ins7: "Número da conta",
    lp_ins8: "Nome do beneficiário",
    lp_ins9: "Tipo de documento",
    lp_ins10: "ID do documento",
    lp_ins11: "O número de referência deve ser exatamente o mesmo.",
    refresh: "Recarregar",
    refresh_notice: "A página será atualizada automaticamente em 30 segundos, caso contrário, clique no botão abaixo.",
    trsf1: "Abra seu aplicativo de instituições financeiras",
    trsf2: "Vá para: Serviços > Transações > Transfiya",
    trsf3: "Selecione a conta com a qual deseja pagar",
    trsf4: "Pague nesta conta: ",
    trsf5: "Lembre-se de transferir o valor exato de ",
    trsf6: ", caso contrário a transação falhará",
    spei_key: "Esta chave só pode ser usada uma vez",
    spei_clabe: "CLABE interbancária",
    spei_bank: "Banco destino",
    spei_recipient: "Beneficiário",
    baloto_faq1q: "¿Cómo pagar con VIA Baloto?",
    baloto_faq1a:
      "Dígale los datos al cajero en el punto VIA Baloto de su preferencia y verifique si todo está igual que en el recibo. Este cupón solo es válido para este pedido. Si realiza dos pagos, se cancelarán.",
    payvalida_notice: "Recibo {{key}} generado!",
    payvalida_notice2: "Para realizar tu pago informa los siguientes dato en el punto {{key}} de tu preferencia:",
    address: "Endereço",
    invalid_address: "Endereço inválido",
    bncr_cash1:
      "Vá ao ponto de pagamento do BN Servicios mais próximo e solicite ao caixa que efetue um pagamento {{key}} com o número de referência fornecido acima",
    bncr_cash2: "Pague e receba seu voucher",
    bncr_bank1: "Entre no Internet Banking do Banco Nacional com sua identificação e senha",
    bncr_bank2: "Realize a busca pelo nome: Payválida e digite o número de referência",
    bncr_bank3: "O número de referência será confirmado, selecione a forma de pagamento e aguarde a confirmação",
    bncr_paycash0:
      "Vá ao ponto de pagamento do BN Servicios mais próximo e peça ao caixa para efetuar o pagamento em PayCash com o código ",
    bncr_paycash1: "Entre no Internet Banking do Banco Nacional com sua identificação e senha",
    bncr_paycash2: "Faça a busca pelo nome: Paycash e digite número de referência de 16 dígitos",
    bncr_paycash3: "Pagamento confirmado, selecione a forma de pagamento e aguarde a confirmação",
    digital_banking: "Banco digital",
    20000: "Serviço atualmente indisponível",
    40002: "A transação falhou",
    40013: "Pedido não existe",
    40014: "Pedido terminou",
    40015: "As informações do pedido são inconsistentes",
    40016: "Valor máximo (incluindo taxas) excedido",
    40024: "Tempo limite do pedido",
    20005: "Falha ao carregar",
    90001: "Banco esta ocupado",
    "70001-10001": "O CPF digitado é inválido, digite outro",
    "70001-10002": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
    "70001-10003": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
    "70001-10004": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
    "70001-20001": "Limite de transação excedido",
    "70001-99999": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
    err_100001: "Algo deu errado, tente novamente mais tarde",
    err_100002: "Algo deu errado. Use outro método de pagamento.",
    err_100003: "Algo deu errado. Por favor, tente fazer o upload novamente",
    err_100004: "Falha no upload",
    err_10000: "Sucesso",
    err_20000: "Serviço atualmente indisponível",
    err_20001: "Os parâmetros obrigatórios estão faltando",
    err_20002: "Método não suportado",
    err_20003: "Tipo de mídia não suportado",
    err_40000: "A transação falhou",
    err_40001: "O número do comerciante é inválido",
    err_40002: "Comerciante está bloqueado",
    err_40003: "Número de pedido do comerciante duplicado",
    err_40004: "A interface de integração não é compatível",
    err_40005: "Não suporta conexão direta",
    err_40010: "Tipo de assinatura inválido",
    err_40011: "Assinatura inválida",
    err_40012: "Pedido não existe",
    err_40013: "Pedido terminou",
    err_40014: "As informações do pedido são inconsistentes",
    err_40015: "Valor máximo (incluindo taxas) excedido",
    err_40016: "Moeda não é suportada",
    err_40017: "Incapaz de obter a taxa de câmbio",
    err_40020: "App é inválido",
    err_40021: "App não existe",
    err_40022: "A chave do aplicativo é inválida",
    err_40023: "O ID do aplicativo é inconsistente",
    err_40101: "Tempo limite do pedido",
    err_40201: "Falha ao carregar",
    err_40301: "Banco esta ocupado",
  },
};

export default pt;
