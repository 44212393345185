import React, { useState, useEffect } from "react";
import "./RefundConfirmation.scss";
import { useTranslation } from "react-i18next";
import OrderDetails from "./OrderDetails";
import TitleWithDetails from "./TitleWithDetails";
import { FormatMoney, formatTaxId } from "../utils/formats";
import Name from "./form-input/Name";
import { connect } from "react-redux";
import TaxId from "./form-input/TaxId";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Correct from "../img/correct.jpg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { checkName, checkTaxId, validateTaxId } from "../utils/validations";
import req from "../utils/axios";
import { useHistory } from "react-router-dom";
import { setTradeStatus } from "../redux/actions";
import CircularProgress from "@material-ui/core/CircularProgress";

const bankList = {
  "BCO DO BRASIL S.A.": "001",
  "BCO DA AMAZONIA S.A.": "003",
  "BCO DO NORDESTE DO BRASIL S.A.": "004",
  BNDES: "007",
  CREDICOAMO: "010",
  "C.SUISSE HEDGING": "011",
  "BANCO INBURSA": "012",
  "STATE STREET BR S.A. BCO COMERCIAL": "014",
  "UBS BRASIL CCTVM S.A.": "015",
  "CCM DESP TRÂNS SC E RS": "016",
  "BNY MELLON BCO S.A.": "017",
  "BCO TRICURY S.A.": "018",
  "BCO BANESTES S.A.": "021",
  "BCO BANDEPE S.A.": "024",
  "BCO ALFA S.A.": "025",
  "BANCO ITAÚ CONSIGNADO S.A.": "029",
  "BCO SANTANDER (BRASIL) S.A.": "033",
  "BCO BBI S.A.": "036",
  "BCO DO EST. DO PA S.A.": "037",
  "BCO CARGILL S.A.": "040",
  "BCO DO ESTADO DO RS S.A.": "041",
  "BCO DO EST. DE SE S.A.": "047",
  "CONFIDENCE CC S.A.": "060",
  "HIPERCARD BM S.A.": "062",
  "BANCO BRADESCARD": "063",
  "GOLDMAN SACHS DO BRASIL BM S.A.": "064",
  "BCO ANDBANK S.A.": "065",
  "BCO MORGAN STANLEY S.A.": "066",
  "BCO CREFISA S.A.": "069",
  BRB: "070",
  "BCO. J.SAFRA S.A.": "074",
  "BCO ABN AMRO S.A.": "075",
  "BCO KDB BRASIL S.A.": "076",
  "BANCO INTER": "077",
  "HAITONG BI DO BRASIL S.A.": "078",
  "BCO ORIGINAL DO AGRO S/A": "079",
  "B&T CC LTDA.": "080",
  "BANCOSEGURO S.A.": "081",
  "BANCO TOPÁZIO S.A.": "082",
  "BCO DA CHINA BRASIL S.A.": "083",
  "UNIPRIME NORTE DO PARANÁ": "084",
  "COOP CENTRAL AILOS": "085",
  "BANCO RANDON S.A.": "088",
  "CREDISAN CC": "089",
  "CCCM UNICRED CENTRAL RS": "091",
  "BRK S.A. CFI": "092",
  "POLOCRED SCMEPP LTDA.": "093",
  "BANCO FINAXIS": "094",
  "TRAVELEX BANCO DE CÂMBIO S.A.": "095",
  "BCO B3 S.A.": "096",
  "CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.": "097",
  "CREDIALIANÇA CCR": "098",
  "UNIPRIME CENTRAL CCC LTDA.": "099",
  "PLANNER CV S.A.": "100",
  "RENASCENCA DTVM LTDA": "101",
  "XP INVESTIMENTOS CCTVM S/A": "102",
  "CAIXA ECONOMICA FEDERAL": "104",
  "LECCA CFI S.A.": "105",
  "BCO BOCOM BBM S.A.": "107",
  "PORTOCRED S.A.": "108",
  "OLIVEIRA TRUST DTVM S.A.": "111",
  "MAGLIANO S.A. CCVM": "113",
  "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO": "114",
  "ADVANCED CC LTDA": "117",
  "BCO WESTERN UNION": "119",
  "BCO RODOBENS S.A.": "120",
  "BCO AGIBANK S.A.": "121",
  "BCO BRADESCO BERJ S.A.": "122",
  "BCO WOORI BANK DO BRASIL S.A.": "124",
  "BANCO GENIAL": "125",
  "BR PARTNERS BI": "126",
  "CODEPE CVC S.A.": "127",
  "MS BANK S.A. BCO DE CÂMBIO": "128",
  "UBS BRASIL BI S.A.": "129",
  "CARUANA SCFI": "130",
  "TULLETT PREBON BRASIL CVC LTDA": "131",
  "ICBC DO BRASIL BM S.A.": "132",
  "CRESOL CONFEDERAÇÃO": "133",
  "BGC LIQUIDEZ DTVM LTDA": "134",
  "CONF NAC COOP CENTRAIS UNICRED": "136",
  "GET MONEY CC LTDA": "138",
  "INTESA SANPAOLO BRASIL S.A. BM": "139",
  EASYNVEST: "140",
  "BROKER BRASIL CC LTDA.": "142",
  "TREVISO CC S.A.": "143",
  "BEXS BCO DE CAMBIO S.A.": "144",
  "LEVYCAM CCV LTDA": "145",
  "GUITTA CC LTDA": "146",
  "FACTA S.A. CFI": "149",
  "ICAP DO BRASIL CTVM LTDA.": "157",
  "CASA CREDITO S.A. SCM": "159",
  "COMMERZBANK BRASIL S.A.": "163",
  "BRL TRUST DTVM SA": "173",
  "PEFISA S.A.": "174",
  GUIDE: "177",
  "CM CAPITAL MARKETS CCTVM LTDA": "180",
  "SOCRED SA": "183",
  "BCO ITAÚ BBA S.A.": "184",
  "ATIVA S.A. INVESTIMENTOS CCTVM": "188",
  "HS FINANCEIRA": "189",
  SERVICOOP: "190",
  "NOVA FUTURA CTVM LTDA.": "191",
  "PARMETAL DTVM LTDA": "194",
  "FAIR CC S.A.": "196",
  "STONE PAGAMENTOS S.A.": "197",
  "BANCO BTG PACTUAL S.A.": "208",
  "BANCO ORIGINAL": "212",
  "BCO ARBI S.A.": "213",
  "BANCO JOHN DEERE S.A.": "217",
  "BCO BS2 S.A.": "218",
  "BCO CRÉDIT AGRICOLE BR S.A.": "222",
  "BCO FIBRA S.A.": "224",
  "BANCO CIFRA": "233",
  "BCO BRADESCO S.A.": "237",
  "BCO CLASSICO S.A.": "241",
  "BCO MÁXIMA S.A.": "243",
  "BCO ABC BRASIL S.A.": "246",
  "BANCO INVESTCRED UNIBANCO S.A.": "249",
  BCV: "250",
  "BEXS CC S.A.": "253",
  "PARANA BCO S.A.": "254",
  "MONEYCORP BCO DE CÂMBIO S.A.": "259",
  "NU PAGAMENTOS S.A.": "260",
  "BCO FATOR S.A.": "265",
  "BCO CEDULA S.A.": "266",
  "BARI CIA HIPOTECÁRIA": "268",
  "BCO HSBC S.A.": "269",
  "SAGITUR CC LTDA": "270",
  "IB CCTVM S.A.": "271",
  "AGK CC S.A.": "272",
  "CCR DE SÃO MIGUEL DO OESTE": "273",
  "MONEY PLUS SCMEPP LTDA": "274",
  "BCO SENFF S.A.": "276",
  "GENIAL INVESTIMENTOS CVM S.A.": "278",
  "CCR DE PRIMAVERA DO LESTE": "279",
  "WILL FINANCEIRA S.A.CFI": "280",
  "CCR COOPAVEL": "281",
  "RB INVESTIMENTOS DTVM LTDA.": "283",
  "FRENTE CC LTDA.": "285",
  "CCR DE OURO": "286",
  "CAROL DTVM LTDA.": "288",
  "DECYSEO CC LTDA.": "289",
  "PAGSEGURO S.A.": "290",
  "BS2 DTVM S.A.": "292",
  "LASTRO RDV DTVM LTDA": "293",
  "VISION S.A. CC": "296",
  "VIPS CC LTDA.": "298",
  "BCO SOROCRED S.A.": "299",
  "BCO LA NACION ARGENTINA": "300",
  "BPP IP S.A.": "301",
  "PORTOPAR DTVM LTDA": "306",
  "TERRA INVESTIMENTOS DTVM": "307",
  "CAMBIONET CC LTDA": "309",
  "VORTX DTVM LTDA.": "310",
  "DOURADA CORRETORA": "311",
  "HSCM SCMEPP LTDA.": "312",
  "AMAZÔNIA CC LTDA.": "313",
  "PI DTVM S.A.": "315",
  "BCO BMG S.A.": "318",
  "OM DTVM LTDA": "319",
  "BCO CCB BRASIL S.A.": "320",
  "CREFAZ SCMEPP LTDA": "321",
  "CCR DE ABELARDO LUZ": "322",
  "MERCADO PAGO": "323",
  "CARTOS SCD S.A.": "324",
  "ÓRAMA DTVM S.A.": "325",
  PARATI: "326",
  "CECM FABRIC CALÇADOS SAPIRANGA": "328",
  "QI SCD S.A.": "329",
  "BANCO BARI S.A.": "330",
  "FRAM CAPITAL DTVM S.A.": "331",
  "ACESSO SOLUCOES PAGAMENTO SA": "332",
  "BANCO DIGIO": "335",
  "BCO C6 S.A.": "336",
  "SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.": "340",
  "ITAÚ UNIBANCO S.A.": "341",
  "CREDITAS SCD": "342",
  "FFA SCMEPP LTDA.": "343",
  "BCO XP S.A.": "348",
  "AL5 S.A. CFI": "349",
  "CREHNOR LARANJEIRAS": "350",
  "TORO CTVM LTDA": "352",
  "NECTON INVESTIMENTOS S.A CVM": "354",
  "ÓTIMO SCD S.A.": "355",
  "MIDWAY S.A.": "358",
  "ZEMA CFI S/A": "359",
  "TRINUS CAPITAL DTVM": "360",
  "CIELO S.A.": "362",
  "SINGULARE CTVM S.A.": "363",
  GERENCIANET: "364",
  SIMPAUL: "365",
  "BCO SOCIETE GENERALE BRASIL": "366",
  "VITREO DTVM S.A.": "367",
  "BCO CSF S.A.": "368",
  "BCO MIZUHO S.A.": "370",
  "WARREN CVMC LTDA": "371",
  "UP.P SEP S.A.": "373",
  "REALIZE CFI S.A.": "374",
  "BCO J.P. MORGAN S.A.": "376",
  "BMS SCD S.A.": "377",
  "BBC LEASING": "378",
  "CECM COOPERFORTE": "379",
  PICPAY: "380",
  "BCO MERCEDES": "381",
  "FIDUCIA SCMEPP LTDA": "382",
  JUNO: "383",
  "GLOBAL SCM LTDA": "384",
  "NU FINANCEIRA S.A. CFI": "386",
  "BCO TOYOTA DO BRASIL S.A.": "387",
  "BCO MERCANTIL DO BRASIL S.A.": "389",
  "BCO GM S.A.": "390",
  "CCR DE IBIAM": "391",
  "BCO VOLKSWAGEN S.A": "393",
  "BCO BRADESCO FINANC. S.A.": "394",
  "F D GOLD DTVM LTDA": "395",
  "HUB PAGAMENTOS": "396",
  "LISTO SCD S.A.": "397",
  "IDEAL CTVM S.A.": "398",
  "KIRTON BANK": "399",
  "CC POUP SER FIN CO": "400",
  "IUGU SERVICOS NA INTERNET S/A": "401",
  "COBUCCIO SCD S.A.": "402",
  "CORA SCD S.A.": "403",
  "SUMUP SCD S.A.": "404",
  "ACCREDITO SCD S.A.": "406",
  "ÍNDIGO INVESTIMENTOS DTVM LTDA.": "407",
  "BONUSPAGO SCD S.A.": "408",
  "PLANNER SCM S.A.": "410",
  "VIA CERTA FINANCIADORA S.A.": "411",
  "BCO CAPITAL S.A.": "412",
  "WORK SCD S.A.": "414",
  "LAMARA SCD S.A.": "416",
  "NUMBRS SCD S.A.": "419",
  "BCO SAFRA S.A.": "422",
  "CRED-UFES": "427",
  "CRED-SYSTEM SCD S.A.": "428",
  "PLANNER TRUSTEE DTVM LTDA": "438",
  "BCO MUFG BRASIL S.A.": "456",
  "BCO SUMITOMO MITSUI BRASIL S.A.": "464",
  "BCO CAIXA GERAL BRASIL S.A.": "473",
  "CITIBANK N.A.": "477",
  "BCO ITAUBANK S.A.": "479",
  "DEUTSCHE BANK S.A.BCO ALEMAO": "487",
  "JPMORGAN CHASE BANK": "488",
  "ING BANK N.V.": "492",
  "BCO LA PROVINCIA B AIRES BCE": "495",
  "BCO CREDIT SUISSE S.A.": "505",
  "SENSO CCVM S.A.": "545",
  "BCO LUSO BRASILEIRO S.A.": "600",
  "BCO INDUSTRIAL DO BRASIL S.A.": "604",
  "BCO VR S.A.": "610",
  "BCO PAULISTA S.A.": "611",
  "BCO GUANABARA S.A.": "612",
  "OMNI BANCO S.A.": "613",
  "BANCO PAN": "623",
  "BCO C6 CONSIG": "626",
  SMARTBANK: "630",
  "BCO RENDIMENTO S.A.": "633",
  "BCO TRIANGULO S.A.": "634",
  "BCO SOFISA S.A.": "637",
  "BCO PINE S.A.": "643",
  "ITAÚ UNIBANCO HOLDING S.A.": "652",
  "BCO INDUSVAL S.A.": "653",
  "BCO DIGIMAIS S.A.": "654",
  "BCO VOTORANTIM S.A.": "655",
  "BCO DAYCOVAL S.A": "707",
  "BCO OURINVEST S.A.": "712",
  "BCO RNX S.A.": "720",
  "BCO CETELEM S.A.": "739",
  "BCO RIBEIRAO PRETO S.A.": "741",
  "BANCO SEMEAR": "743",
  "BCO CITIBANK S.A.": "745",
  "BCO MODAL S.A.": "746",
  "BCO RABOBANK INTL BRASIL S.A.": "747",
  "BCO COOPERATIVO SICREDI S.A.": "748",
  "SCOTIABANK BRASIL": "751",
  "BCO BNP PARIBAS BRASIL S A": "752",
  "NOVO BCO CONTINENTAL S.A.": "753",
  "BANCO SISTEMA": "754",
  "BOFA MERRILL LYNCH BM S.A.": "755",
  "BANCO SICOOB S.A.": "756",
  "BCO KEB HANA DO BRASIL S.A.": "757",
};

function RefundConfirmation(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [form, setForm] = useState({
    name: { value: "", valid: true, max: 80, checked: false },
    idType: "CPF",
    taxId: { value: "", valid: true, checked: false },
    bank_list: { value: "", valid: true, checked: false },
    agent: { value: "", valid: true, max: 6, checked: false },
    account_type: { value: "", valid: true, checked: false },
    bank_number: { value: "", valid: true, checked: false },
    branch_number: { value: "", valid: true, checked: false },
  });
  const [isLoading, setIsLoading] = useState(false);

  const checkForm = (formKey) => {
    if (form[formKey]) {
      const validity = !!form[formKey].value;
      setForm((prev) => ({ ...prev, [formKey]: { ...prev[formKey], valid: validity, checked: true } }));
      return validity;
    }
    return false;
  };

  const submitForm = () => {
    const validityFunctions = [
      checkName({ form: form, setForm: setForm }),
      checkTaxId({ form: form, setForm: setForm, region: "BRA" }),
      checkForm("bank_list"),
      checkForm("agent"),
      checkForm("account_type"),
      checkForm("bank_number"),
      checkForm("branch_number"),
    ];

    const validity = validityFunctions.every((item) => item === true);

    if (validity) {
      const reqData = {
        id: form.taxId.value.replace(/[-/.]/g, ""),
        id_type: form.idType,
        name: form.name.value,
        bank_id: bankList[form.bank_list.value],
        bank_name: form.bank_list.value,
        agency: form.agent.value,
        type: form.account_type.value,
        number: form.bank_number.value.replace(/[^\d]/g, "") + "-" + form.branch_number.value,
      };
      setIsLoading(true);
      req
        .post(`/api/trade/refund-confirmation?trade_no=${props.prepayId}`, reqData)
        .then((res) => {
          if (res.data.code === "10000") {
            props.setTradeStatus({ id: "process", text: "refund_processing", notice: "processing_notice" });
          } else {
            props.setTradeStatus({ id: "fail" });
          }
          history.replace(`/refund-result?trade_no=${props.prepayId}`);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const ProgressButton = ({ fullWidth }) => {
    return (
      <div className="refundConfirmation__ProgressButton">
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={submitForm}
          style={{
            width: fullWidth ? "100%" : "auto",
          }}
        >
          {t("confirm")}
        </Button>
        {isLoading && (
          <CircularProgress
            size={24}
            className="refundConfirmation__progressButton__loading"
            style={{ color: isLoading ? "#01a27e" : "#fff" }}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="refundConfirmation">
        <div className="refundConfirmationMobile__payDetails refundConfirmationMobile">
          <TitleWithDetails show={true} />
          <hr />
          <div className="refundConfirmationMobile__payDetails__container">
            <p className="refundConfirmationMobile__payDetails__text">{t("total")}</p>
            <p className="refundConfirmationMobile__payDetails__value">
              <FormatMoney type="total" />
            </p>
          </div>
        </div>
        <div className="refundConfirmation__forms">
          <p className="refundConfirmation__forms__title">Informação do beneficiário</p>
          <div className="refundConfirmation--row">
            <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} infoPromp={false} />
            <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
          </div>
          <div className="refundConfirmation--row">
            <Autocomplete
              value={form.bank_list.value}
              onChange={(event, newValue) => {
                setForm((prev) => {
                  return {
                    ...prev,
                    bank_list: { ...prev.bank_list, value: newValue || "" },
                  };
                });
              }}
              options={Object.keys(bankList)}
              // getOptionLabel={(option) => option?.bank_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("bank_list")}
                  variant="outlined"
                  error={!form.bank_list.valid}
                  helperText={!form.bank_list.valid && t("bank_select")}
                  placeholder="Escolha..."
                  size="small"
                  inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
                />
              )}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onBlur={() => {
                checkForm("bank_list");
              }}
            />
            <TextField
              onClick={(event) => {
                event.stopPropagation();
              }}
              inputProps={{ autocomplete: "new-password" }}
              size="small"
              error={!form.agent.valid}
              helperText={!form.agent.valid && "Agência inválido"}
              label={"Agência"}
              variant="outlined"
              value={form.agent.value}
              onChange={(event) => {
                var format = /[^0-9a-zA-Z]+/g;
                var value = event.target.value;
                value = value.replace(format, "");
                if (value?.length > form.agent.max) {
                  value = value.slice(0, form.agent.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    agent: { ...prev.agent, value: value },
                  };
                });
              }}
              onBlur={() => {
                checkForm("agent");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={Correct}
                      alt=""
                      style={{
                        display: form.agent.valid && form.agent.checked ? "block" : "none",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="refundConfirmation--row">
            <FormControl variant="outlined" size="small" error={!form.account_type.valid}>
              <InputLabel id="demo-simple-select-label">{"Tipo de conta"}</InputLabel>
              <Select
                // value={form.account_type.value}
                label={"Tipo de conta"}
                onChange={(event) => {
                  // console.log(">>>>>>>", event.target.value);
                  setForm((prev) => {
                    return {
                      ...prev,
                      account_type: { ...prev.account_type, value: event.target.value },
                    };
                  });
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onBlur={() => {
                  checkForm("account_type");
                }}
              >
                <MenuItem value="1">Conta corrente</MenuItem>
                <MenuItem value="2">Conta poupança</MenuItem>
              </Select>
              {!form.account_type.valid && <FormHelperText>{"Selecione um tipo de conta"}</FormHelperText>}
            </FormControl>
            <div className="refundConfirmation__forms__bankNumber">
              <TextField
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password" }}
                size="small"
                error={!form.bank_number.valid}
                helperText={!form.bank_number.valid && "Número da conta bancária inválido"}
                label={"Número da conta bancária"}
                variant="outlined"
                value={form.bank_number.value}
                onChange={(event) => {
                  let value = event.target.value;
                  const format = /^(\d{1,4})(\d{0,4})(\d{0,4})(\d{0,4})/;
                  const match = value?.replace(/[^\d]/g, "").match(format);

                  if (match) {
                    value =
                      match[1] +
                      (match[2] ? " " + match[2] : "") +
                      (match[2] && match[3] ? " " + match[3] : "") +
                      (match[2] && match[3] && match[4] ? " " + match[4] : "");
                  }

                  setForm((prev) => {
                    return {
                      ...prev,
                      bank_number: { ...prev.bank_number, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkForm("bank_number");
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.bank_number.valid && form.bank_number.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <span>-</span>
              <TextField
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password" }}
                size="small"
                error={!form.branch_number.valid}
                helperText={!form.branch_number.valid && "Número da filial inválido"}
                label={"Número da filial"}
                variant="outlined"
                value={form.branch_number.value}
                onChange={(event) => {
                  let value = event.target.value;
                  const format = /^(\d{1,2})/;
                  const match = value?.replace(/[^\d]/g, "").match(format);
                  if (match) {
                    value = match[1];
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      branch_number: { ...prev.branch_number, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkForm("branch_number");
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.branch_number.valid && form.branch_number.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="refundConfirmationDesktop refundConfirmation__confirm">
            <ProgressButton />
          </div>
        </div>
        <div className="refundConfirmation__right refundConfirmationDesktop">
          <OrderDetails showDetails={true} hideStatus={true} backToShop={true} />
        </div>
        <div className="refundConfirmationMobile__footer refundConfirmationMobile">
          <ProgressButton fullWidth />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setTradeStatus: (item) => dispatch(setTradeStatus(item)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundConfirmation);
