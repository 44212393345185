import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import "./StreetAndNumber.scss";
import { checkStreet, checkNo } from "../../utils/validations";

function Name({ form, setForm, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();

  return (
    <div className="streetAndNumber">
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ autocomplete: "new-password" }}
        size="small"
        error={!form.street.valid}
        helperText={!form.street.valid && t("invalid_street")}
        className="homeMobile__filling__content__input"
        label={t("street")}
        variant="outlined"
        value={form.street.value}
        onChange={(event) => {
          var value = event.target.value;
          if (value?.length > form.street.max) {
            value = value.slice(0, form.street.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              street: { ...prev.street, value: value },
            };
          });
        }}
        onBlur={() => {
          checkStreet({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.street.valid && form.street.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ autocomplete: "new-password" }}
        size="small"
        error={!form.no.valid}
        helperText={!form.no.valid && t("invalid_number")}
        className="homeMobile__filling__content__input"
        label={t("number")}
        variant="outlined"
        value={form.no.value}
        onChange={(event) => {
          // eslint-disable-next-line no-useless-escape
          var format = /[^\d-\./]+/g;
          var value = event.target.value;
          value = value.replace(format, "");
          if (value?.length > form.no.max) {
            value = value.slice(0, form.no.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              no: { ...prev.no, value: value },
            };
          });
        }}
        onBlur={() => {
          checkNo({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.no.valid && form.no.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default Name;
