import Alianza from "../../img/latam-agents/alianza-fiduciaria.png";
import Ban100 from "../../img/latam-agents/ban100.svg";
import BANCAMIA from "../../img/latam-agents/bancamia.svg";
import AGRARIO from "../../img/latam-agents/banco-agrario.svg";
import VILLAS from "../../img/latam-agents/banco-av-villas.svg";
import BBVA from "../../img/latam-agents/bbva.svg";
import CAJASOCIAL from "../../img/latam-agents/banco-caja-social.png";
import COOPCENTRAL from "../../img/latam-agents/banco-coopcentral.svg";
import DAVIVIENDA from "../../img/latam-agents/banco-davivienda.svg";
import BOGOTA from "../../img/latam-agents/banco-bogota.svg";
import OCCIDENTE from "../../img/latam-agents/banco-occidente.svg";
import FALABELLA from "../../img/latam-agents/banco-falabella.svg";
import FINANDINA from "../../img/latam-agents/banco-finandina.svg";
import SUDAMERIS from "../../img/latam-agents/banco-sudameris.svg";
import ITAU from "../../img/latam-agents/banco-itau.svg";
import MORGAN from "../../img/latam-agents/jp-morgan.svg";
import MUNDO from "../../img/latam-agents/mudo-mujer.jpeg";
import PICHINCHA from "../../img/latam-agents/banco-pichincha.svg";
import POPULAR from "../../img/latam-agents/banco-popular.svg";
import SANTANDER from "../../img/latam-agents/banco-santander.svg";
import SERFINANZA from "../../img/latam-agents/banco-serfinanza.svg";
import UNION from "../../img/latam-agents/banco-antes-giros.png";
import BANCOLOMBIA from "../../img/latam-agents/bancolombia.png";
import BANCOOMEVA from "../../img/latam-agents/bancoomeva.svg";
import COOPERATIVA from "../../img/latam-agents/cfa-cooperativa.png";
import CITIBANK from "../../img/latam-agents/citibank.svg";
import COLTEFINANCIERA from "../../img/latam-agents/contefinanciera.png";
import CONFIAR from "../../img/latam-agents/confiar.svg";
import COOFINEP from "../../img/latam-agents/coofinep.png";
import COTRAFA from "../../img/latam-agents/contrafa.png";
import CREZCAMOS from "../../img/latam-agents/crezcamos.png";
import DALE from "../../img/latam-agents/dale.png";
import DAVIPLATA from "../../img/latam-agents/daviplata.svg";
import IRIS from "../../img/latam-agents/iris.svg";
import LULO from "../../img/latam-agents/lulo-bank.svg";
import MOVII from "../../img/latam-agents/movii.svg";
import NEQUI from "../../img/latam-agents/nequi.svg";
import RAPPIPAY from "../../img/latam-agents/rappi.svg";
import SCOTIABANK from "../../img/latam-agents/scotiabank-colpatria.png";
import UALÁ from "../../img/latam-agents/uala.png";

export const achImegeConfig = {
  "ALIANZA FIDUCIARIA": Alianza,
  BAN100: Ban100,
  "BANCAMIA S.A.": BANCAMIA,
  "BANCO AGRARIO": AGRARIO,
  "BANCO AV VILLAS": VILLAS,
  "BANCO BBVA COLOMBIA S.A.": BBVA,
  "BANCO CAJA SOCIAL": CAJASOCIAL,
  "BANCO COOPERATIVO COOPCENTRAL": COOPCENTRAL,
  "BANCO DAVIVIENDA": DAVIVIENDA,
  "BANCO DE BOGOTA": BOGOTA,
  "BANCO DE OCCIDENTE": OCCIDENTE,
  "BANCO FALABELLA ": FALABELLA,
  "BANCO FINANDINA S.A. BIC": FINANDINA,
  "BANCO GNB SUDAMERIS": SUDAMERIS,
  "BANCO ITAU": ITAU,
  "BANCO J.P. MORGAN COLOMBIA S.A.": MORGAN,
  "BANCO MUNDO MUJER S.A.": MUNDO,
  "BANCO PICHINCHA S.A.": PICHINCHA,
  "BANCO POPULAR": POPULAR,
  "BANCO SANTANDER COLOMBIA": SANTANDER,
  "BANCO SERFINANZA": SERFINANZA,
  "BANCO UNION antes GIROS": UNION,
  BANCOLOMBIA: BANCOLOMBIA,
  "BANCOOMEVA S.A.": BANCOOMEVA,
  "CFA COOPERATIVA FINANCIERA": COOPERATIVA,
  "CITIBANK ": CITIBANK,
  COLTEFINANCIERA: COLTEFINANCIERA,
  "CONFIAR COOPERATIVA FINANCIERA": CONFIAR,
  "COOFINEP COOPERATIVA FINANCIERA": COOFINEP,
  COTRAFA: COTRAFA,
  "CREZCAMOS S.A. COMPAÑÍA DE FINANCIAMIENTO": CREZCAMOS,
  DALE: DALE,
  DAVIPLATA: DAVIPLATA,
  IRIS: IRIS,
  "LULO BANK": LULO,
  "MOVII S.A.": MOVII,
  NEQUI: NEQUI,
  RAPPIPAY: RAPPIPAY,
  "SCOTIABANK COLPATRIA": SCOTIABANK,
  UALÁ: UALÁ,
};
