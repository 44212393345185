import React from "react";
import "./CheckoutOxxo.scss";
import ShareOnSocialMedia from "./ShareOnSocialMedia";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import BarcodeAndPDF from "./BarcodeAndPDF";
import SaveButton from "./SaveButton";
import CheckoutTitle from "./CheckoutTitle";

function CheckoutOxxo({ image, pdf, mobile = false, ...props }) {
  const { t } = useTranslation();
  return (
    <div className="checkoutOxxo">
      <CheckoutTitle />
      <div className="checkoutOxxo__instContainer">
        <p>{t("checkout_inst3")}</p>
        <p>
          <span>{t("checkout_inst4")}</span>
          <span className="checkoutOxxo__instContainer__number">{props.payData?.trade_no}</span>
        </p>
      </div>
      {(props.payChannel === "Banwire" || props.payChannel === "Conekta" || props.payChannel === "Localpayment") && (
        <BarcodeAndPDF mobile={mobile} barcode={props.payData?.barcode} pdf={pdf} image={image} />
      )}
      {props.payChannel === "CardPay" && (
        <>
          <div className="checkoutOxxo__saveAndMedia">
            <SaveButton link={pdf} />
            <ShareOnSocialMedia text={pdf} />
          </div>
          <div className="checkoutOxxo__fileContainer">
            {mobile ? <img src={image} alt="" /> : <embed src={pdf} type="application/pdf" width="90%" height="1050" />}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payChannel: state.payChannel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOxxo);
