import React from "react";
import "./Failed.scss";
import Fail from "../img/fail.png";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

function Failed(props) {
  const { t } = useTranslation();
  return (
    <div className="failed">
      <img className="failed__result" src={Fail} alt="" />
      <p className="failed__notice1" style={{ color: "#ed5d6b" }}>
        {props.tradeStatus?.text ? t(props.tradeStatus?.text) : t("failed")}
      </p>
      {props.method === "PSE" &&
      props.payChannel === "ACH" &&
      (props.payData?.refuse_info || props.payData?.data?.refuse_info) ? (
        <p className="failed__notice2">
          {t([
            `checkout.pse.error.${props.payData?.data?.refuse_info || props.payData?.refuse_info}`,
            "checkout.pse.error.default",
          ])}
        </p>
      ) : (
        <p className="failed__notice2">
          {props.tradeStatus?.notice ? t(props.tradeStatus?.notice) : t(props.payData?.code)}
        </p>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
    payChannel: state.payChannel,
    tradeStatus: state.tradeStatus,
    method: state.method,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Failed);
