import React, { useState, useEffect } from "react";
import "./Checkout.scss";
import { connect } from "react-redux";
import req from "../utils/axios";
import TitleWithDetails from "./TitleWithDetails";
import { useTranslation } from "react-i18next";
import CheckoutSafetyPay from "./CheckoutSafetyPay";
import OrderDetails from "./OrderDetails";
import CheckoutSpei from "./CheckoutSpei";
import CheckoutPaynet from "./CheckoutPaynet";
import CheckoutOxxo from "./CheckoutOxxo";
import CheckoutWallet from "./CheckoutWallet";
import CheckoutBoleto from "./CheckoutBoleto";
import CheckoutPix from "./CheckoutPix";
import CheckoutLottery from "./CheckoutLottery";
import CheckoutDepositExpress from "./CheckoutDepositExpress";
import CheckoutCodi from "./CheckoutCodi";
import CheckoutPayCash from "./CheckoutPayCash";
import CheckoutKlapCash from "./CheckoutKlapCash";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import { FormatMoney } from "../utils/formats";
import { loadAddScript } from "../utils/advert.js";
import CheckoutPayvalida from "./CheckoutPayvalida";
import CheckoutBarcode from "./CheckoutBarcode";
import CheckoutBankTransferLocalpayment from "./CheckoutBankTransferLocalpayment";
import CheckoutMonnetBankCash from "./CheckoutMonnetBankCash";
import CheckoutQr from "./CheckoutQr";
import CheckoutBankCashCommon from "./CheckoutBankCashCommon";
import CheckoutCrypto from "./CheckoutCrypto";
import CheckoutPSE from "./CheckoutPSE";

function Checkout(props) {
  const { t } = useTranslation();
  const [image, setImage] = useState("");
  const [pdf, setPdf] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const hideFooter =
    ((props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay") ||
    props.method === "DepositExpress";
  const isSafetyPay = (props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay";
  const notify = (text) => {
    toast.error(text, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleError = (err, notice) => {
    console.error(err);
    setIsLoading(false);
    notify(notice);
  };
  useEffect(() => {
    loadAddScript(props.tradeInfo?.app_info?.region);
    if (
      props.method === "OXXO" ||
      (props.method === "Cash" && props.payChannel === "OpenPay") ||
      (props.method === "SPEI" && props.payChannel === "OpenPay") ||
      props.method === "Boleto" ||
      (props.method === "OXXOPay" && props.payChannel === "Conekta") ||
      (props.method === "OXXO" && props.payChannel === "Localpayment")
    ) {
      let endpoint = "pdf";
      if (props.method === "OXXO" && props.payChannel === "CardPay") {
        endpoint = "oxxo-pdf";
      } else if (props.method === "Boleto" && props.payChannel === "BS2") {
        endpoint = "boleto-pdf";
      }

      let endPointPng = "png";
      if (props.method === "OXXO" && props.payChannel === "CardPay") {
        endPointPng = "oxxo-png";
      } else if (props.method === "Boleto" && props.payChannel === "BS2") {
        endPointPng = "boleto-png";
      }

      req
        .post(`api/trade/${endpoint}?trade_no=${props.prepayId}`)
        .then((res) => {
          if (res.data.code === "10000") {
            res.data?.data && setPdf(res.data.data);
          } else {
            //notify(t(`err_${res.data.code}`));
          }
        })
        .then(() => {
          req.post(`api/trade/${endPointPng}?trade_no=${props.prepayId}`).then((res) => {
            if (res.data.code === "10000") {
              res.data?.data && setImage(res.data.data);
            } else {
              //notify(t(`err_${res.data.code}`));
            }
          });
        });
    }
  }, []);

  return (
    <>
      <div className="puff__loader" style={{ display: isLoading ? "block" : "none" }}>
        <div className="loader">
          <PuffLoader color="#01b88f" />
        </div>
      </div>
      <div className="checkout">
        <div className="checkout__container">
          <div className="checkout__left">
            {(props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay" && (
              <CheckoutSafetyPay />
            )}

            {props.method === "SPEI" && <CheckoutSpei pdf={pdf} image={image} />}
            {props.method === "Cash" && props.payChannel === "OpenPay" && <CheckoutPaynet pdf={pdf} image={image} />}
            {((props.method === "OXXO" &&
              (props.payChannel === "Banwire" ||
                props.payChannel === "CardPay" ||
                props.payChannel === "Bamboo" ||
                props.payChannel === "Localpayment")) ||
              (props.method === "OXXOPay" && props.payChannel === "Conekta")) && (
              <CheckoutOxxo pdf={pdf} image={image} />
            )}
            {props.method === "Wallet" &&
              (props.payChannel === "Mach" ||
                props.payChannel === "Vita" ||
                props.payChannel === "AME" ||
                props.payChannel === "Alipay" ||
                props.payChannel === "GCash" ||
                props.payChannel === "KakaoPay" ||
                props.payChannel === "TNG" ||
                props.payChannel === "TrueMoney" ||
                props.payChannel === "TPaga") && <CheckoutWallet />}

            {(props.method === "Boleto" || props.method === "BoletoRapido") && <CheckoutBoleto pdf={pdf} />}

            {props.method === "PIX" && <CheckoutPix />}
            {props.method === "Lottery" && <CheckoutLottery />}

            {props.method === "DepositExpress" && (
              <CheckoutDepositExpress setIsLoading={setIsLoading} handleError={handleError} />
            )}
            {props.method === "CoDi" && props.payChannel === "STP" && <CheckoutCodi />}
            {props.method === "Cash" && props.payChannel === "PayCash" && <CheckoutBankCashCommon />}
            {(props.method === "KLAP" || props.method === "Cash") && props.payChannel === "Multicaja" && (
              <CheckoutKlapCash />
            )}
            {((props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
              (props.method === "SuRed" && props.payChannel === "PayValida") ||
              (props.method === "GANA" && props.payChannel === "PayValida") ||
              (props.method === "Bancolombia" && props.payChannel === "PayValida") ||
              (props.method === "BNCR" && props.payChannel === "PayValida") ||
              (props.method === "BNCR" && props.payChannel === "PayCash")) && <CheckoutPayvalida />}

            {((props.method === "PagoFacil" && props.payChannel === "Localpayment") ||
              (props.method === "Rapipago" && props.payChannel === "Localpayment") ||
              (props.method === "Cash" && props.payChannel === "Localpayment")) && <CheckoutBarcode />}
            {props.method === "BankTransfer" && props.payChannel === "Localpayment" && (
              <CheckoutBankTransferLocalpayment />
            )}
            {(props.method === "BankTransfer" || props.method === "Cash") && props.payChannel === "Monnet" && (
              <CheckoutMonnetBankCash />
            )}
            {props.method === "Crypto" && props.payChannel === "TripleA" && <CheckoutCrypto />}

            {props.method === "Wallet" && (props.payChannel === "Tupay" || props.payChannel === "ShopeePay") && (
              <CheckoutQr />
            )}
            {props.method === "PSE" && props.payChannel === "ACH" && <CheckoutPSE />}
          </div>
          <div className="checkout__right">
            <OrderDetails showDetails={!isSafetyPay} showStatus={isSafetyPay} backToShop={true} />
          </div>
        </div>
      </div>

      {/* ~~~~~~~~~~~~~~~~~~~~~ */}
      {/*   Mobile starts here  */}
      {/* ~~~~~~~~~~~~~~~~~~~~~ */}
      <div className="checkoutMobile" style={{ paddingBottom: isSafetyPay ? "300px" : "90px" }}>
        <div className="checkoutMobile__top">
          <TitleWithDetails />
          <hr />
          {props?.payData?.amount &&
            props?.paymentInfo?.pay_amount &&
            props?.payData?.amount != props?.paymentInfo?.pay_amount && (
              <div className="checkout--floatRight">
                <p>
                  <span className="checkoutMobile__top__totalText" style={{ fontSize: "13px" }}>
                    {t("bank_fee")}
                  </span>
                  <span className="checkoutMobile__top__totalValue" style={{ fontSize: "14px" }}>
                    {props?.paymentInfo?.pay_currency || props?.tradeInfo?.pay_currency}{" "}
                    {(
                      props?.payData?.amount - (props?.paymentInfo?.order_amount || props?.tradeInfo?.order_amount)
                    )?.toFixed(2)}
                  </span>
                </p>
              </div>
            )}
          <div className="checkout--floatRight">
            <p>
              <span className="checkoutMobile__top__totalText">{t("total")}</span>
              <span className="checkoutMobile__top__totalValue">
                <FormatMoney type="total" />
              </span>
            </p>
          </div>
        </div>
        {(props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay" && (
          <CheckoutSafetyPay />
        )}
        {props.method === "SPEI" && <CheckoutSpei pdf={pdf} image={image} mobile />}
        {props.method === "Cash" && props.payChannel === "OpenPay" && <CheckoutPaynet pdf={pdf} image={image} mobile />}
        {((props.method === "OXXO" &&
          (props.payChannel === "Banwire" ||
            props.payChannel === "CardPay" ||
            props.payChannel === "Bamboo" ||
            props.payChannel === "Localpayment")) ||
          (props.method === "OXXOPay" && props.payChannel === "Conekta")) && (
          <CheckoutOxxo pdf={pdf} image={image} mobile />
        )}
        {props.method === "Wallet" &&
          (props.payChannel === "Mach" ||
            props.payChannel === "Vita" ||
            props.payChannel === "AME" ||
            props.payChannel === "Alipay" ||
            props.payChannel === "GCash" ||
            props.payChannel === "KakaoPay" ||
            props.payChannel === "TNG" ||
            props.payChannel === "TrueMoney" ||
            props.payChannel === "TPaga") && <CheckoutWallet />}

        {(props.method === "Boleto" || props.method === "BoletoRapido") && (
          <CheckoutBoleto pdf={pdf} image={image} mobile />
        )}

        {props.method === "PIX" && <CheckoutPix />}

        {props.method === "Lottery" && <CheckoutLottery />}

        {props.method === "DepositExpress" && (
          <CheckoutDepositExpress setIsLoading={setIsLoading} handleError={handleError} />
        )}
        {props.method === "CoDi" && props.payChannel === "STP" && <CheckoutCodi />}
        {props.method === "Cash" && props.payChannel === "PayCash" && <CheckoutBankCashCommon />}
        {(props.method === "KLAP" || props.method === "Cash") && props.payChannel === "Multicaja" && (
          <CheckoutKlapCash />
        )}
        {((props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
          (props.method === "SuRed" && props.payChannel === "PayValida") ||
          (props.method === "GANA" && props.payChannel === "PayValida") ||
          (props.method === "Bancolombia" && props.payChannel === "PayValida") ||
          (props.method === "BNCR" && props.payChannel === "PayValida") ||
          (props.method === "BNCR" && props.payChannel === "PayCash")) && <CheckoutPayvalida />}

        {((props.method === "PagoFacil" && props.payChannel === "Localpayment") ||
          (props.method === "Rapipago" && props.payChannel === "Localpayment") ||
          (props.method === "Cash" && props.payChannel === "Localpayment")) && <CheckoutBarcode />}
        {props.method === "BankTransfer" && props.payChannel === "Localpayment" && <CheckoutBankTransferLocalpayment />}
        {(props.method === "BankTransfer" || props.method === "Cash") && props.payChannel === "Monnet" && (
          <CheckoutMonnetBankCash />
        )}
        {props.method === "Crypto" && props.payChannel === "TripleA" && <CheckoutCrypto />}
        {props.method === "Wallet" && (props.payChannel === "Tupay" || props.payChannel === "ShopeePay") && (
          <CheckoutQr />
        )}
        {props.method === "PSE" && props.payChannel === "ACH" && <CheckoutPSE />}
        <div className="checkoutMobile__footer">
          {!hideFooter && props.returnUrl && (
            <button
              className="checkoutMobile__footer__returnButton"
              onClick={() => {
                window.open(props.returnUrl, "_parent");
              }}
            >
              {t("back_shop")}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    channel: state.channel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    prepayId: state.prepayId,
    returnUrl: state.returnUrl,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
