const ar = {
  translation: {
    en: "English",
    es: "Español",
    pt: "Português",
    ru: "Pусский",
    ar: "عربي",
    zh: "中文",
    "zh-TW": "繁體中文",
    jp: "日本語",
    ko: "한국어",
    tr: "Türkçe",
    bank_transfer: "التحويل البنكي",
    other_fees: "رسوم أخرى",
    kiosk: "أكشاك الدفع",
    warning: {
      crypto: {
        ETH: "أرسل ETH فقط على شبكة ETH (ERC20) إلى هذا العنوان.",
        USDC: "أرسل USDC فقط على شبكة ETH (ERC20) إلى هذا العنوان.",
        USDT: "أرسل USDT فقط على شبكة ETH (ERC20) إلى هذا العنوان.",
        USDT_TRC20: "أرسل USDT فقط على شبكة TRON (TRC20) إلى هذا العنوان.",
      },
    },
    fawry: {
      cash: {
        content:
          "استخدم الرقم المرجعي أدناه لإجراء الدفع في أي من متاجر البيع بالتجزئة التابعة لـ FawryPay. أو ادفع من خلال تطبيق FawryPay عن طريق إدخال الرقم المرجعي.",
      },
    },
    checkout: {
      kiosk: {
        title: {
          1: "يمكنك الدفع في أي من هذه الأماكن",
          2: "كيف تريد الدفع؟",
        },
        service: "الخدمة",
        code: "رمز الدفع",
        due_date: "تاريخ الاستحقاق",
        amount: "القيمة",
        how_to: {
          1: "{{reference}} اختر مدفوعات متنوعة-تأكيد-وتقديم الرقم المرجعي الخاص بك",
          2: "ادفع واستلم إيصالك",
        },
      },
    },
    "checkout_inst1.0": "عزيزي <0></0>، ادفع <1></1> لإتمام عملية الشراء",
    "checkout_inst1.2": "المستخدم",
    payment_method: " طريقة الدفع",
    filling_details: " تفاصيل التعبئة",
    trx_id: "معرّف المعاملة",
    time_remain: " الوقت المتبقي:",
    hide: " إخفاء",
    show: " إظهار",
    product_description: "وصف المنتج",
    order_value: " قيمة الطلب",
    fee_102: " رسوم بنكية",
    fee_101: "ضريبة",
    fee_100: " رسوم أخرى",
    discount: " خصم",
    total: " المجموع",
    confirm: " تأكيد",
    return: " العودة",
    privacy_policy_error: " يجب أن تقرأ وتقبل شروط الاستخدام وسياسة الخصوصية لإكمال عملية الشراء",
    privacy_policy1: " لقد قرأت ووافقت على",
    privacy_policy2: " شروط الاستخدام",
    privacy_policy3: " و",
    privacy_policy4: " سياسة الخصوصية",
    privacy_policy5: " Pagsmile من ",
    pci_notice: " PCI DSS جميع المعاملات مضمونة بأمان ومتوافقة مع بروتوكول",
    "payment.redirect.notice1": "ستتم إعادة توجيهك إلى صفحة التاجر خلال {{key}} ثانية",
    "payment.redirect.notice2": "انقر ",
    "payment.redirect.notice3": "هنا ",
    "payment.redirect.notice4": "للإلغاء",
    back_shop: " العودة إلى التسوق",
    invalid_name: " اسم غير صالح",
    cardholder_name: " الاسم الكامل لحامل البطاقة",
    exp_month: "شهر نهاية صلاحية",
    invalid_month: " اختر شهرًا",
    exp_year: "سنة نهاية صلاحية ",
    invalid_year: " اختر سنة",
    invalid_cvv: "غير صالح CVV",
    phone: " رقم الهاتف المحمول",
    invalid_phone: " رقم الهاتف غير صالح",
    email: " البريد الإلكتروني",
    invalid_email: " بريد إلكتروني غير صالح",
    address_billing: "عنوان إرسال الفواتير:",
    country: " البلد",
    invalid_country: " اختر البلد",
    state: "مقاطعة",
    invalid_state: " اختر مقاطعة ",
    city: "المدينة",
    invalid_city: " اختر المدينة",
    street: " شارع",
    invalid_street: " شارع غير صالح",
    number: "رقم",
    invalid_number: " رقم غير صالح",
    select_country: " الرجاء تحديد بلد أولاً",
    postal_code: " الرمز البريدي",
    invalid_postal_code: " رمز بريدي غير صالح",
    address_delivery: ": عنوان التسليم",
    address_same: " نفس عنوان إرسال الفواتير",
    details: " التفاصيل",
    card_no: "رقم البطاقة",
    invalid_card_no: "رقم بطاقة غير صالحة",
    expiry_month: "شهر انتهاء الصلاحية",
    invalid_month2: "شهر غير صالح",
    expiry_year: "سنة انتهاء الصلاحية",
    invalid_year2: "سنة غير صالحة",
    credit_debit_card: "بطاقة الائتمان / الخصم",
    okay: "!أوكي",
    network_err: "خطأ في الشبكة ، يرجى المحاولة مرة أخرى",
    fail_try_agin: "حدث خطأ ما ، يرجى المحاولة مرة أخرى لاحقًا",
    fail_change_method: "حدث خطأ ما. يُرجى استخدام طريقة دفع أخرى",
    fail_notice: "من الممكن أن تكون المعلومات خاطئة ، حاول مرة أخرى أو تواصل معنا",
    payment_fail: "فشل الدفع",
    failed: "فشل",
    success_result: "نجاح",
    success_notice: " Pagsmile  لقد دفعت الطلب بنجاح. الرجاء الرجوع إلى صفحة البائع! شكرًا لك على استخدام",
    cancelled: "Cancelled",
    order_timeout: " انتهى الحد الزمني لدفعتك ، يرجى الرجوع إلى صفحة البائع لتقديم طلب جديد",
    processing: "معالجة",
    processing_notice: "! Pagsmile المعاملة قيد المعالجة، وستكون سريعة. شكرًا لك على استخدام",
    success: "تم الدفع بنجاح",
    refund_verifying: "استرداد الأموال",
    refund_processing: "معالجة استرداد الأموال",
    risk_control: "التحكم في المخاطر",
    refunded: "استرداد",
    partial_refund: "تم رد الأموال جزئيًا",
    cash_payments: "التسديد نقذا",
    chargeback: " رد المبالغ المدفوعة",
    chargeback_reversed: "تم رد المبالغ المدفوعة",
    dispute: "نزاع",
    dispute_reversed: "تم النزاع",
    timeout: "انتهت مهلة المعاملة",
    payment_refuse: "دفعة مرفوضة",
    refund_reverse: "تم استرداد الأموال",
    refund_refuse: "تم رفض رد الأموال",
    pay_close: "مغلق",
    fail_sec: "تم إغلاق هذا الطلب. يرجى إرساله مرة أخرى من أجل تأمين معاملتك",
    crypto: "العملات الرقمية",
    20000: "الخدمة غير متوفرة حاليًا",
    40000: "فشلت المعاملة",
    40002: "فشلت المعاملة",
    40012: "الطلب غير موجود",
    40013: "انتهى الأمر",
    40014: "معلومات الطلب غير متسقة",
    40015: "تجاوز الحد الأقصى للمبلغ (بما في ذلك الرسوم)",
    40101: "مهلة الطلب",
    40201: "فشل التحميل",
    40301: "البنك مشغول",
    "SYS-0001": "تم رفض دفعتك. اختر طريقة دفع أخرى. نوصي بطرق الدفع نقدًا",
    "SYS-0002": "وقت مستقطع",
    "SYS-0003": "مبلغ المعاملة غير صالح",
    "SYS-0004": "يجب على الدافع تقديم بريد إلكتروني صالح",
    "CC-0001": "مستخدمون غير صالحين للمشغلين",
    "CC-0002": "رمز البطاقة غير صالح",
    "CC-0003": "الطوارئ المعلقة",
    "CC-0004": "دليل المراجعة المعلق",
    "CC-0005": "رقم البطاقة المرفوض والمعبأ غير صالح",
    "CC-0006": "تاريخ مرفوض وملء غير صالح",
    "CC-0007": "مرفوضة، وملأت أخرى سيئة",
    "CC-0008": "رمز أمان مرفوض ومملوء بشكل سيئ مرفوضة،",
    "CC-0009": " مرفوضة، في القائمة السوداء",
    "CC-0010": " مرفوض، دعوة للحصول على إذن",
    "CC-0011": "مرفوضة، البطاقة معطلة",
    "CC-0012": " مرفوض، خطأ في البطاقة ",
    "CC-0013": "دفع مكرر مرفوض",
    "CC-0014": "مرفوضة ، عالية الخطورة",
    "CC-0015": "مبلغ مرفوض وغير كافٍ",
    "CC-0016": "أقساط مرفوضة وغير صالحة",
    "CC-0017": "مرفوضة ، الحد الأقصى من المحاولات",
    "CC-0018": " مرفوض ، سبب آخر",
    70001: "نحن غير قادرين على معالجة دفعتك",
    refresh_notice: " سيتم تحديث الصفحة تلقائيًا في غضون 10 ثانية ، وإلا يرجى النقر فوق الزر أدناه",
    refresh: "تحديث",
    fail_query: "هل تريد المحاولة مرة أخرى؟",
    yes: "نعم",
    help_me: " المساعدة ",
    source: "موقع الويب أو اسم التطبيق الذي اشتريت منه المنتج",
    timed_out: "انتهت مهلة طلب المعاملة",
    name: "اسم",
    copy: "نسخ",
    wallet: "محافظ",
    copied: "تم النسخ",
    ref_no: "الرقم المرجعي :",
    payment_ticket: "تذكرة الدفع",
    no_channel: "لا توجد قناة ، يرجى الاتصال بالتاجر",
    wallet_inst1: "قم بمسح الرمز وقم بعملية الدفع",
    wallet_inst2: "يمكنك مسح الرمز والقيام بعملية الدفع",
    wallet_inst3: "كيف تريد الدفع؟",
    wallet_inst4: "افتح تطبيق محفظتك على جهازك",
    wallet_inst5: "اضغط على ميزة المسح الضوئي",
    wallet_inst6: "قم بمسح الرمز وإكمال عملية الدفع",
    wallet_inst7: "يمكن أيضًا استخدام الرقم المرجعي للدفع",
    amount: "مبلغ الدفع",
    "70001-10001": "CPF الذي تم إدخاله غير صالح ، أدخل واحدًا آخر",
    "70001-10002": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
    "70001-10003": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
    "70001-10004": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
    "70001-20001": "تجاوز حد المعاملة",
    "70001-20003": "تم تجاوز حد المعاملة الواحدة",
    "70001-99999": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
  },
};

export default ar;
