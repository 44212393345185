import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import "./DropZoneCustom.scss";
import Dropzone, { defaultClassNames } from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";

const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles },
}) => {
  return (
    <div className="dropZoneCustom">
      <div {...dropzoneProps}>{input}</div>
      {previews}
      {files.length > 0 && submitButton}
    </div>
  );
};

const Input =
  (image, title) =>
  ({ accept, onFiles, files, getFilesFromEvent }) => {
    return (
      <label className="dropZoneCustom__label">
        <img src={image} alt="" />
        <span>{title}</span>
        {files.length === 0 && (
          <input
            style={{ display: "none" }}
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        )}
      </label>
    );
  };

function DropZoneCustom({ image, title, setForm }) {
  const handleChangeStatus = (fileWithMeta, status) => {
    // console.log(status, fileWithMeta.file);
    if (status === "done") {
      setForm((prev) => ({
        ...prev,
        file: { ...prev.file, value: fileWithMeta.file, checked: true },
      }));
    } else if (status === "removed") {
      setForm((prev) => ({
        ...prev,
        file: { ...prev.file, value: "" },
      }));
    }
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  return (
    <>
      <Dropzone
        LayoutComponent={Layout}
        onChangeStatus={handleChangeStatus}
        classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
        multiple={false}
        maxFiles={1}
        InputComponent={Input(image, title)}
        getFilesFromEvent={getFilesFromEvent}
        maxSizeBytes={1024 * 1024 * 5}
        accept=".pdf,.jpg,.png,.txt,.jpeg"
      />
    </>
  );
}

export default DropZoneCustom;
