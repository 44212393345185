import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import { state2Cities } from "../../utils/regionMx";
import { checkCity } from "../../utils/validations";

function City({ form, setForm, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState("");
  useEffect(() => {
    if (!(state2Cities[form.state.value]?.indexOf(form.city.value) >= 0)) {
      setForm((prev) => ({ ...prev, city: { ...prev.city, value: "", valid: prev.city.checked ? false : true } }));
    }
  }, [form.state.value]);
  return (
    <>
      <Autocomplete
        className="homeMobile__filling__content__input"
        value={form.city.value}
        onChange={(event, newValue) => {
          setForm((prev) => {
            return {
              ...prev,
              city: { ...prev.city, value: newValue },
            };
          });
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={state2Cities[form.state.value] ? state2Cities[form.state.value] : []}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("city")}
            variant="outlined"
            error={!form.city.valid}
            helperText={!form.city.valid && t("invalid_city")}
            size="small"
            inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
          />
        )}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onBlur={() => {
          checkCity({ form: form, setForm: setForm });
        }}
      />
    </>
  );
}

export default City;
