import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setTradeStatus } from "../redux/actions";
import "./TradeStatus.scss";
import PaymentCode from "./PaymentCode";
import Processing from "./Processing";
import Success from "./Success";
import Failed from "./Failed";
import Other from "./Other";
import { useTranslation } from "react-i18next";
import { FormatMoney } from "../utils/formats";

function TradeStatus({ status, ...props }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (
      status === "process" &&
      !sessionStorage?.getItem(props.prepayId + "clickedPay") &&
      props.payData &&
      props.method
    ) {
      props.setTradeStatus({ id: "order" });
    }
  }, []);

  return (
    <div className="status">
      {props.payData?.reference &&
        (status === "order" || status === "process") && (
          <>
            <PaymentCode copy />
            <hr />
          </>
        )}
      {props?.payData?.amount &&
        props?.paymentInfo?.pay_amount &&
        props?.payData?.amount != props?.paymentInfo?.pay_amount && (
          <div className="status__payDetail__container">
            <p
              className="status__payDetail__totalText"
              style={{ fontSize: "13px" }}
            >
              {t("bank_fee")}
            </p>
            <p
              className="status__payDetail__totalValue"
              style={{ fontSize: "14px" }}
            >
              {props?.paymentInfo?.pay_currency ||
                props?.tradeInfo?.pay_currency}{" "}
              {(
                props?.payData?.amount -
                (props?.paymentInfo?.order_amount ||
                  props?.tradeInfo?.order_amount)
              )?.toFixed(2)}
            </p>
          </div>
        )}
      <div
        className="status__payDetail__container"
        style={{ marginTop: status === "order" ? "50px" : "0px" }}
      >
        <p className="status__payDetail__totalText">{t("total")}</p>
        <p className="status__payDetail__totalValue">
          <FormatMoney type="total" />
        </p>
      </div>

      {status === "process" && <Processing />}
      {status === "fail" && <Failed />}
      {status === "success" && <Success />}
      {status === "other" && <Other />}

      {status === "order" && props.method === "BankTransfer" && (
        <>
          <button
            className="status__payButton"
            onClick={() => {
              sessionStorage?.setItem(props.prepayId + "clickedPay", "yes");
              props.method === "BankTransfer" &&
                window.open(props.payData?.pay_url);
              props.setTradeStatus({ id: "process" });
            }}
          >
            {t("pay2")}
          </button>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    prepayId: state.prepayId,
    returnUrl: state.returnUrl,
    payData: state.payData,
    method: state.method,
    paymentInfo: state.paymentInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTradeStatus: (item) => dispatch(setTradeStatus(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeStatus);
