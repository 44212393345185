import React, { useState } from "react";
import "./Header.scss";
import { connect } from "react-redux";
import { queryOrder } from "../redux/actions";
import RightArrowWhite from "../img/right_arrow_white.png";
import LeftArrowWhite from "../img/left_arrow_white.png";
import pagsmileLogo from "../img/pagsmile_logo.png";
import pagsmileLogoM from "../img/pagsmile_logo2.png";
import select from "../img/select_icon.png";
import selectM from "../img/select_icon2.png";
import LangLogo from "../img/地球.png";
import InfoLogo from "../img/信息.png";
import InfoLogoM from "../img/信息2.png";
import { useTranslation } from "react-i18next";
import MEX_flag from "../img/墨西哥.jpg";
import CHL_flag from "../img/智利.jpg";
import COL_flag from "../img/哥伦比亚.jpg";
import PER_flag from "../img/秘鲁.jpg";
import ECU_flag from "../img/厄瓜多尔.jpg";
import BRA_flag from "../img/巴西.png";
import PAN_flag from "../img/巴.jpg";
import CRI_flag from "../img/哥斯.jpg";
import SLV_flag from "../img/萨尔瓦多.jpg";
import GTM_flag from "../img/危地马拉.jpg";
import NIC_flag from "../img/尼加拉瓜.jpg";
import SGP_flag from "../img/新加坡.png";
import ARG_flag from "../img/ARG_flag.png";
import URY_flag from "../img/URY_flag.png";
import BOL_flag from "../img/BOL_flag.png";
import PRY_flag from "../img/PRY_flag.png";
import SAU_flag from "../img/沙特.jpg";
import ARE_flag from "../img/阿拉伯.jpg";
import KWT_flag from "../img/科威特.jpg";
import QAT_flag from "../img/卡塔尔.jpg";
import OMN_flag from "../img/阿曼.jpg";
import EGY_flag from "../img/埃及.png";
import BHR_flag from "../img/BHR_flag.png";
import DropDownMenu from "./DropDownMenu";
import FlipMove from "react-flip-move";
import uuid from "react-uuid";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import SortDown from "../img/sort_down.png";
import { useLocation } from "react-router-dom";
var classNames = require("classnames");

const codeToFlag = {
  MEX: MEX_flag,
  CHL: CHL_flag,
  COL: COL_flag,
  PER: PER_flag,
  ECU: ECU_flag,
  BRA: BRA_flag,
  PAN: PAN_flag,
  CRI: CRI_flag,
  SLV: SLV_flag,
  GTM: GTM_flag,
  NIC: NIC_flag,
  SGP: SGP_flag,
  ARG: ARG_flag,
  URY: URY_flag,
  BOL: BOL_flag,
  PRY: PRY_flag,
  SAU: SAU_flag,
  ARE: ARE_flag,
  KWT: KWT_flag,
  QAT: QAT_flag,
  OMN: OMN_flag,
  EGY: EGY_flag,
  BHR: BHR_flag,
};

const CountryDropDown = ({ countryState, countryList, queryOrder, prepayId }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    countryList && setOpen((prevOpen) => !prevOpen);
  };

  const handleCountryChange = (country) => {
    if (countryState !== country) {
      queryOrder(prepayId, country);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  return (
    <div className="countryDropDown">
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <img className="countryDropDown__countryFlag" src={codeToFlag[countryState]} alt="" />
        {countryList && (
          <img
            className={classNames({
              arrow__180: open,
              arrow__0: !open,
            })}
            src={SortDown}
            alt=""
          />
        )}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 50 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {countryList?.map?.((item) => (
                    <MenuItem
                      onClick={(event) => {
                        handleCountryChange(item);
                        handleClose(event);
                      }}
                    >
                      <img className="countryDropDown__countryFlag" src={codeToFlag[item]} alt="" />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation();
  console.log("t(i18n.resolvedLanguage :", i18n.resolvedLanguage);
  const languageList = ["en", "es", "pt", "ar"];
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLangChange = (language) => {
    i18n.changeLanguage(language);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  return (
    <div className="languageDropdown">
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <div className="languageDropdown__langContainer">
          <img src={LangLogo} alt="" />
          <span className="languageDropdown__langText">{t(i18n.resolvedLanguage)}</span>
          <img
            className={classNames({
              arrow__180: open,
              arrow__0: !open,
            })}
            src={SortDown}
            alt=""
          />
        </div>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 50 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {languageList?.map?.((item) => (
                    <MenuItem
                      onClick={(event) => {
                        handleLangChange(item);
                        handleClose(event);
                      }}
                    >
                      {t(item)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

function Header({ returnToShop = false, ...props }) {
  const { t, i18n } = useTranslation();
  let location = useLocation();
  const countryState = props.tradeInfo?.app_info?.region;

  const [dropDownState, setDropDpwnState] = useState({
    state: false,
    value: "main",
  });

  return (
    <div>
      <div className="header">
        <div className="header__container">
          <img className="header__pagsmile" src={pagsmileLogo} alt="" />
          <div className="header__rightContainer">
            <LanguageDropdown />
            <img
              className="header__info"
              src={InfoLogo}
              alt=""
              onClick={() => {
                setDropDpwnState((prev) => ({
                  state: true,
                  value: "main",
                }));
              }}
            />
            <CountryDropDown
              countryState={countryState}
              countryList={
                props.tradeInfo?.app_info?.global &&
                props.tradeStatus.id === "trade" &&
                props.tradeInfo?.app_info?.global_countries
              }
              queryOrder={props.queryOrder}
              prepayId={props.prepayId}
            />
          </div>
        </div>
      </div>

      {/* Mobile starts here */}
      {/* Mobile starts here */}
      <div className="headerMobile">
        <div className="headerMobile__top">
          {location?.pathname === "/" && props.returnUrl && (
            <img
              className="headerMobile__returnButton"
              src={LeftArrowWhite}
              alt=""
              onClick={() => {
                window.open(props.returnUrl, "_parent");
              }}
            />
          )}
          <img className="headerMobile__pagsmile" src={pagsmileLogoM} alt="" />
        </div>
        <div className="headerMobile__bottom">
          <CountryDropDown
            countryState={countryState}
            countryList={
              props.tradeInfo?.app_info?.global &&
              props.tradeStatus.id === "trade" &&
              props.tradeInfo?.app_info?.global_countries
            }
            queryOrder={props.queryOrder}
            prepayId={props.prepayId}
          />
          <div
            className="header--langContainer"
            onClick={() => {
              setDropDpwnState((prev) => ({
                state: true,
                value: "lang",
              }));
            }}
          >
            <span className="header__langTextSelect">{t(i18n.resolvedLanguage)}</span>
            <img className="header__selectIcon" src={selectM} alt="" />
          </div>
          <div className="headerMobile__infoContainer">
            <img
              className="headerMobile__info"
              src={InfoLogoM}
              alt=""
              onClick={() => {
                setDropDpwnState((prev) => ({
                  state: true,
                  value: "main",
                }));
              }}
            />
          </div>
        </div>
      </div>
      <FlipMove enterAnimation="accordionVertical" leaveAnimation="accordionVertical">
        {dropDownState.state && (
          <DropDownMenu
            back={() => {
              setDropDpwnState((prev) => ({
                ...prev,
                state: false,
              }));
            }}
            state={dropDownState.value}
            key={uuid()}
          />
        )}
      </FlipMove>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    returnUrl: state.returnUrl,
    prepayId: state.prepayId,
    tradeStatus: state.tradeStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { queryOrder: (prepayId, handleError) => dispatch(queryOrder(prepayId, handleError)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
