import React from "react";
import "./CopyButton.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CopyIcon from "../img/icon_copy.png";
import { useTranslation } from "react-i18next";

function CopyButton({ text, buttonText, minWidth = 150, height = 45 }) {
  const { t } = useTranslation();
  const notify = () => {
    toast.success(t("copied"), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <div className="copyButton">
      <CopyToClipboard text={text} onCopy={() => {}}>
        <button className="copyButton__button" onClick={notify} style={{ minWidth: minWidth, height: height }}>
          {buttonText || t("copy")}
          <img src={CopyIcon} alt="" style={{ marginLeft: "10px" }} />
        </button>
      </CopyToClipboard>
    </div>
  );
}

export default CopyButton;
