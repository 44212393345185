import {
  SET_METHOD,
  SET_CHANNEL,
  SET_TRADE_INFO,
  SET_PAYMENT_INFO,
  SET_CHANNEL_LIST,
  SET_RETURN_URL,
  SET_PREPAY_ID,
  SET_PAY_DATA,
  SET_TRADE_STATUS,
  SET_PAY_CHANNEL,
  SET_LOADED_LIBS,
  SET_PAYPAL_CREDITCARD,
  SET_PAYPAL_WALLET,
  SET_GEOLOCATION,
  SET_ISAPPLOADING,
} from "./types";

import { depositExpressChannels, pagoEfectivoChannels, cryptoChannels } from "../utils/configurations";

export const initialState = {
  isAppLoading: true,
  method: "",
  payChannel: "",
  channel: "",
  returnUrl: "",
  prepayId: "",
  tradeInfo: "",
  tradeStatus: { id: "trade" },
  paymentInfo: {},
  payData: "",
  channelList: {
    Cash: [],
    BankTransfer: [],
    Khipu: [],
    Wallet: [],
    DepositExpress: depositExpressChannels,
    PagoEfectivo: pagoEfectivoChannels,
    Crypto: cryptoChannels,
  },
  loadedLibs: {},
  paypalWallet: { isReady: false, authorizationToken: "", deviceData: "" },
  paypalCreditCard: {
    authorizationToken: "",
    deviceData: "",
    cardName: { valid: false, checked: false },
    cardNumber: { valid: false, checked: false },
    expDate: { valid: false, checked: false },
    cvv: { valid: false, checked: false },
  },
  geoLocation: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_METHOD:
      return {
        ...state,
        method: action.item,
      };
    case SET_CHANNEL:
      return {
        ...state,
        channel: action.item,
      };
    case SET_RETURN_URL:
      return {
        ...state,
        returnUrl: action.item,
      };
    case SET_PREPAY_ID:
      return {
        ...state,
        prepayId: action.item,
      };
    case SET_TRADE_INFO:
      return {
        ...state,
        tradeInfo: action.item,
      };

    case SET_PAYMENT_INFO:
      return {
        ...state,
        paymentInfo: action.item,
      };
    case SET_CHANNEL_LIST:
      return {
        ...state,
        channelList: action.item,
      };
    case SET_PAY_DATA:
      return {
        ...state,
        payData: action.item,
      };
    case SET_TRADE_STATUS:
      return {
        ...state,
        tradeStatus: action.item,
      };
    case SET_PAY_CHANNEL:
      return {
        ...state,
        payChannel: action.item,
      };
    case SET_LOADED_LIBS:
      return {
        ...state,
        loadedLibs: { ...state.loadedLibs, ...action.item },
      };
    case SET_PAYPAL_CREDITCARD:
      if (action.item?.type === "keepPrev") {
        return {
          ...state,
          paypalCreditCard: {
            ...state.paypalCreditCard,
            [action.item.key]: { ...state.paypalCreditCard[action.item.key], ...action.item.value },
          },
        };
      } else {
        return {
          ...state,
          paypalCreditCard: {
            ...state.paypalCreditCard,
            ...action.item,
          },
        };
      }
    case SET_PAYPAL_WALLET:
      return {
        ...state,
        paypalWallet: { ...state.paypalWallet, ...action.item },
      };
    case SET_GEOLOCATION:
      return {
        ...state,
        geoLocation: action.item,
      };
    case SET_ISAPPLOADING:
      return {
        ...state,
        isAppLoading: action.item,
      };
    default:
      return state;
  }
};

export default reducer;
