import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import Correct from "../../../img/correct.jpg";
import { useTranslation } from "react-i18next";
import { formatAccountNumber } from "../../../utils/formats";
import { checkAccountNumber } from "../../../utils/validations";

function AccountNumber({ form, setForm, setFocusOnInput = () => {}, region }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ autocomplete: "new-password" }}
        size="small"
        error={!form.account_number.valid}
        helperText={!form.account_number.valid && t("account_invalid")}
        className="homeMobile__filling__content__input"
        label={t("account_number")}
        variant="outlined"
        value={form.account_number.value}
        onChange={(event) => {
          let value = formatAccountNumber(event.target.value, region);
          // let value = event.target.value.replace(/[^\d]/g, "");
          setForm((prev) => {
            return {
              ...prev,
              account_number: { ...prev.account_number, value: value },
            };
          });
        }}
        onBlur={() => {
          checkAccountNumber({ form: form, setForm: setForm, region: region });
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Select
                className="account_number__typeSelect"
                variant="standard"
                value={form.account_number.type}
                style={{ fontSize: "12px" }}
                onChange={(event) => {
                  setForm((prev) => {
                    return {
                      ...prev,
                      account_number: {
                        ...prev.account_number,
                        type: event.target.value,
                        value: "",
                        valid: true,
                        checked: false,
                      },
                    };
                  });
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <MenuItem value={"C"}>{t("checking_account")}</MenuItem>
                <MenuItem value={"S"}>
                  {region === "ARG" && i18n.language !== "en" ? "Caja de ahorro" : t("saving_account")}
                </MenuItem>
                {region === "PER" && <MenuItem value={"M"}>{t("master_account")}</MenuItem>}
              </Select>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.account_number.valid && form.account_number.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default AccountNumber;
