import React from "react";
import { connect } from "react-redux";
import { jsonParse } from "../utils/jsonParse";
import uuid from "react-uuid";
import Cash from "../img/cash.png";
import Online from "../img/online.png";
import Steps from "../img/123.png";
import DirectionRight from "../img/direction_right.png";
import DirectionDown from "../img/direction_down.png";
import { useTranslation } from "react-i18next";
import "./CheckoutSafetyPay.scss";

function CheckoutSafetyPay(props) {
  const { t } = useTranslation();
  return (
    <div className="checkoutSafetyPay">
      <div className="checkoutSafetyPayPc">
        <p className="checkout--instructionTitle">{t("payment_inst")}</p>
        <p className="checkout--instructionsHeader">{t("payment_inst_top")}</p>
        <div className="checkout--instructions">
          {props.payData?.instruction &&
            jsonParse(props.payData?.instruction)?.map((item) => {
              return (
                <div>
                  <p className="checkout--instructions__title">{item.location_name}</p>
                  {item?.howto_pay_steps?.map((step, index) => {
                    return (
                      <p key={uuid()}>
                        {`${index + 1}. `}
                        {step.step_instruction}
                      </p>
                    );
                  })}
                </div>
              );
            })}
        </div>

        <div className="checkoutSafetyPayPc__instBottom">
          <div className="checkoutSafetyPayPc__instBottom__left">
            <img src={Steps} alt="" />
            <span> {t("payment_code")}</span>
            <span>+</span>
            <img src={props.method === "Cash" ? Cash : Online} alt="" />
            <span>{props.method === "Cash" ? t("cash") : t("online_banking")}</span>
          </div>
          <img className="checkoutSafetyPayPc__instBottom__direction" src={DirectionRight} alt="" />
          <div className="checkoutSafetyPayPc__instBottom__channels">
            {props.channel?.data?.map((item) => {
              return (
                <>
                  {item?.logo ? (
                    <img className="checkoutSafetyPayPc__instBottom__channelLogo" src={item?.logo} alt="" />
                  ) : (
                    <p className="checkoutSafetyPayPc__instBottom__channelText">{item?.bank_name}</p>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
      {/* Mobile starts here */}
      {/* Mobile starts here */}
      {/* Mobile starts here */}
      <div className="checkoutSafetyPayMobile">
        <p className="checkout--instructionTitle">{t("payment_inst")}</p>
        <p className="checkout--instructionsHeader">{t("payment_inst_top")}</p>
        <div className="checkout--instructions">
          {props.payData?.instruction &&
            jsonParse(props.payData?.instruction)?.map((item) => {
              return (
                <div>
                  <p className="checkout--instructions__title">{item.location_name}</p>
                  {item?.howto_pay_steps?.map((step, index) => {
                    return (
                      <p key={uuid()}>
                        {`${index + 1}. `}
                        {step.step_instruction}
                      </p>
                    );
                  })}
                </div>
              );
            })}
        </div>
        <div className="checkoutSafetyPayMobile__instructions__bottom">
          <div className="checkoutSafetyPayMobile__instructions__bottomInsContainer">
            <img src={props.method === "Cash" ? Cash : Online} alt="" />
            <span>{props.method === "Cash" ? t("cash") : t("online_banking")}</span>
            <span>+</span>
            <img src={Steps} alt="" />
            <span>{t("payment_code")}</span>
          </div>
          <img className="checkoutSafetyPayMobile__instructions__bottomDirectionDown" src={DirectionDown} alt="" />
          <div className="checkoutSafetyPayMobile__instructions__bottom__channels">
            {props.channel?.data?.map((item) => {
              return (
                <>
                  {item?.logo ? (
                    <img
                      className="checkoutSafetyPayMobile__instructions__bottom__channelLogo"
                      src={item?.logo}
                      alt=""
                    />
                  ) : (
                    <p className="checkoutSafetyPayMobile__instructions__bottom__channelText">{item?.bank_name}</p>
                  )}
                </>
              );
            })}
          </div>
        </div>
        {props.returnUrl && (
          <div className="checkoutSafetyPayMobile__return">
            <a href={props.returnUrl}>{t("back_shop")}</a>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    channel: state.channel,
    payData: state.payData,
    returnUrl: state.returnUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSafetyPay);
