const country2codes = [
  {
    country_name: "Afghanistan",
    alpha2_code: "AF",
    alpha3_code: "AFG",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+93",
  },
  {
    country_name: "Albania",
    alpha2_code: "AL",
    alpha3_code: "ALB",
    postal_val: /^(120|122)[0-9]{2}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+355",
  },
  {
    country_name: "Algeria",
    alpha2_code: "DZ",
    alpha3_code: "DZA",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+213",
  },
  {
    country_name: "Andorra",
    alpha2_code: "AD",
    alpha3_code: "AND",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+376",
  },
  {
    country_name: "Angola",
    alpha2_code: "AO",
    alpha3_code: "AGO",
    phone_code: "+244",
  },
  {
    country_name: "Antigua and Barbuda",
    alpha2_code: "AG",
    alpha3_code: "ATG",
    phone_code: "+1268",
  },
  {
    country_name: "Argentina",
    alpha2_code: "AR",
    alpha3_code: "ARG",
    postal_val: /^[A-Z]{1}[0-9]{4}[A-Z]{3}$/,
    postal_format: /^([A-Z]{1})([0-9]{0,4})([A-Z]{0,3})/,
    phone_code: "+54 9",
  },
  {
    country_name: "Armenia",
    alpha2_code: "AM",
    alpha3_code: "ARM",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+374",
  },
  {
    country_name: "Aruba",
    alpha2_code: "AW",
    alpha3_code: "ABW",
    phone_code: "+297",
  },
  {
    country_name: "Australia",
    alpha2_code: "AU",
    alpha3_code: "AUS",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+61",
  },
  {
    country_name: "Austria",
    alpha2_code: "AT",
    alpha3_code: "AUT",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+43",
  },
  {
    country_name: "Azerbaijan",
    alpha2_code: "AZ",
    alpha3_code: "AZE",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+994",
  },
  {
    country_name: "Bahamas",
    alpha2_code: "BS",
    alpha3_code: "BHS",
    phone_code: "+1242",
  },
  {
    country_name: "Bahrain",
    alpha2_code: "BH",
    alpha3_code: "BHR",
    phone_code: "+973",
  },
  {
    country_name: "Bangladesh",
    alpha2_code: "BD",
    alpha3_code: "BGD",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+880",
  },
  {
    country_name: "Barbados",
    alpha2_code: "BB",
    alpha3_code: "BRB",
    postal_val: /^BB[0-9]{5}$/,
    postal_format: /^(B{1,2}[0-9]{0,5})/,
    phone_code: "+1246",
  },
  {
    country_name: "Belarus",
    alpha2_code: "BY",
    alpha3_code: "BLR",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+375",
  },
  {
    country_name: "Belgium",
    alpha2_code: "BE",
    alpha3_code: "BEL",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+32",
  },
  {
    country_name: "Belize",
    alpha2_code: "BZ",
    alpha3_code: "BLZ",
    phone_code: "+501",
  },
  {
    country_name: "Benin",
    alpha2_code: "BJ",
    alpha3_code: "BEN",
    phone_code: "+229",
  },
  {
    country_name: "Bhutan",
    alpha2_code: "BT",
    alpha3_code: "BTN",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+975",
  },
  {
    country_name: "Bolivia",
    alpha2_code: "BO",
    alpha3_code: "BOL",
    phone_code: "+591",
  },
  {
    country_name: "Bosnia and Herzegovina",
    alpha2_code: "BA",
    alpha3_code: "BIH",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+387",
  },
  {
    country_name: "Botswana",
    alpha2_code: "BW",
    alpha3_code: "BWA",
    phone_code: "+267",
  },
  {
    country_name: "Brazil",
    alpha2_code: "BR",
    alpha3_code: "BRA",
    postal_val: /^(\d)(?!\1+$)\d{7}$/, // 8 digit which in all digits are not same,
    postal_format: /^([\d]{1,5})([\d]{0,3})/,
    phone_code: "+55",
  },
  {
    country_name: "British Indian Ocean Territory",
    alpha2_code: "IO",
    alpha3_code: "IOT",
    phone_code: "+246",
  },

  {
    country_name: "Brunei",
    alpha2_code: "BN",
    alpha3_code: "BRN",
    postal_val: /^[A-Z]{2}[0-9]{4}$/,
    postal_format: /^([A-Z]{1,2}[0-9]{0,4})/,
    phone_code: "+673",
  },
  {
    country_name: "Bulgaria",
    alpha2_code: "BG",
    alpha3_code: "BGR",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+359",
  },
  {
    country_name: "Burkina Faso",
    alpha2_code: "BF",
    alpha3_code: "BFA",
    phone_code: "+226",
  },
  {
    country_name: "Burundi",
    alpha2_code: "BI",
    alpha3_code: "BDI",
    phone_code: "+257",
  },
  {
    country_name: "Cambodia",
    alpha2_code: "KH",
    alpha3_code: "KHM",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+855",
  },
  {
    country_name: "Cameroon",
    alpha2_code: "CM",
    alpha3_code: "CMR",
    phone_code: "+237",
  },
  {
    country_name: "Canada",
    alpha2_code: "CA",
    alpha3_code: "CAN",
    postal_val: /^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/,
    postal_format: /^([A-Z])([0-9]{0,1})([A-Z]{0,1})([0-9]{0,1})([A-Z]{0,1})([0-9]{0,1})/,
    phone_code: "+1",
  },
  {
    country_name: "Cape Verde",
    alpha2_code: "CV",
    alpha3_code: "CPV",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+238",
  },
  {
    country_name: "Caribbean Netherlands",
    alpha2_code: "BQ",
    alpha3_code: "BES",
    phone_code: "+599",
  },
  {
    country_name: "Central African Republic",
    alpha2_code: "CF",
    alpha3_code: "CAF",
    phone_code: "+236",
  },
  {
    country_name: "Chad",
    alpha2_code: "TD",
    alpha3_code: "TCD",
    phone_code: "+235",
  },
  {
    country_name: "Chile",
    alpha2_code: "CL",
    alpha3_code: "CHL",
    postal_val: /^[0-9]{7}$/,
    postal_format: /^([0-9]{1,7})/,
    phone_code: "+56",
  },
  {
    country_name: "China",
    alpha2_code: "CN",
    alpha3_code: "CHN",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+86",
  },
  {
    country_name: "Colombia",
    alpha2_code: "CO",
    alpha3_code: "COL",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+57",
  },
  {
    country_name: "Comoros",
    alpha2_code: "KM",
    alpha3_code: "COM",
    phone_code: "+269",
  },
  {
    country_name: "Congo",
    alpha2_code: "CG",
    alpha3_code: "COG",
    phone_code: "+242",
  },
  {
    country_name: "Democratic Congo",
    alpha2_code: "CD",
    alpha3_code: "COD",
    phone_code: "+243",
  },
  {
    country_name: "Costa Rica",
    alpha2_code: "CR",
    alpha3_code: "CRI",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+506",
  },
  {
    country_name: "Côte d'Ivoire",
    alpha2_code: "CI",
    alpha3_code: "CIV",
    phone_code: "+225",
  },
  {
    country_name: "Croatia",
    alpha2_code: "HR",
    alpha3_code: "HRV",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+385",
  },
  {
    country_name: "Cuba",
    alpha2_code: "CU",
    alpha3_code: "CUB",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+53",
  },
  {
    country_name: "Curaçao",
    alpha2_code: "CW",
    alpha3_code: "CUW",
    phone_code: "+599",
  },
  {
    country_name: "Cyprus",
    alpha2_code: "CY",
    alpha3_code: "CYP",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+357",
  },
  {
    country_name: "Czech Republic",
    alpha2_code: "CZ",
    alpha3_code: "CZE",
    postal_val: /^[0-9]{3} ?[0-9]{2}$/,
    postal_format: /^([0-9]{1,3})([0-9]{0,2})/,
    phone_code: "+420",
  },
  {
    country_name: "Denmark",
    alpha2_code: "DK",
    alpha3_code: "DNK",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+45",
  },
  {
    country_name: "Djibouti",
    alpha2_code: "DJ",
    alpha3_code: "DJI",
    phone_code: "+253",
  },
  {
    country_name: "Dominica",
    alpha2_code: "DM",
    alpha3_code: "DMA",
    phone_code: "+1767",
  },
  {
    country_name: "Dominican Republic",
    alpha2_code: "DO",
    alpha3_code: "DOM",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+1",
  },
  {
    country_name: "Ecuador",
    alpha2_code: "EC",
    alpha3_code: "ECU",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+593",
  },
  {
    country_name: "Egypt",
    alpha2_code: "EG",
    alpha3_code: "EGY",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+20",
  },
  {
    country_name: "El Salvador",
    alpha2_code: "SV",
    alpha3_code: "SLV",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+503",
  },
  {
    country_name: "Equatorial Guinea",
    alpha2_code: "GQ",
    alpha3_code: "GNQ",
    phone_code: "+240",
  },
  {
    country_name: "Eritrea",
    alpha2_code: "ER",
    alpha3_code: "ERI",
    phone_code: "+291",
  },
  {
    country_name: "Estonia",
    alpha2_code: "EE",
    alpha3_code: "EST",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+372",
  },
  {
    country_name: "Ethiopia",
    alpha2_code: "ET",
    alpha3_code: "ETH",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+251",
  },
  {
    country_name: "Fiji",
    alpha2_code: "FJ",
    alpha3_code: "FJI",
    phone_code: "+679",
  },
  {
    country_name: "Finland",
    alpha2_code: "FI",
    alpha3_code: "FIN",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+358",
  },
  {
    country_name: "France",
    alpha2_code: "FR",
    alpha3_code: "FRA",
    postal_val: /^[0-9]{3}$/,
    postal_format: /^([0-9]{1,3})/,
    phone_code: "+33",
  },
  {
    country_name: "French Guiana",
    alpha2_code: "GF",
    alpha3_code: "GUF",
    phone_code: "+594",
  },
  {
    country_name: "French Polynesia",
    alpha2_code: "PF",
    alpha3_code: "PYF",
    postal_val: /^987[0-9]{2}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+689",
  },
  {
    country_name: "Gabon",
    alpha2_code: "GA",
    alpha3_code: "GAB",
    phone_code: "+241",
  },
  {
    country_name: "Gambia",
    alpha2_code: "GM",
    alpha3_code: "GMB",
    phone_code: "+220",
  },
  {
    country_name: "Georgia",
    alpha2_code: "GE",
    alpha3_code: "GEO",
    phone_code: "+995",
  },
  {
    country_name: "Germany",
    alpha2_code: "DE",
    alpha3_code: "DEU",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+49",
  },
  {
    country_name: "Ghana",
    alpha2_code: "GH",
    alpha3_code: "GHA",
    phone_code: "+233",
  },
  {
    country_name: "Greece",
    alpha2_code: "GR",
    alpha3_code: "GRC",
    postal_val: /^[0-9]{3} ?[0-9]{2}$/,
    postal_format: /^([0-9]{1,3})([0-9]{0,2})/,
    phone_code: "+30",
  },
  {
    country_name: "Grenada",
    alpha2_code: "GD",
    alpha3_code: "GRD",
    phone_code: "+1473",
  },
  {
    country_name: "Guadeloupe",
    alpha2_code: "GP",
    alpha3_code: "GLP",
    postal_val: /^971[0-9]{2}$/,
    postal_format: /^([0-9]{5})/,
    phone_code: "+590",
  },
  {
    country_name: "Guam",
    alpha2_code: "GU",
    alpha3_code: "GUM",
    postal_val: /^\d{5}(?:-?\d{4})?$/,
    postal_format: /^([0-9]{1,5})([0-9]{0,4})/,
    phone_code: "+1671",
  },
  {
    country_name: "Guatemala",
    alpha2_code: "GT",
    alpha3_code: "GTM",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+502",
  },
  {
    country_name: "Guinea Republic",
    alpha2_code: "GN",
    alpha3_code: "GIN",
    postal_val: /^[0-9]{3}$/,
    postal_format: /^([0-9]{1,3})/,
    phone_code: "+224",
  },
  {
    country_name: "Guinea-Bissau",
    alpha2_code: "GW",
    alpha3_code: "GNB",
    phone_code: "+245",
  },
  {
    country_name: "Guyana",
    alpha2_code: "GY",
    alpha3_code: "GUY",
    phone_code: "+592",
  },
  {
    country_name: "Haiti",
    alpha2_code: "HT",
    alpha3_code: "HTI",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+509",
  },
  {
    country_name: "Honduras",
    alpha2_code: "HN",
    alpha3_code: "HND",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+504",
  },
  {
    country_name: "Hong Kong",
    alpha2_code: "HK",
    alpha3_code: "HKG",
    phone_code: "+852",
  },
  {
    country_name: "Hungary",
    alpha2_code: "HU",
    alpha3_code: "HUN",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+36",
  },
  {
    country_name: "Iceland",
    alpha2_code: "IS",
    alpha3_code: "ISL",
    postal_val: /^[0-9]{3}$/,
    postal_format: /^([0-9]{1,3})/,
    phone_code: "+354",
  },
  {
    country_name: "India",
    alpha2_code: "IN",
    alpha3_code: "IND",
    postal_val: /^[1-9][0-9]{5}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+91",
  },
  {
    country_name: "Indonesia",
    alpha2_code: "ID",
    alpha3_code: "IDN",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+62",
  },
  {
    country_name: "Iran",
    alpha2_code: "IR",
    alpha3_code: "IRN",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+98",
  },
  {
    country_name: "Iraq",
    alpha2_code: "IQ",
    alpha3_code: "IRQ",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+964",
  },
  {
    country_name: "Ireland",
    alpha2_code: "IE",
    alpha3_code: "IRL",
    postal_val: /^(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/,
    phone_code: "+353",
  },
  {
    country_name: "Israel",
    alpha2_code: "IL",
    alpha3_code: "ISR",
    postal_val: /^[0-9]{5}|[0-9]{7}$/,
    postal_format: /^([0-9]{1,7})/,
    phone_code: "+972",
  },
  {
    country_name: "Italy",
    alpha2_code: "IT",
    alpha3_code: "ITA",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+39",
  },
  {
    country_name: "Jamaica",
    alpha2_code: "JM",
    alpha3_code: "JAM",
    phone_code: "+1876",
  },
  {
    country_name: "Japan",
    alpha2_code: "JP",
    alpha3_code: "JPN",
    postal_val: /^[0-9]{3}-?[0-9]{4}$/,
    postal_format: /^([0-9]{1,3})([0-9]{0,4})/,
    phone_code: "+81",
  },
  {
    country_name: "Jordan",
    alpha2_code: "JO",
    alpha3_code: "JOR",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+962",
  },
  {
    country_name: "Kazakhstan",
    alpha2_code: "KZ",
    alpha3_code: "KAZ",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+7",
  },
  {
    country_name: "Kenya",
    alpha2_code: "KE",
    alpha3_code: "KEN",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+254",
  },
  {
    country_name: "Kiribati",
    alpha2_code: "KI",
    alpha3_code: "KIR",
    phone_code: "+686",
  },
  {
    country_name: "Kosovo",
    alpha2_code: "XK",
    alpha3_code: "XKX",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+383",
  },
  {
    country_name: "Kuwait",
    alpha2_code: "KW",
    alpha3_code: "KWT",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+965",
  },
  {
    country_name: "Kyrgyzstan",
    alpha2_code: "KG",
    alpha3_code: "KGZ",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+996",
  },
  {
    country_name: "Laos",
    alpha2_code: "LA",
    alpha3_code: "LAO",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+856",
  },
  {
    country_name: "Latvia",
    alpha2_code: "LV",
    alpha3_code: "LVA",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+371",
  },
  {
    country_name: "Lebanon",
    alpha2_code: "LB",
    alpha3_code: "LBN",
    postal_val: /^[0-9]{4} ?[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})([0-9]{0,4})/,
    phone_code: "+961",
  },
  {
    country_name: "Lesotho",
    alpha2_code: "LS",
    alpha3_code: "LSO",
    postal_val: /^[0-9]{3}$/,
    postal_format: /^([0-9]{1,3})/,
    phone_code: "+266",
  },
  {
    country_name: "Liberia",
    alpha2_code: "LR",
    alpha3_code: "LBR",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+231",
  },
  {
    country_name: "Libya",
    alpha2_code: "LY",
    alpha3_code: "LBY",
    phone_code: "+218",
  },
  {
    country_name: "Liechtenstein",
    alpha2_code: "LI",
    alpha3_code: "LIE",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+423",
  },
  {
    country_name: "Lithuania",
    alpha2_code: "LT",
    alpha3_code: "LTU",
    postal_val: /^LT-?[0-9]{5}$/,
    postal_format: /^([A-Z]{1,2})([0-9]{0,5})/,
    phone_code: "+370",
  },
  {
    country_name: "Luxembourg",
    alpha2_code: "LU",
    alpha3_code: "LUX",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+352",
  },
  {
    country_name: "Macau",
    alpha2_code: "MO",
    alpha3_code: "MAC",
    phone_code: "+853",
  },
  {
    country_name: "Macedonia",
    alpha2_code: "MK",
    alpha3_code: "MKD",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+389",
  },
  {
    country_name: "Madagascar",
    alpha2_code: "MG",
    alpha3_code: "MDG",
    postal_val: /^[0-9]{3}$/,
    postal_format: /^([0-9]{1,3})/,
    phone_code: "+261",
  },
  {
    country_name: "Malawi",
    alpha2_code: "MW",
    alpha3_code: "MWI",
    phone_code: "+265",
  },
  {
    country_name: "Malaysia",
    alpha2_code: "MY",
    alpha3_code: "MYS",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+60",
  },
  {
    country_name: "Maldives",
    alpha2_code: "MV",
    alpha3_code: "MDV",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+960",
  },
  {
    country_name: "Mali",
    alpha2_code: "ML",
    alpha3_code: "MLI",
    phone_code: "+223",
  },
  {
    country_name: "Malta",
    alpha2_code: "MT",
    alpha3_code: "MLT",
    postal_val: /^[A-Z]{3} ?[0-9]{4}$/,
    postal_format: /^([A-Z]{1,3})([0-9]{0,4})/,
    phone_code: "+356",
  },
  {
    country_name: "Marshall Islands",
    alpha2_code: "MH",
    alpha3_code: "MHL",
    postal_val: /^\d{5}(?:-?\d{4})?$/,
    postal_format: /^([0-9]{1,5})([0-9]{0,4})/,
    phone_code: "+692",
  },
  {
    country_name: "Martinique",
    alpha2_code: "MQ",
    alpha3_code: "MTQ",
    postal_val: /^972[0-9]{2}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+596",
  },
  {
    country_name: "Mauritania",
    alpha2_code: "MR",
    alpha3_code: "MRT",
    phone_code: "+222",
  },
  {
    country_name: "Mauritius",
    alpha2_code: "MU",
    alpha3_code: "MUS",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+230",
  },
  {
    country_name: "Mexico",
    alpha2_code: "MX",
    alpha3_code: "MEX",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+52",
  },
  {
    country_name: "Micronesia",
    alpha2_code: "FM",
    alpha3_code: "FSM",
    phone_code: "+691",
  },
  {
    country_name: "Moldova",
    alpha2_code: "MD",
    alpha3_code: "MDA",
    postal_val: /^MD-?[0-9]{4}$/,
    postal_format: /^([A-Z]{1,2})([0-9]{0,4})/,
    phone_code: "+373",
  },
  {
    country_name: "Monaco",
    alpha2_code: "MC",
    alpha3_code: "MCO",
    postal_val: /^980[0-9]{2}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+377",
  },
  {
    country_name: "Mongolia",
    alpha2_code: "MN",
    alpha3_code: "MNG",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+976",
  },
  {
    country_name: "Montenegro",
    alpha2_code: "ME",
    alpha3_code: "MNE",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+382",
  },
  {
    country_name: "Morocco",
    alpha2_code: "MA",
    alpha3_code: "MAR",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+212",
  },
  {
    country_name: "Mozambique",
    alpha2_code: "MZ",
    alpha3_code: "MOZ",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+258",
  },
  {
    country_name: "Myanmar",
    alpha2_code: "MM",
    alpha3_code: "MMR",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+95",
  },
  {
    country_name: "Namibia",
    alpha2_code: "NA",
    alpha3_code: "NAM",
    phone_code: "+264",
  },
  {
    country_name: "Nauru",
    alpha2_code: "NR",
    alpha3_code: "NRU",
    phone_code: "+674",
  },
  {
    country_name: "Nepal",
    alpha2_code: "NP",
    alpha3_code: "NPL",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+977",
  },
  {
    country_name: "Netherlands",
    alpha2_code: "NL",
    alpha3_code: "NLD",
    postal_val: /^(?:NL-)?(\d{4})\s*([A-Z]{2})v$/,
    phone_code: "+31",
  },
  {
    country_name: "New Caledonia",
    alpha2_code: "NC",
    alpha3_code: "NCL",
    postal_val: /^988[0-9]{2}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+687",
  },
  {
    country_name: "New Zealand",
    alpha2_code: "NZ",
    alpha3_code: "NZL",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+64",
  },
  {
    country_name: "Nicaragua",
    alpha2_code: "NI",
    alpha3_code: "NIC",
    phone_code: "+505",
  },
  {
    country_name: "Niger",
    alpha2_code: "NE",
    alpha3_code: "NER",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+227",
  },
  {
    country_name: "Nigeria",
    alpha2_code: "NG",
    alpha3_code: "NGA",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+234",
  },
  {
    country_name: "North Korea",
    alpha2_code: "KP",
    alpha3_code: "PRK",
    phone_code: "+850",
  },
  {
    country_name: "Norway",
    alpha2_code: "NO",
    alpha3_code: "NOR",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+47",
  },
  {
    country_name: "Oman",
    alpha2_code: "OM",
    alpha3_code: "OMN",
    postal_val: /^[0-9]{3}$/,
    postal_format: /^([0-9]{1,3})/,
    phone_code: "+968",
  },
  {
    country_name: "Pakistan",
    alpha2_code: "PK",
    alpha3_code: "PAK",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+92",
  },
  {
    country_name: "Palau",
    alpha2_code: "PW",
    alpha3_code: "PLW",
    postal_val: /^\d{5}(?:-?\d{4})?$/,
    postal_format: /^([0-9]{1,5})([0-9]{0,4})/,
    phone_code: "+680",
  },
  {
    country_name: "Palestine",
    alpha2_code: "PS",
    alpha3_code: "PSE",
    phone_code: "+970",
  },
  {
    country_name: "Panama",
    alpha2_code: "PA",
    alpha3_code: "PAN",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+507",
  },
  {
    country_name: "Papua New Guinea",
    alpha2_code: "PG",
    alpha3_code: "PNG",
    postal_val: /^[0-9]{3}$/,
    postal_format: /^([0-9]{1,3})/,
    phone_code: "+675",
  },
  {
    country_name: "Paraguay",
    alpha2_code: "PY",
    alpha3_code: "PRY",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+595",
  },
  {
    country_name: "Peru",
    alpha2_code: "PE",
    alpha3_code: "PER",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+51",
  },
  {
    country_name: "Philippines",
    alpha2_code: "PH",
    alpha3_code: "PHL",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+63",
  },
  {
    country_name: "Poland",
    alpha2_code: "PL",
    alpha3_code: "POL",
    postal_val: /^[0-9]{2}-?[0-9]{3}$/,
    postal_format: /^([0-9]{1,2})([0-9]{0,3})/,
    phone_code: "+48",
  },
  {
    country_name: "Portugal",
    alpha2_code: "PT",
    alpha3_code: "PRT",
    postal_val: /^[0-9]{4}-?[0-9]{3}$/,
    postal_format: /^([0-9]{1,4})([0-9]{0,3})/,
    phone_code: "+351",
  },
  {
    country_name: "Puerto Rico",
    alpha2_code: "PR",
    alpha3_code: "PRI",
    postal_val: /^\d{5}(?:-?\d{4})?$/,
    postal_format: /^([0-9]{1,5})([0-9]{0,4})/,
    phone_code: "+1",
  },
  {
    country_name: "Qatar",
    alpha2_code: "QA",
    alpha3_code: "QAT",
    phone_code: "+974",
  },
  {
    country_name: "Réunion",
    alpha2_code: "RE",
    alpha3_code: "REU",
    postal_val: /^974[0-9]{2}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+262",
  },
  {
    country_name: "Romania",
    alpha2_code: "RO",
    alpha3_code: "ROU",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+40",
  },
  {
    country_name: "Russia",
    alpha2_code: "RU",
    alpha3_code: "RUS",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+7",
  },
  {
    country_name: "Rwanda",
    alpha2_code: "RW",
    alpha3_code: "RWA",
    phone_code: "+250",
  },
  {
    country_name: "Saint Kitts and Nevis",
    alpha2_code: "KN",
    alpha3_code: "KNA",
    phone_code: "+1869",
  },
  {
    country_name: "Saint Lucia",
    alpha2_code: "LC",
    alpha3_code: "LCA",
    phone_code: "+1758",
  },
  {
    country_name: "Saint Vincent and the Grenadines",
    alpha2_code: "VC",
    alpha3_code: "VCT",
    phone_code: "+1784",
  },
  {
    country_name: "Samoa",
    alpha2_code: "WS",
    alpha3_code: "WSM",
    postal_val: /^WS[0-9]{4}$/,
    postal_format: /^([A-Z]{1,2}[0-9]{0,4})/,
    phone_code: "+685",
  },
  {
    country_name: "San Marino",
    alpha2_code: "SM",
    alpha3_code: "SMR",
    phone_code: "+378",
  },
  {
    country_name: "Sao Tome and Principe",
    alpha2_code: "ST",
    alpha3_code: "STP",
    phone_code: "+239",
  },
  {
    country_name: "Saudi Arabia",
    alpha2_code: "SA",
    alpha3_code: "SAU",
    postal_val: /^[0-9]{5}(-?[0-9]{4})?$/,
    postal_format: /^([0-9]{1,5})([0-9]{0,4})/,
    phone_code: "+966",
  },
  {
    country_name: "Senegal",
    alpha2_code: "SN",
    alpha3_code: "SEN",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+221",
  },
  {
    country_name: "Serbia",
    alpha2_code: "RS",
    alpha3_code: "SRB",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+381",
  },
  {
    country_name: "Seychelles",
    alpha2_code: "SC",
    alpha3_code: "SYC",
    phone_code: "+248",
  },
  {
    country_name: "Sierra Leone",
    alpha2_code: "SL",
    alpha3_code: "SLE",
    phone_code: "+232",
  },
  {
    country_name: "Singapore",
    alpha2_code: "SG",
    alpha3_code: "SGP",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+65",
  },
  {
    country_name: "Slovakia",
    alpha2_code: "SK",
    alpha3_code: "SVK",
    postal_val: /^[0-9]{3} ?[0-9]{2}$/,
    postal_format: /^([0-9]{1,3})([0-9]{0,2})/,
    phone_code: "+421",
  },
  {
    country_name: "Slovenia",
    alpha2_code: "SI",
    alpha3_code: "SVN",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+386",
  },
  {
    country_name: "Solomon Islands",
    alpha2_code: "SB",
    alpha3_code: "SLB",
    phone_code: "+677",
  },
  {
    country_name: "Somalia",
    alpha2_code: "SO",
    alpha3_code: "SOM",
    postal_val: /^[A-Z]{2} ?[0-9]{5}$/,
    postal_format: /^([A-Z]{1,2})([0-9]{0,5})/,
    phone_code: "+252",
  },
  {
    country_name: "South Africa",
    alpha2_code: "ZA",
    alpha3_code: "ZAF",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+27",
  },
  {
    country_name: "South Korea",
    alpha2_code: "KR",
    alpha3_code: "KOR",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+82",
  },
  {
    country_name: "South Sudan",
    alpha2_code: "SS",
    alpha3_code: "SSD",
    phone_code: "+211",
  },
  {
    country_name: "Spain",
    alpha2_code: "ES",
    alpha3_code: "ESP",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+34",
  },
  {
    country_name: "Sri Lanka",
    alpha2_code: "LK",
    alpha3_code: "LKA",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+94",
  },
  {
    country_name: "Sudan",
    alpha2_code: "SD",
    alpha3_code: "SDN",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+249",
  },
  {
    country_name: "Suriname",
    alpha2_code: "SR",
    alpha3_code: "SUR",
    phone_code: "+597",
  },
  {
    country_name: "Swaziland",
    alpha2_code: "SZ",
    alpha3_code: "SWZ",
    postal_val: /^[A-Z]{1}[0-9]{3}$/,
    postal_format: /^([A-Z]{1}[0-9]{0,3})/,
    phone_code: "+268",
  },
  {
    country_name: "Sweden",
    alpha2_code: "SE",
    alpha3_code: "SWE",
    postal_val: /^[0-9]{3} ?[0-9]{2}$/,
    postal_format: /^([0-9]{1,3})([0-9]{0,2})/,
    phone_code: "+46",
  },
  {
    country_name: "Switzerland",
    alpha2_code: "CH",
    alpha3_code: "CHE",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+41",
  },
  {
    country_name: "Syria",
    alpha2_code: "SY",
    alpha3_code: "SYR",
    phone_code: "+963",
  },
  {
    country_name: "Taiwan",
    alpha2_code: "TW",
    alpha3_code: "TWN",
    postal_val: /^[0-9]{3}(-?[0-9]{2})?$/,
    postal_format: /^([0-9]{1,3})([0-9]{0,2})/,
    phone_code: "+886",
  },
  {
    country_name: "Tajikistan",
    alpha2_code: "TJ",
    alpha3_code: "TJK",
    phone_code: "+992",
  },
  {
    country_name: "Tanzania",
    alpha2_code: "TZ",
    alpha3_code: "TZA",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+255",
  },
  {
    country_name: "Thailand",
    alpha2_code: "TH",
    alpha3_code: "THA",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+66",
  },
  {
    country_name: "Timor-Leste",
    alpha2_code: "TL",
    alpha3_code: "TLS",
    phone_code: "+670",
  },
  {
    country_name: "Togo",
    alpha2_code: "TG",
    alpha3_code: "TGO",
    phone_code: "+228",
  },
  {
    country_name: "Tonga",
    alpha2_code: "TO",
    alpha3_code: "TON",
    phone_code: "+676",
  },
  {
    country_name: "Trinidad and Tobago",
    alpha2_code: "TT",
    alpha3_code: "TTO",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+1868",
  },
  {
    country_name: "Tunisia",
    alpha2_code: "TN",
    alpha3_code: "TUN",
    postal_val: /^[0-9]{4}$/,
    postal_format: /^([0-9]{1,4})/,
    phone_code: "+216",
  },
  {
    country_name: "Turkey",
    alpha2_code: "TR",
    alpha3_code: "TUR",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+90",
  },
  {
    country_name: "Turkmenistan",
    alpha2_code: "TM",
    alpha3_code: "TKM",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+993",
  },
  {
    country_name: "Tuvalu",
    alpha2_code: "TV",
    alpha3_code: "TUV",
    phone_code: "+688",
  },
  {
    country_name: "Uganda",
    alpha2_code: "UG",
    alpha3_code: "UGA",
    phone_code: "+256",
  },
  {
    country_name: "Ukraine",
    alpha2_code: "UA",
    alpha3_code: "UKR",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+380",
  },
  {
    country_name: "United Arab Emirates",
    alpha2_code: "AE",
    alpha3_code: "ARE",
    phone_code: "+971",
  },
  {
    country_name: "United Kingdom",
    alpha2_code: "GB",
    alpha3_code: "GBR",
    postal_val:
      /^([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
    phone_code: "+44",
  },
  {
    country_name: "United States",
    alpha2_code: "US",
    alpha3_code: "USA",
    postal_val: /^\d{5}(?:-?\d{4})?$/,
    postal_format: /^([0-9]{1,5})([0-9]{0,4})/,
    phone_code: "+1",
  },
  {
    country_name: "Uruguay",
    alpha2_code: "UY",
    alpha3_code: "URY",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+598",
  },
  {
    country_name: "Uzbekistan",
    alpha2_code: "UZ",
    alpha3_code: "UZB",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+998",
  },
  {
    country_name: "Vanuatu",
    alpha2_code: "VU",
    alpha3_code: "VUT",
    phone_code: "+678",
  },
  {
    country_name: "Vatican City",
    alpha2_code: "VA",
    alpha3_code: "VAT",
    phone_code: "+39",
  },
  {
    country_name: "Venezuela",
    alpha2_code: "VE",
    alpha3_code: "VEN",
    postal_val: /^[0-9]{4}(-?[A-Z]{1})?$/,
    postal_format: /^([0-9]{1,4})([A-Z]{0,1})/,
    phone_code: "+58",
  },
  {
    country_name: "Vietnam",
    alpha2_code: "VN",
    alpha3_code: "VNM",
    postal_val: /^[0-9]{6}$/,
    postal_format: /^([0-9]{1,6})/,
    phone_code: "+84",
  },
  {
    country_name: "Yemen",
    alpha2_code: "YE",
    alpha3_code: "YEM",
    phone_code: "+967",
  },
  {
    country_name: "Zambia",
    alpha2_code: "ZM",
    alpha3_code: "ZMB",
    postal_val: /^[0-9]{5}$/,
    postal_format: /^([0-9]{1,5})/,
    phone_code: "+260",
  },
  {
    country_name: "Zimbabwe",
    alpha2_code: "ZW",
    alpha3_code: "ZWE",
    phone_code: "+263",
  },
];

export default country2codes;
