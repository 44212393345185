import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../../img/correct.jpg";
import { checkToditoCardNo } from "../../../utils/validations";

function CardNo({ form, setForm, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        inputProps={{ autocomplete: "new-password" }}
        error={!form.todito_cardNo.valid}
        helperText={!form.todito_cardNo.valid && t("invalid_card_no")}
        className="homeMobile__filling__content__input"
        label={t("card_no")}
        variant="outlined"
        value={form.todito_cardNo.value}
        onChange={(event) => {
          var value = event.target.value?.replace(/[^\d]/, "");
          if (value?.length > form.todito_cardNo.max) {
            value = value.slice(0, form.todito_cardNo.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              todito_cardNo: { ...prev.todito_cardNo, value: value },
            };
          });
        }}
        onBlur={() => {
          checkToditoCardNo({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display:
                    form.todito_cardNo.valid && form.todito_cardNo.checked
                      ? "block"
                      : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default CardNo;
