import React from "react";
import "./CheckoutTitle.scss";
import { useTranslation } from "react-i18next";
import { FormatMoney } from "../utils/formats";

function CheckoutTitle(props) {
  const { t } = useTranslation();
  return (
    <>
      <p className="checkoutTitle">
        <span>{t("checkout_inst1.1")}</span>
        <span className="checkoutTitle__amount">
          {" "}
          <FormatMoney type="total" />{" "}
        </span>
        <span>{t("checkout_inst1.2")}</span>
      </p>
    </>
  );
}

export default CheckoutTitle;
