import React from "react";
import ShareOnSocialMedia from "./ShareOnSocialMedia";
import SaveButton from "./SaveButton";
import CopyButton from "./CopyButton";
import "./BarcodeAndPDF.scss";
var Barcode = require("react-barcode");

function BarcodeAndPDF({ mobile, barcode, pdf, image, barcodeWidth = 1, copyButton }) {
  return (
    <div className="barcodeAndPDF">
      {mobile ? (
        <img className="barcodeAndPDF__content__image" src={image} alt="" />
      ) : (
        pdf && (
          <embed src={pdf} type="application/pdf" width="50%" height="600" />
        )
      )}
      <div className="barcodeAndPDF__barcode">
        <Barcode value={barcode} width={barcodeWidth} fontSize={12} />
        {copyButton && <CopyButton text={copyButton?.text} buttonText={copyButton?.buttonText} />}
        {pdf && <SaveButton link={pdf} />}
        <ShareOnSocialMedia text={barcode} />
      </div>
    </div>
  );
}

export default BarcodeAndPDF;
