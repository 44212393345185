import React from "react";
import CheckoutTitle from "./CheckoutTitle";
import PaymentCode from "./PaymentCode";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import "./CheckoutKlapCash.scss";
import Klap from "../img/klap.png";

function CheckoutKlapCash(props) {
  const { t } = useTranslation();
  return (
    <div className="checkoutKlapCash">
      <div className="checkoutKlapCash__title">
        <CheckoutTitle />
        <img src={Klap} alt="" />
      </div>
      <div className="checkoutKlapCash__mobileCopy">
        <hr />
        <PaymentCode value={props.payData.reference} copy />
        <hr />
      </div>
      <div className="checkoutKlapCash__inst">
        <p className="checkoutKlapCash__instTitle">{t("clap_cash_title")}</p>
        <p className="checkoutKlapCash__instCont">
          <span>1. {t("klap_store1")}</span>
          <a href="https://www.klap.cl/home-comercios#mapa-comercios" target="_blank" rel="noreferrer">
            {t("klap_store2")}
          </a>
          <span>{t("klap_store3")}</span>
        </p>
        <p className="checkoutKlapCash__instCont">
          <span>2. {t("clap_cash_ins1")}</span>
          <span className="checkoutKlapCash__instCont__payCode">{props.payData?.reference}</span>
        </p>
        <p className="checkoutKlapCash__instCont">3. {t("clap_cash_ins2")}</p>
        <p className="checkoutKlapCash__instCont">4. {t("clap_cash_ins3")}</p>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutKlapCash);
