import React from "react";
import CheckoutTitle from "./CheckoutTitle";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import "./CheckoutBankTransferLocalpayment.scss";
import { jsonParse } from "../utils/jsonParse";
import { convertUTCDateToLocalDate } from "../utils/date";
import InfoLogo from "../img/info_icon2.svg";
import CopyButton from "./CopyButton";

function CheckoutBankTransferLocalpayment(props) {
  const { t } = useTranslation();
  const instructions = jsonParse(props.payData?.instruction);

  return (
    <div className="checkoutBankTransferLocalpayment">
      <CheckoutTitle />
      <div className="checkoutBankTransferLocalpayment__inst">
        <p className="checkoutBankTransferLocalpayment__inst__tile">{t("lp_ins1")}</p>
        <ul>
          <li>
            <p>{t("lp_ins2")}</p>
            <p className="checkoutBankTransferLocalpayment__inst__value">
              {convertUTCDateToLocalDate(props.payData?.due_date)}
            </p>
          </li>
          <li>
            <p>{t("lp_ins3")}</p>
            <p className="checkoutBankTransferLocalpayment__inst__value">{instructions?.referenceCode}</p>
            <CopyButton text={instructions?.referenceCode} minWidth={50} height={35} />
          </li>
          <li className="checkoutBankTransferLocalpayment__inst_notice">
            <img src={InfoLogo} alt="" />
            <p>{t("lp_ins11")}</p>
          </li>
        </ul>
      </div>
      <div className="checkoutBankTransferLocalpayment__info">
        <p className="checkoutBankTransferLocalpayment__info__title">{t("lp_ins4")}</p>
        <div>
          <p>{t("lp_ins5")}</p>
          <p>{instructions?.beneficiary?.bank?.name}</p>
        </div>
        <div>
          <p>{t("lp_ins6")}</p>
          <p>{instructions?.beneficiary?.bank?.account?.type}</p>
        </div>
        <div>
          <p>{t("lp_ins7")}</p>
          <p>{instructions?.beneficiary?.bank?.account?.number}</p>
        </div>
        <div>
          <p>{t("lp_ins8")}</p>
          <p>{instructions?.beneficiary?.name}</p>
        </div>
        <div>
          <p>{t("lp_ins9")}</p>
          <p>{instructions?.beneficiary?.document?.type}</p>
        </div>
        <div>
          <p>{t("lp_ins10")}</p>
          <p>{instructions?.beneficiary?.document?.id}</p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBankTransferLocalpayment);
