import React from "react";
import "./NotFound.scss";
import E404 from "../img/404.png";

function NotFound() {
  return (
    <div className="notFound">
      <div className="notFound__notice">
        <p className="notFound__notice__404">404</p>
        <p>Something's missing.</p>
        <p>This page is missing or you assembled the link incorrectly.</p>
        <button
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </button>
      </div>
      <img src={E404} alt="" />
    </div>
  );
}

export default NotFound;
