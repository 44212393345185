import React, { useState, useEffect } from "react";
import "./Channel.scss";
import Selected from "../img/selected_green.png";
import { connect } from "react-redux";
import { setChannel, setPayChannel, setPaymentInfo, changeMethod } from "../redux/actions";
import uuid from "react-uuid";

function Channel({ data = { data: [], key: "" }, loading = () => {}, subChannel, handleError, ...props }) {
  const [moreState, setMoreState] = useState(false);
  const [borderStatus, setBorderStatus] = useState(false);
  useEffect(() => {
    data?.data?.forEach((item) => {
      !item.logo && setBorderStatus(true);
    });
  }, []);

  const handleFail = (err, notice) => {
    handleError(err, notice);
    props.setChannel("");
    props.setPayChannel("");
  };

  const setChannelData = () => {
    props.setChannel(
      subChannel
        ? props.channelList[props.method][subChannel]?.find((item) => {
            return item?.key === data?.key;
          })
        : props.channelList[props.method]?.find((item) => {
            return item?.key === data?.key;
          })
    );
  };

  // const changeChannel = (method, channel) => {
  //   loading(true);
  //   req
  //     .post(
  //       `/api/trade/change-method?prepay_id=${props.prepayId}&method=${method}&channel=${channel}`
  //     )
  //     .then((res) => {
  //       if (res?.data?.code === "10000") {
  //         props.setPaymentInfo(res.data.data);
  //         props.setPayChannel(channel);
  //         setChannelData();
  //         loading(false);
  //       } else {
  //         handleFail();
  //       }
  //     })
  //     .catch((err) => {
  //       handleFail();
  //     });
  // };
  return (
    <div
      className={`channelContainer ${props.channel?.key === data?.key && "channel--border"}`}
      style={{
        border: borderStatus ? "solid 1px rgb(209, 209, 209)" : "solid 1px white",
      }}
      onClick={() => {
        if (props.method === "Wallet") {
          props.changeMethod(props.method, data?.data[0]?.channel, loading, handleFail, setChannelData);
          //changeChannel(props.method, data?.data[0]?.channel);
        } else {
          setChannelData();
        }
        setMoreState((prev) => !prev);
      }}
    >
      {!moreState &&
        data?.data?.map((item, index) => {
          if (index < 6) {
            return (
              <div
                className="channel"
                key={uuid()}
                style={{ backgroundColor: !item?.logo ? "#f1f4f8" : "transparent" }}
              >
                {item?.logo ? (
                  <img
                    className="channel__image"
                    src={item?.logo}
                    alt=""
                    onLoad={() => {
                      setBorderStatus(true);
                    }}
                  />
                ) : (
                  <p className="channel__text">{item?.bank_name || item?.channel}</p>
                )}
              </div>
            );
          }
        })}
      {moreState &&
        data?.data?.map((item) => {
          return (
            <div className="channel" key={uuid()} style={{ backgroundColor: !item?.logo ? "#f1f4f8" : "transparent" }}>
              {item?.logo ? (
                <img className="channel__image" src={item?.logo} alt="" />
              ) : (
                <p className="channel__text">{item?.bank_name || item?.channel}</p>
              )}
            </div>
          );
        })}
      {data?.data?.length > 5 && !moreState && (
        <div className="channel__more">
          <p>....</p>
        </div>
      )}
      {props.channel?.key === data?.key && <img className="channel__selected" src={Selected} alt="" />}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    channel: state.channel,
    method: state.method,
    channelList: state.channelList,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChannel: (item) => dispatch(setChannel(item)),
    setPayChannel: (item) => dispatch(setPayChannel(item)),
    setPaymentInfo: (item) => dispatch(setPaymentInfo(item)),
    changeMethod: (method, channel, setIsLoading, handleError, setChannelData) =>
      dispatch(changeMethod(method, channel, setIsLoading, handleError, setChannelData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Channel);
