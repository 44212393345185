import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import country2codes from "../../../utils/regionCodes";
import TextField from "@material-ui/core/TextField";
import Correct from "../../../img/correct.jpg";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  checkBillCountry,
  checkDeliveryCountry,
  checkBillState,
  checkDeliveryState,
  checkBillCity,
  checkDeliveryCity,
  checkBillStreet,
  checkDeliveryStreet,
  checkBillNo,
  checkDeliveryNo,
  checkBillPostalCode,
  checkDeliveryPostalCode,
} from "../../../utils/validations";
import { formatPostalCode } from "../../../utils/formats";
import Checkbox from "@material-ui/core/Checkbox";
import "./Address.scss";
import { useEffect } from "react";

function Address({ form, setForm, setFocusOnInput = () => {}, setSelectNotice, ...props }) {
  const { t } = useTranslation();
  const [billCountryValue, setBillCountryValue] = useState("");
  const [deliveryCountryValue, setDeliveryCountryValue] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        isSameAddress: isChecked,
      };
    });
  }, [isChecked]);

  useEffect(() => {
    if (props.geoLocation?.country_name) {
      setForm((prev) => {
        return {
          ...prev,
          bill_country: { ...prev.bill_country, value: props.geoLocation?.country_name ?? "" },
        };
      });
    }
  }, [props.geoLocation]);

  return (
    <div className="address">
      <div className="address__billing">
        <p className="address__title">{t("address_billing")}</p>
        <div className="address__row">
          <Autocomplete
            className="homeMobile__filling__content__input"
            value={form.bill_country.value}
            onChange={(event, newValue) => {
              setForm((prev) => {
                return {
                  ...prev,
                  bill_country: { ...prev.bill_country, value: newValue ?? "" },
                };
              });
            }}
            inputValue={billCountryValue}
            onInputChange={(event, newInputValue) => {
              setBillCountryValue(newInputValue);
            }}
            options={country2codes.map((item) => item.country_name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("country")}
                variant="outlined"
                error={!form.bill_country.valid}
                helperText={!form.bill_country.valid && t("invalid_country")}
                size="small"
                inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
              />
            )}
            onFocus={() => {
              setFocusOnInput(true);
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onBlur={() => {
              checkBillCountry({ form: form, setForm: setForm });
            }}
          />

          <TextField
            onFocus={() => {
              setFocusOnInput(true);
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            inputProps={{ autocomplete: "new-password" }}
            size="small"
            error={!form.bill_state.valid}
            helperText={!form.bill_state.valid && t("invalid_state")}
            className="homeMobile__filling__content__input"
            label={t("state")}
            variant="outlined"
            value={form.bill_state.value}
            onChange={(event) => {
              var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
              var value = event.target.value;
              value = value.replace(format, "");
              if (value?.length > form.bill_state.max) {
                value = value.slice(0, form.bill_state.max);
              }
              setForm((prev) => {
                return {
                  ...prev,
                  bill_state: { ...prev.bill_state, value: value },
                };
              });
            }}
            onBlur={() => {
              checkBillState({ form: form, setForm: setForm });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={Correct}
                    alt=""
                    style={{
                      display: form.bill_state.valid && form.bill_state.checked ? "block" : "none",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="address__row">
          <TextField
            onFocus={() => {
              setFocusOnInput(true);
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            inputProps={{ autocomplete: "new-password" }}
            size="small"
            error={!form.bill_city.valid}
            helperText={!form.bill_city.valid && t("invalid_city")}
            className="homeMobile__filling__content__input"
            label={t("city")}
            variant="outlined"
            value={form.bill_city.value}
            onChange={(event) => {
              var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
              var value = event.target.value;
              value = value.replace(format, "");
              if (value?.length > form.bill_city.max) {
                value = value.slice(0, form.bill_city.max);
              }
              setForm((prev) => {
                return {
                  ...prev,
                  bill_city: { ...prev.bill_city, value: value },
                };
              });
            }}
            onBlur={() => {
              checkBillCity({ form: form, setForm: setForm });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={Correct}
                    alt=""
                    style={{
                      display: form.bill_city.valid && form.bill_city.checked ? "block" : "none",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            onFocus={() => {
              setFocusOnInput(true);
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            inputProps={{ autocomplete: "new-password", "ga-data": "346" }}
            size="small"
            error={!form.bill_street.valid}
            helperText={!form.bill_street.valid && t("invalid_street")}
            className="homeMobile__filling__content__input"
            label={t("street")}
            variant="outlined"
            value={form.bill_street.value}
            onChange={(event) => {
              var value = event.target.value;
              if (value?.length > form.bill_street.max) {
                value = value.slice(0, form.bill_street.max);
              }
              setForm((prev) => {
                return {
                  ...prev,
                  bill_street: { ...prev.bill_street, value: value },
                };
              });
            }}
            onBlur={() => {
              checkBillStreet({ form: form, setForm: setForm });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={Correct}
                    alt=""
                    style={{
                      display: form.bill_street.valid && form.bill_street.checked ? "block" : "none",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="address__row">
          <TextField
            onFocus={() => {
              setFocusOnInput(true);
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            inputProps={{ autocomplete: "new-password", "ga-data": "347" }}
            size="small"
            error={!form.bill_no.valid}
            helperText={!form.bill_no.valid && t("invalid_number")}
            className="homeMobile__filling__content__input"
            label={t("number")}
            variant="outlined"
            value={form.bill_no.value}
            onChange={(event) => {
              // eslint-disable-next-line no-useless-escape
              var format = /[^\d-\./]+/g;
              var value = event.target.value;
              value = value.replace(format, "");
              if (value?.length > form.bill_no.max) {
                value = value.slice(0, form.bill_no.max);
              }
              setForm((prev) => {
                return {
                  ...prev,
                  bill_no: { ...prev.bill_no, value: value },
                };
              });
            }}
            onBlur={() => {
              checkBillNo({ form: form, setForm: setForm });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={Correct}
                    alt=""
                    style={{
                      display: form.bill_no.valid && form.bill_no.checked ? "block" : "none",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            inputProps={{ autocomplete: "new-password", "ga-data": "343" }}
            onFocus={() => {
              setFocusOnInput(true);
              if (!form.bill_country.value) {
                setSelectNotice({ state: true, notice: t("select_country") });
              }
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            size="small"
            error={!form.bill_postalCode.valid}
            helperText={!form.bill_postalCode.valid && t("invalid_postal_code")}
            className="homeMobile__filling__content__input"
            label={t("postal_code")}
            variant="outlined"
            value={form.bill_postalCode.value}
            onChange={(event) => {
              let value = event.target.value;
              value = formatPostalCode(value, props.tradeInfo?.app_info?.region, form.bill_country.value, true);
              setForm((prev) => {
                return {
                  ...prev,
                  bill_postalCode: { ...prev.bill_postalCode, value: value },
                };
              });
            }}
            onBlur={() => {
              checkBillPostalCode({ form: form, setForm: setForm });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={Correct}
                    alt=""
                    style={{
                      display: form.bill_postalCode.valid && form.bill_postalCode.checked ? "block" : "none",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <div className="address__delivery">
        <p className="address__title">{t("address_delivery")}</p>
        <div className="address__checkbox">
          <Checkbox
            size="small"
            checked={isChecked}
            onClick={() => {
              setIsChecked((prev) => !prev);
            }}
          />
          <p
            onClick={() => {
              setIsChecked((prev) => !prev);
            }}
          >
            {t("address_same")}
          </p>
        </div>
        <div style={{ display: isChecked ? "none" : "block" }}>
          <div className="address__row">
            <Autocomplete
              className="homeMobile__filling__content__input"
              value={form.delivery_country.value}
              onChange={(event, newValue) => {
                setForm((prev) => {
                  return {
                    ...prev,
                    delivery_country: { ...prev.delivery_country, value: newValue ?? "" },
                  };
                });
              }}
              inputValue={deliveryCountryValue}
              onInputChange={(event, newInputValue) => {
                setDeliveryCountryValue(newInputValue);
              }}
              options={country2codes.map((item) => item.country_name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("country")}
                  variant="outlined"
                  error={!form.delivery_country.valid}
                  helperText={!form.delivery_country.valid && t("invalid_country")}
                  size="small"
                  inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
                />
              )}
              onFocus={() => {
                setFocusOnInput(true);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onBlur={() => {
                checkDeliveryCountry({ form: form, setForm: setForm });
              }}
            />

            <TextField
              onFocus={() => {
                setFocusOnInput(true);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              inputProps={{ autocomplete: "new-password" }}
              size="small"
              error={!form.delivery_state.valid}
              helperText={!form.delivery_state.valid && t("invalid_state")}
              className="homeMobile__filling__content__input"
              label={t("state")}
              variant="outlined"
              value={form.delivery_state.value}
              onChange={(event) => {
                var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
                var value = event.target.value;
                value = value.replace(format, "");
                if (value?.length > form.delivery_state.max) {
                  value = value.slice(0, form.delivery_state.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    delivery_state: { ...prev.delivery_state, value: value },
                  };
                });
              }}
              onBlur={() => {
                checkDeliveryState({ form: form, setForm: setForm });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={Correct}
                      alt=""
                      style={{
                        display: form.delivery_state.valid && form.delivery_state.checked ? "block" : "none",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="address__row">
            <TextField
              onFocus={() => {
                setFocusOnInput(true);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              inputProps={{ autocomplete: "new-password" }}
              size="small"
              error={!form.delivery_city.valid}
              helperText={!form.delivery_city.valid && t("invalid_city")}
              className="homeMobile__filling__content__input"
              label={t("city")}
              variant="outlined"
              value={form.delivery_city.value}
              onChange={(event) => {
                var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
                var value = event.target.value;
                value = value.replace(format, "");
                if (value?.length > form.delivery_city.max) {
                  value = value.slice(0, form.delivery_city.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    delivery_city: { ...prev.delivery_city, value: value },
                  };
                });
              }}
              onBlur={() => {
                checkDeliveryCity({ form: form, setForm: setForm });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={Correct}
                      alt=""
                      style={{
                        display: form.delivery_city.valid && form.delivery_city.checked ? "block" : "none",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              onFocus={() => {
                setFocusOnInput(true);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              inputProps={{ autocomplete: "new-password", "ga-data": "346" }}
              size="small"
              error={!form.delivery_street.valid}
              helperText={!form.delivery_street.valid && t("invalid_street")}
              className="homeMobile__filling__content__input"
              label={t("street")}
              variant="outlined"
              value={form.delivery_street.value}
              onChange={(event) => {
                var value = event.target.value;
                if (value?.length > form.delivery_street.max) {
                  value = value.slice(0, form.delivery_street.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    delivery_street: { ...prev.delivery_street, value: value },
                  };
                });
              }}
              onBlur={() => {
                checkDeliveryStreet({ form: form, setForm: setForm });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={Correct}
                      alt=""
                      style={{
                        display: form.delivery_street.valid && form.delivery_street.checked ? "block" : "none",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="address__row">
            <TextField
              onFocus={() => {
                setFocusOnInput(true);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              inputProps={{ autocomplete: "new-password", "ga-data": "347" }}
              size="small"
              error={!form.delivery_no.valid}
              helperText={!form.delivery_no.valid && t("invalid_number")}
              className="homeMobile__filling__content__input"
              label={t("number")}
              variant="outlined"
              value={form.delivery_no.value}
              onChange={(event) => {
                // eslint-disable-next-line no-useless-escape
                var format = /[^\d-\./]+/g;
                var value = event.target.value;
                value = value.replace(format, "");
                if (value?.length > form.delivery_no.max) {
                  value = value.slice(0, form.delivery_no.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    delivery_no: { ...prev.delivery_no, value: value },
                  };
                });
              }}
              onBlur={() => {
                checkDeliveryNo({ form: form, setForm: setForm });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={Correct}
                      alt=""
                      style={{
                        display: form.delivery_no.valid && form.delivery_no.checked ? "block" : "none",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              inputProps={{ autocomplete: "new-password", "ga-data": "343" }}
              onFocus={() => {
                setFocusOnInput(true);
                if (!form.delivery_country.value) {
                  setSelectNotice({ state: true, notice: t("select_country") });
                }
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              size="small"
              error={!form.delivery_postalCode.valid}
              helperText={!form.delivery_postalCode.valid && t("invalid_postal_code")}
              className="homeMobile__filling__content__input"
              label={t("postal_code")}
              variant="outlined"
              value={form.delivery_postalCode.value}
              onChange={(event) => {
                let value = event.target.value;
                value = formatPostalCode(value, props.tradeInfo?.app_info?.region, form.delivery_country.value, true);
                setForm((prev) => {
                  return {
                    ...prev,
                    delivery_postalCode: { ...prev.delivery_postalCode, value: value },
                  };
                });
              }}
              onBlur={() => {
                checkDeliveryPostalCode({ form: form, setForm: setForm });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={Correct}
                      alt=""
                      style={{
                        display: form.delivery_postalCode.valid && form.delivery_postalCode.checked ? "block" : "none",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    geoLocation: state.geoLocation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
