import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import { checkEmail } from "../../utils/validations";

function Email({ form, setForm, mobile, setFocusOnInput = () => {}, tradeInfo, vita }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!form.email.checked) {
      let email = tradeInfo?.user?.email ? tradeInfo?.user?.email : localStorage?.getItem("__frm_email");

      if (email) {
        setForm((prev) => {
          return {
            ...prev,
            email: { ...prev.email, value: email },
          };
        });
        checkEmail({ form: form, setForm: setForm, data: email });
      }
    }
  }, []);
  return (
    <>
      <TextField
        id={mobile ? "inputform-email-mob" : "inputform-email"}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        inputProps={{ inputMode: "email", autocomplete: "new-password" }}
        error={!form.email.valid}
        helperText={!form.email.valid && t("invalid_email")}
        className="homeMobile__filling__content__input"
        label={vita ? "Registered Email" : t("email")}
        variant="outlined"
        value={form.email.value}
        onChange={(event) => {
          let value = event.target.value;
          if (value?.length > form.email.max) {
            value = value.slice(0, form.email.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              email: {
                ...prev.email,
                value: value.trim(),
              },
            };
          });
        }}
        onBlur={() => {
          checkEmail({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.email.valid && form.email.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default Email;
