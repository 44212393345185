import React from "react";
import "./CheckoutBoleto.scss";
import { connect } from "react-redux";
import BarcodeAndPDF from "./BarcodeAndPDF";
import { useTranslation } from "react-i18next";

function CheckoutBoleto({ pdf, image, mobile = false, ...props }) {
  const { t } = useTranslation();
  return (
    <div className="checkoutBoleto">
      <div className="checkoutBoleto__header">
        <p className="checkoutBoleto__header__title">{t("boleto_inst1")}</p>
        <div className="checkoutBoleto__header__contentContainer">
          <p className="checkoutBoleto__header__content">{t("boleto_inst2")}</p>
          <p className="checkoutBoleto__header__content">{t("boleto_inst3")}</p>
        </div>
      </div>
      <BarcodeAndPDF
        mobile={mobile}
        barcode={props.payData?.bank_code}
        pdf={pdf}
        image={image}
        copyButton={{ text: props.payData?.bank_code, buttonText: `${t("copy")} ${t("barcode")}` }}
      />
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBoleto);
