import React from "react";
import "./CheckoutSpei.scss";
import CopyButton from "./CopyButton";
import { connect } from "react-redux";
import ShareOnSocialMedia from "./ShareOnSocialMedia";
import { useTranslation } from "react-i18next";
import SaveButton from "./SaveButton";
import CheckoutTitle from "./CheckoutTitle";
import { FormatMoney } from "../utils/formats";
import InfoLogo from "../img/info_icon2.svg";

function CheckoutSpei({ pdf, image, ...props }) {
  const { t } = useTranslation();
  return (
    <div className="checkoutSpei">
      <CheckoutTitle />
      {props.payChannel !== "OpenPay" && (
        <>
        <div className="checkoutSpei__instContainer">
          <ol>
            <li>{t("checkout_inst5")}</li>
            <li>
              {t("trsf5")}
              <span className="checkoutSpei__instContainer--bold">
                <FormatMoney type="total" />
              </span>
              {t("trsf6")}
            </li>
          </ol>
        </div>
        <div className="checkoutSpei__account">
          <p className="checkoutSpei__title__main">{t("register_info")}</p>
          <div className="checkoutSpei__account__key">
            <div className="checkoutSpei__row">
              <p className="checkoutSpei__title">{t("spei_clabe")}</p>
              <div className="checkoutSpei__account__clabe">
                <p className="checkoutSpei__value checkoutSpei__clabe">{props.payData?.clabe}</p>
                <CopyButton text={props.payData?.clabe} minWidth={100} height={35} />
              </div>
            </div>
            <div>
              <div className="checkoutSpei__account__notice">
                <img src={InfoLogo} alt="" />
                <p>{t("spei_key")}</p>
              </div>
            </div>
          </div>

          <hr />
          <div className="checkoutSpei__row">
            <p className="checkoutSpei__title">{t("spei_bank")}</p>
            <p className="checkoutSpei__value">{props.payData?.bank_name}</p>
          </div>
          <hr />
          <div className="checkoutSpei__row">
            <p className="checkoutSpei__title">{t("spei_recipient")}</p>
            <p className="checkoutSpei__value">
              {props.payData?.beneficiary_name ? props.payData?.beneficiary_name : "--"}
            </p>
          </div>
          <hr />
        </div>
      </>
      )}

      {props.payChannel === "OpenPay" && (
        <>
          <div className="checkoutSpei__openpay">
            <div className="checkoutSpei__openpay__inst">
              <div className="checkoutSpei__openpay__instContent">
                <h4>{t("checkoutSpei_openpay_h4")}</h4>
                <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_openpay_content1")}</p>
                <div className="checkoutSpei__openpay__instRow">
                  <div className="checkoutSpei__openpay__instColumn">
                    <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_openpay_instValue")}</p>
                    <p className="checkoutSpei__openpay__instValue">{props.payData?.reference}</p>
                  </div>
                  <CopyButton text={props.payData?.reference} minWidth={100} height={35} />
                </div>
                <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_openpay_content2")}</p>
                <div className="checkoutSpei__openpay__instRow">
                  <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_reference")}</p>
                  <p className="checkoutSpei__openpay__instValue">{props.payData?.payment_reference}</p>
                </div>

                <hr />
                <div className="checkoutSpei__openpay__instRow">
                  <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_openpay_monto")}</p>
                  <p className="checkoutSpei__openpay__instValue">
                    <FormatMoney type="total" />
                  </p>
                </div>
              </div>
              <div className="checkoutSpei__openpay__instContent">
                <h4>{t("checkoutSpei_openpay_from")}</h4>
                <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_openpay_content3")}</p>
                <div className="checkoutSpei__openpay__instRow">
                  <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_openpay_beneficiary")}</p>
                  <p className="checkoutSpei__openpay__instValue">Pagsmile</p>
                </div>
                <hr />
                <div className="checkoutSpei__openpay__instRow">
                  <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_destinationbank")}</p>
                  <p className="checkoutSpei__openpay__instValue">{props.payData?.bank_name}</p>
                </div>

                <hr />
                <div className="checkoutSpei__openpay__instRow">
                  <div className="checkoutSpei__openpay__instColumn">
                    <p className="checkoutSpei__openpay__instTitle">Clabe:</p>
                    <p className="checkoutSpei__openpay__instValue">{props.payData?.clabe}</p>
                  </div>
                  <CopyButton text={props.payData?.clabe} minWidth={100} height={35} />
                </div>
                <hr />
                <div className="checkoutSpei__openpay__instRow">
                  <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_description")}</p>
                  <p className="checkoutSpei__openpay__instValue">{props.payData?.payment_reference}</p>
                </div>
                <hr />
                <div className="checkoutSpei__openpay__instRow">
                  <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_reference")}</p>
                  <p className="checkoutSpei__openpay__instValue">{props.payData?.reference}</p>
                </div>
                <hr />
                <div className="checkoutSpei__openpay__instRow">
                  <p className="checkoutSpei__openpay__instTitle">{t("checkoutSpei_amount")}</p>
                  <p className="checkoutSpei__openpay__instValue">
                    <FormatMoney type="total" />
                  </p>
                </div>
              </div>
            </div>
            <div className="checkoutSpei__openpay__buttom">
              <SaveButton link={pdf} />
              <ShareOnSocialMedia text={pdf} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payChannel: state.payChannel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSpei);
