import React from "react";
import "./VideoContent.scss";
function VideoContent({ title, source }) {
  return (
    <div className="video">
      <iframe
        width="224"
        height="126"
        src={source}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <p className="video__title">{title}</p>
    </div>
  );
}

export default VideoContent;
