import React from "react";
import "./CheckoutPix.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PixQr from "../img/pixqr.png";
import CopyButton from "./CopyButton";
import CheckoutTitle from "./CheckoutTitle";
var QRCode = require("qrcode.react");

function CheckoutPix(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className="checkoutPix">
      <div>
        <CheckoutTitle />
        <div className="checkoutPix__instContainer">
          <p>{i18n.t("checkoutPix_instContainer")}</p>
        </div>
      </div>
      <div className="checkoutPix__content">
        {props.payData?.qr_code && (
          <>
            <QRCode
              value={props.payData?.qr_code}
              size={128}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              renderAs={"svg"}
              imageSettings={{
                src: PixQr,
                x: null,
                y: null,
                height: 24,
                width: 24,
                excavate: true,
              }}
            />
            <CopyButton text={props.payData?.qr_code} minWidth={100} />
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPix);
