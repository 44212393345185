import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { Braintree, HostedField } from "react-braintree-fields";
import { connect } from "react-redux";
import { setPaypalCreditCard } from "../../../redux/actions";
import "./PaypalCreditCard.scss";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";

function CustomField(props) {
  const { inputRef, setValidity, type, name, ...other } = props;

  return (
    <HostedField
      {...other}
      onValidityChange={(item) => {
        setValidity({ type: "keepPrev", key: name, value: { valid: item.isValid, checked: true } });
      }}
      type={type}
      ref={inputRef}
    />
  );
}

const PaypalCreditcard = forwardRef(
  ({ authorizationToken, handleError = () => {}, loading, setFocusOnInput = () => {}, target, ...props }, ref) => {
    const { t } = useTranslation();
    const [tokenize, setTokenizeFunc] = React.useState();
    const onlyWidth = useWindowWidth();

    useEffect(() => {
      loading(true);
    }, []);

    useImperativeHandle(ref, () => ({
      getToken(success = () => {}, fail = () => {}) {
        tokenize().then(success).catch(fail);
      },
    }));

    const onAuthorizationSuccess = () => {
      loading(false);
    };

    const onDataCollectorInstanceReady = (err, collector) => {
      // DO SOMETHING with Braintree collector as needed
      if (err) {
        handleError("onDataCollectorInstanceReady error");
      } else {
        props.setPaypalCreditCard({ deviceData: collector.deviceData });
      }
    };

    const handleErr = (newError) => {
      if (newError.code === "HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME") {
        console.log("Element already contains a Braintree iframe");
      } else {
        handleError(newError.message || String(newError));
      }
    };

    const onCardTypeChange = ({ cards }) => {};

    // const getToken = () => {
    //   tokenize().then(setToken).catch(handleError);
    // };

    return (
      ((target === "mobile" && onlyWidth <= 800) || (target === "pc" && onlyWidth > 800)) && (
        <div>
          <Braintree
            className="paypalContainer"
            authorization={authorizationToken}
            onAuthorizationSuccess={onAuthorizationSuccess}
            onDataCollectorInstanceReady={onDataCollectorInstanceReady}
            onError={handleErr}
            onCardTypeChange={onCardTypeChange}
            getTokenRef={(ref) => setTokenizeFunc(() => ref)}
            styles={{
              input: {
                "font-size": "16px",
                "font-family": "sans-serif",
                "font-weight": "400",
                color: "#000000DE",
              },
              ":focus": {
                color: "inherit",
              },
            }}
          >
            <div className="paypalCreditcard--row">
              <TextField
                size="small"
                variant="outlined"
                label="Cardholder Name"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!props.paypalCreditCard?.cardName?.valid && props.paypalCreditCard?.cardName?.checked}
                helperText={
                  !props.paypalCreditCard?.cardName?.valid && props.paypalCreditCard?.cardName?.checked && t("invalid_name")
                }
                inputProps={{
                  setValidity: props.setPaypalCreditCard,
                  type: "cardholderName",
                  name: "cardName",
                }}
                InputProps={{
                  inputComponent: CustomField,
                }}
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onBlur={() => {
                  props.setPaypalCreditCard({ type: "keepPrev", key: "cardName", value: { checked: true } });
                }}
              />

              <TextField
                size="small"
                variant="outlined"
                label="Credit card number"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!props.paypalCreditCard?.cardNumber?.valid && props.paypalCreditCard?.cardNumber?.checked}
                helperText={
                  !props.paypalCreditCard?.cardNumber?.valid &&
                  props.paypalCreditCard?.cardNumber?.checked &&
                  "Invalid Card Number"
                }
                inputProps={{
                  setValidity: props.setPaypalCreditCard,
                  type: "number",
                  name: "cardNumber",
                }}
                InputProps={{
                  inputComponent: CustomField,
                }}
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onBlur={() => {
                  props.setPaypalCreditCard({ type: "keepPrev", key: "cardNumber", value: { checked: true } });
                }}
              />
            </div>

            <div className="paypalCreditcard--row">
              <TextField
                size="small"
                variant="outlined"
                label="Expiration Date"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!props.paypalCreditCard?.expDate?.valid && props.paypalCreditCard?.expDate?.checked}
                helperText={
                  !props.paypalCreditCard?.expDate?.valid && props.paypalCreditCard?.expDate?.checked && "Invalid expiration date"
                }
                inputProps={{
                  setValidity: props.setPaypalCreditCard,
                  type: "expirationDate",
                  name: "expDate",
                }}
                InputProps={{
                  inputComponent: CustomField,
                }}
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onBlur={() => {
                  props.setPaypalCreditCard({ type: "keepPrev", key: "expDate", value: { checked: true } });
                }}
              />

              <TextField
                size="small"
                variant="outlined"
                label="CVV"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!props.paypalCreditCard?.cvv?.valid && props.paypalCreditCard?.cvv?.checked}
                helperText={!props.paypalCreditCard?.cvv?.valid && props.paypalCreditCard?.cvv?.checked && "Invalid cvv"}
                inputProps={{
                  setValidity: props.setPaypalCreditCard,
                  type: "cvv",
                  name: "cvv",
                }}
                InputProps={{
                  inputComponent: CustomField,
                }}
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onBlur={() => {
                  props.setPaypalCreditCard({ type: "keepPrev", key: "cvv", value: { checked: true } });
                }}
              />
            </div>
          </Braintree>
        </div>
      )
    );
  }
);

const mapStateToProps = (state, ownprops) => {
  return {
    paypalCreditCard: state.paypalCreditCard,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPaypalCreditCard: (item) => dispatch(setPaypalCreditCard(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PaypalCreditcard);
