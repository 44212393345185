import { getFingerPrint } from "./getFingerPrint";
import req from "./axios";
import store from "../redux/store";

const loadCrediMaxLib = (successCallback = () => {}, prepayId, method) => {
  const { tradeInfo } = store.getState();
  const region = tradeInfo?.app_info?.region;
  const data = {
    trade_no: prepayId,
    method: method,
    region,
  };
  req.post("/api/trade/get-client-token", data).then((res) => {
    if (res?.data?.code === "10000") {
      let name = "CreditCard-CrediMax";
      const existingScript = document.getElementById(name);
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://credimax.gateway.mastercard.com/form/version/${res?.data?.token?.version}/merchant/${res?.data?.token?.merchant_id}/session.js`;
        //script.id = name;
        document.body.appendChild(script);
        script.onload = () => {
          successCallback();
          sessionStorage?.setItem("CrediMax__session", res?.data?.token?.token);
        };
      }
    }
  });
};

const libs = {
  "CreditCard-OpenPay-MEX": [
    "https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js",
    "https://js.openpay.mx/openpay.v1.min.js",
    "https://js.openpay.mx/openpay-data.v1.min.js",
  ],
  "CreditCard-OpenPay-PER": [
    "https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js",
    "https://js.openpay.pe/openpay.v1.min.js",
    "https://js.openpay.pe/openpay-data.v1.min.js",
  ],
  "Wallet-Paypal": [
    "https://js.braintreegateway.com/web/3.79.0/js/client.min.js",
    "https://js.braintreegateway.com/web/3.79.0/js/paypal-checkout.min.js",
    "https://js.braintreegateway.com/web/3.79.0/js/data-collector.min.js",
  ],
  "CreditCard-CrediMax": [getFingerPrint, loadCrediMaxLib],
  "CreditCard-Paypal": [getFingerPrint],
  "CreditCard-Localpayment": [getFingerPrint],
  "DebitCard-Localpayment": [getFingerPrint],
  "CreditCard-Movii": [getFingerPrint],
};

const loadScript = (item, name, prepayId, method) => {
  return new Promise((resolve, reject) => {
    if (typeof item === "function") {
      item(
        () => {
          resolve();
        },
        prepayId,
        method
      );
    } else {
      const existingScript = document.getElementById(name);
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = item;
        script.id = name;
        document.body.appendChild(script);
        script.onload = () => {
          // console.log(name);
          resolve();
        };
        script.onerror = () => {
          reject();
        };
      }
    }
  });
};

async function loadOneAfterAnother(arr, name, prepayId) {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    await loadScript(item, name + i, prepayId);
    // console.log(`${name} : ${i}`);
  }
}

export const loadLibs = (channels, region, setLibs = () => {}, excludedLibs = {}, prepayId) => {
  try {
    channels?.forEach((channel) => {
      let name = channel.method + "-" + channel.channel;

      if (name === "CreditCard-OpenPay" && !excludedLibs[name]) {
        loadOneAfterAnother(libs[name + "-" + region], name, prepayId)
          .then(() => {
            setLibs({
              [name]: true,
            });
          })
          .catch(() => {
            setLibs({
              [name]: false,
            });
          });
      } else {
        if (libs[name] && !excludedLibs[name]) {
          let count = 0;
          libs[name]?.forEach((item, index) => {
            loadScript(item, name + index, prepayId, channel.method)
              .then(() => {
                // console.log("count: >>>>> ", `${index} : ${count}`);
                count += 1;
                setLibs({
                  [name]: libs[name].length === count,
                });
              })
              .catch(() => {
                console.log(`${name + index} loadLibs Error!`);
              });
          });
        }
      }
    });
  } catch (error) {
    console.error("loadLibs Error! ", error);
  }
};

export const loadLib = (method, channel, setLibs = () => {}) => {
  return new Promise((resolve, reject) => {
    let name = method + "-" + channel;
    if (libs[name]) {
      let count = 0;
      libs[name]?.forEach((item, index) => {
        loadScript(item, name + index)
          .then(() => {
            // console.log("count: >>>>> ", `${index} : ${count}`);
            count += 1;
            setLibs({
              [name]: libs[name].length === count,
            });
            libs[name].length === count && resolve();
          })
          .catch(() => {
            reject();
          });
      });
    }
  });
};

export const setLiveChat = (data) => {
  //live-chat
  window.__lc = window.__lc || {};
  window.__lc.license = 8514118;
  window.__lc.chat_between_groups = false;
  window.__lc.group = 23;
  window.__lc.params = [
    { name: "app Name", value: data?.app_info?.app_name },
    { name: "Trade NO.", value: data?.out_trade_no },
    { name: "Order Amount", value: data?.order_amount },
  ];
  var lc = document.createElement("script");
  lc.type = "text/javascript";
  lc.async = true;
  lc.src = ("https:" == document.location.protocol ? "https://" : "http://") + "cdn.livechatinc.com/tracking.js";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(lc, s);
  //end live-chat
};
