import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../../img/correct.jpg";
import { checkCEP, validateCep } from "../../../utils/validations";
import { formatCEP } from "../../../utils/formats";
import { connect } from "react-redux";

function Cep({ form, setForm, setFocusOnInput = () => {}, ...props }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!form.boleto_cep.checked) {
      let cep = props.tradeInfo?.address?.zip_code || localStorage?.getItem("__frm_cep");
      if (validateCep(cep)) {
        setForm((prev) => {
          return {
            ...prev,
            boleto_cep: {
              ...prev.boleto_cep,
              value: formatCEP(cep),
            },
          };
        });
        checkCEP({ form: form, setForm: setForm, data: cep });
      }
    }
  }, []);
  return (
    <>
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        inputProps={{ autocomplete: "new-password", "ga-data": "348" }}
        error={!form.boleto_cep.valid}
        helperText={!form.boleto_cep.valid && t("invalid_cep")}
        className="homeMobile__filling__content__input"
        label="CEP"
        variant="outlined"
        value={form.boleto_cep.value}
        onChange={(event) => {
          let value = formatCEP(event.target.value);
          setForm((prev) => {
            return {
              ...prev,
              boleto_cep: { ...prev.boleto_cep, value: value },
            };
          });
        }}
        onBlur={() => {
          checkCEP({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.boleto_cep.valid && form.boleto_cep.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Cep);
