import React, { useEffect, useRef } from "react";
import { jsonParse } from "../../../utils/jsonParse";

function SubmitForm({ data, target = "pc" }) {
  const submitRef = useRef(null);
  useEffect(() => {
    submitRef.current?.click();
  }, []);

  if ((target === "pc" && window.innerWidth >= 800) || (target === "mobile" && window.innerWidth < 800)) {
    return (
      <form action={data?.pay_url} style={{ display: "none" }} target="_self" method="post">
        {Object.keys(jsonParse(data?.form_date) || {})?.map((item) => (
          <>
            <input type="text" name={item} value={jsonParse(data.form_date)[item]} />
          </>
        ))}
        <input ref={submitRef} type="submit" value="Submit" id="" />
      </form>
    );
  } else {
    return <></>;
  }
}

export default SubmitForm;
